import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  tableWrap: {
    outline: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '10px',
    marginBottom: '10px',
  },
  tableContainer: (isChatOpen: boolean) => ({
    maxHeight: isChatOpen ? '212px' : '300px',
    borderRadius: '10px 10px 0 0',
  }),
  title: {
    marginBottom: '16px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.custom.black.B100,
  },
  table: {
    width: '100%',
  },
  cell: {
    padding: '10px 8px',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    color: customTheme.custom.black.B100,
    backgroundColor: customTheme.palette.primary.P5,

    ':first-of-type': {
      padding: '10px 8px 10px 24px',
    },

    ':last-of-type': {
      padding: '10px 24px 10px 8px',
    },
  },
  // Header
  cellHeaderContainer: {
    display: 'flex',
  },
  cellHeaderText: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerCell: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    zIndex: 30,
  },

  headerSubTitle: {
    fontSize: '12px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
  },
  tableBody: {
    backgroundColor: customTheme.custom.white,
    marginBottom: '36px',
  },
  tableFooter: {
    backgroundColor: customTheme.custom.white,
    marginBottom: '36px',
  },
  tableRow: {
    height: '38px',
  },
  labelCell: {
    fontSize: '12px',
    lineHeight: '12px',
  },
  totalWrap: {
    backgroundColor: customTheme.palette.secondary.S20,
  },
  headerVariety: {
    width: '220px',
    maxWidth: '220px',
  },
  headerQuantity: {
    width: '60px',
    maxWidth: '60px',
  },
  headerUnits: {
    width: '90px',
    maxWidth: '90px',
  },
  headerPricePerUnit: {
    width: '100px',
    maxWidth: '100px',
  },
  headerQuote: {},
  headerLastestOffer: {},
  headerRequestedPrice: {
    width: '90px',
    maxWidth: '90px',
  },
  headerSubtotals: {
    width: '70px',
    maxWidth: '70px',
  },
  headerCounterOffer: {},
  headerAcceptPrice: {
    width: '108px',
    maxWidth: '108px',
  },
  headerNotAvailable: {
    width: '60px',
    maxWidth: '60px',
  },
  headerBuyerRemove: {
    width: '16px',
    maxWidth: '16px',
    padding: '8px',
  },
};
