import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  units: {
    padding: '4px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  unitsTooltip: {
    marginBottom: '0px !important',
  },
  unitsLabel: {
    userSelect: 'none',
    color: customTheme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
    minWidth: '18px',
    textAlign: 'center',
  },
  unitsButton: {
    maxHeight: '32px',
    maxWidth: '60px',
    minWidth: '24px',
    textAlign: 'left',
    backgroundColor: 'inherit',
    padding: '0px',
    borderRadius: '8px',
  },
};
