import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  cell: {
    padding: '6px 8px',
    fontSize: '12px',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    color: customTheme.custom.black.B100,

    ':first-of-type': {
      padding: '6px 8px 6px 24px',
      width: 'fit-content',
    },

    ':last-of-type': {
      padding: '6px 24px 6px 8px',
    },
  },
  cellWithOpacity: (isOpacity: boolean) => ({
    opacity: isOpacity ? 0.4 : 1,
  }),
  tableRow: {
    height: '44px',

    ':nth-last-of-type(-n+1)': {
      td: {
        border: 0,
      },
    },
  },
  closeIcon: {},
  labelCell: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  priceCell: {
    fontSize: '12px',
    lineHeight: '18px',
    width: 'fit-content',
    color: customTheme.palette.secondary.S100,
  },
  priceValue: {
    width: '104px',
    minWidth: '104px',
  },
  amountField: {
    margin: '0',
  },
  amountFieldInput: {
    height: '21px',
    paddingRight: '8px',
  },
  sellerActionsCell: {
    width: '40px',
    padding: '16px 0',
    textAlign: 'right',
  },
  statusLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    whiteSpace: 'wrap',
    color: customTheme.custom.black.B100,
  },
  undoLabel: {
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
    textDecoration: 'underline',
    position: 'relative',
    zIndex: 20,

    ':hover': {
      color: customTheme.custom.black.B50,
      cursor: 'pointer',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      zIndex: 5,
    },
  },
  statusLabelWrap: {
    display: 'grid',
    gridTemplateColumns: '160px 40px',
  },
  weight: {
    minWidth: 'unset',
    width: 'fit-content',
  },
  editQuantityUnitsLabel: {
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 400,
    color: customTheme.custom.black.B1000,
    minWidth: 'unset',
  },
  editQuantityUnitsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '60px',
  },
  varietyCell: {
    width: '220px',
    minWidth: '220px',
  },
  amountWeight: {
    display: 'block',
    width: '112px',
    minWidth: '112px',
  },
  quantityCell: {
    maxWidth: '60px',
    width: '60px',
  },
  latestOfferCell: (isEditMode: boolean) => ({
    backgroundColor: isEditMode ? '#F7F7F7' : 'unset',
  }),
  quantityUnitsCell: {
    maxWidth: '90px',
    width: '90px',
    boxSizing: 'border-box',
  },
  unitsCell: {
    maxWidth: '58px',
    width: '58px',
  },
  pricePerUnitCell: {
    maxWidth: '128px',
    width: '128px',
  },
  requestedPriceCell: (isEditMode: boolean) => ({
    backgroundColor: isEditMode ? '#EBF6F8' : 'unset',
    maxWidth: '90px',
    width: '90px',
  }),
  quoteSubtotal: {
    maxWidth: '70px',
    width: '70px',
  },
  quoteNotAvailableText: {
    fontSize: '12px',
    fontWeight: 400,
  },
  acceptPriceCell: {
    maxWidth: '108px',
    width: '108px',
  },
  priceAccepted: {
    color: customTheme.palette.secondary.S100,
    fontSize: '12px',
    fontWeight: 400,
  },
  buyerRemoveCell: {
    maxWidth: '16px',
    width: '16px',
  },
  undoRemove: {
    fontSize: '12px',
    fontWeight: 400,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  notAvailableCell: {
    maxWidth: '72px',
    width: '72px',
  },
  editQuantityBtn: {
    padding: '4px 4px',
  },
  // Seller remove cell
  outOfStockIcon: {},
};
