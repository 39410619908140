import { SxProps } from '@mui/material';

import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  banner: (bgImage: string): SxProps => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    aspectRatio: '135 / 32',
    paddingLeft: '56px',
    boxSizing: 'border-box',
    borderRadius: '30px',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'calc(100% + 28px)',
      height: '210px',
      marginLeft: '-14px',
      marginRight: '-14px',
      paddingTop: '56px',
      paddingLeft: '18px',
      alignItems: 'start',
      borderRadius: 0,
      backgroundPosition: 'right top',
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.18)',
      overflow: 'visible',
    },
  }),
  wrap: {
    width: '360px',

    '@media (min-width: 1520px)': {
      width: '420px',
    },

    '@media (min-width: 1920px)': {
      width: '600px',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
  contentTitle: {
    margin: '0 0 8px',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '44px',
    color: customTheme.custom.black.B5,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '6px',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  contentText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: customTheme.custom.black.B10,

    '@media (min-width: 1920px)': {
      width: '540px',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
};
