import { useMemo } from 'react';

import { GroupChannel } from '@sendbird/chat/lib/__definition';

import { getCurrentCompanyMembers, getOtherCompanyMembers, getSortedCurrentCompanyMembers } from 'utils/chat';

interface UseChannelMembersProps {
  activeUserCompanyId: string;
  currentChannel: GroupChannel | undefined;
  sendbirdUserId: string;
}

export const useChannelMembers = ({ activeUserCompanyId, currentChannel, sendbirdUserId }: UseChannelMembersProps) => {
  const currentCompanyMembers = useMemo(
    () => getCurrentCompanyMembers(currentChannel, activeUserCompanyId),
    [activeUserCompanyId, currentChannel],
  );

  const sortedCurrentCompanyMembers = useMemo(
    () => getSortedCurrentCompanyMembers(currentCompanyMembers, sendbirdUserId),
    [currentCompanyMembers, sendbirdUserId],
  );

  const otherCompanyMembers = useMemo(
    () => getOtherCompanyMembers(currentChannel, activeUserCompanyId),
    [activeUserCompanyId, currentChannel],
  );

  return {
    currentCompanyMembers: sortedCurrentCompanyMembers,
    otherCompanyMembers,
  };
};
