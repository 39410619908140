import { FC } from 'react';

import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { styles } from './styles';

export const FooterLinks: FC = () => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  return (
    <Box component="nav" sx={{ ...styles.navWrap }}>
      <Box sx={{ ...styles.navItem }}>
        <NavLink
          to={ROUTES.common.about}
          onClick={() =>
            handleTrackLinkClickEvent({ path: ROUTES.common.about, text: 'About', type: AnalyticsLinkType.NAV })
          }
        >
          About
        </NavLink>
      </Box>

      <Box sx={{ ...styles.divider }} />

      <Box sx={{ ...styles.navItem }}>
        <NavLink
          to={ROUTES.common.contact}
          onClick={() =>
            handleTrackLinkClickEvent({ path: ROUTES.common.contact, text: 'Contact us', type: AnalyticsLinkType.NAV })
          }
        >
          Contact us
        </NavLink>
      </Box>
    </Box>
  );
};
