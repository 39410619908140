import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  navWrap: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      alignItems: 'start',
      height: 'fit-content',
      gap: '4px',
    },
  },
  navItem: {
    width: '100%',
    position: 'relative',
    padding: '0 8px',
    margin: '0 8px',
    a: {
      color: customTheme.custom.black.B70,
      textDecoration: 'none',
      borderRadius: '8px',

      '&.active': {
        color: customTheme.custom.black.B100,
        borderRadius: '8px',
        backgroundColor: customTheme.custom.black.B20,
        '.MuiBox-root': {
          visibility: 'visible',
        },
      },
    },
    ':hover': {
      a: {
        color: customTheme.custom.black.B100,

        '.MuiBox-root': {
          visibility: 'visible',
        },
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
      padding: 0,
      a: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
  },
  rowText: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowItem: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    width: '100%',
    boxSizing: 'border-box',
    gap: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    color: customTheme.custom.black.B70,
    svg: {
      width: '24px',
      height: '24px',
    },
  },
  activeParent: {},
  children: {
    paddingLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  childItem: {
    padding: '8px',
  },
};
