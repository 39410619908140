import { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import * as rudderanalytics from 'rudder-sdk-js';

import { QuoteProductStatus } from 'app/api/quotes/types';
import { getQuoteData } from 'app/api/quotes/service';
import {
  createDocumentFolder,
  createOrder,
  CreateDocumentFolderRequest,
  CreateOrderRequest,
  CreateOrderResponse,
} from 'app/api/orders';
import { ROUTES } from 'app/routes/constants';
import { getDefaultUserRoute } from 'app/providers/utils';
import { useAuth } from 'hooks';
import { AnalyticsLinkType, RudderAnalyticsName, useLinkClickedEvenet } from 'rudderAnalytics';
import { getOrderSubmittedAnalyticOptions } from '../utils';
import { PlaceOrderFormData } from './types';

const dateFormat = 'yyyy-MM-dd';

export const usePlaceOrderPage = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { isBuyer } = useAuth();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const [formError, setFormError] = useState('');
  const [orderId, setOrderId] = useState<number>();

  const { data: quoteData, isLoading } = useQuery(['quote-data-order', quoteId], () => getQuoteData(Number(quoteId)), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      navigate(getDefaultUserRoute(isBuyer));
    },
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PlaceOrderFormData>();

  const documents = useWatch({
    control,
    name: 'documents',
  });

  const additionalDocuments = useWatch({
    control,
    name: 'additionalDocuments',
  });

  const idealDeliveryDateStart = useWatch({
    control,
    name: 'dateRange.0',
  });

  const idealDeliveryDateEnd = useWatch({
    control,
    name: 'dateRange.1',
  });

  const {
    mutate: handleCreateOrder,
    isLoading: isCreateOrderLoading,
    isSuccess: isCreateOrderSuccess,
  } = useMutation((data: CreateOrderRequest) => createOrder(data), {
    onSuccess: (data: CreateOrderResponse) => {
      setOrderId(data.id);

      const parsedDocuments = documents?.filter((val) => !!val) || [];
      const parsedAdditionalDocuments = additionalDocuments?.filter((val) => !!val.value).map((val) => val.value) || [];

      const preparedDocuments = [...parsedDocuments, ...parsedAdditionalDocuments].map((document) => ({
        kind: document,
        order: data.id,
      }));

      handleCreateDocuments(preparedDocuments);
    },
  });

  const {
    mutate: handleCreateDocuments,
    isLoading: isDocumentsLoading,
    isSuccess: isDocumentsLoadingSuccess,
  } = useMutation((data: CreateDocumentFolderRequest[]) => {
    return Promise.all(data.map((dataItem) => createDocumentFolder(dataItem)));
  });

  const onSubmit = (values: PlaceOrderFormData) => {
    const { documents, additionalDocuments, dateRange } = values;
    const parsedDocuments = documents?.filter((val) => !!val) || [];
    const parsedAdditionalDocuments = additionalDocuments?.filter((val) => !!val.value) || [];

    if (!parsedDocuments.length && !parsedAdditionalDocuments.length) {
      setFormError('Please fill in the required information.');
      return;
    }

    handleCreateOrder({
      quote: Number(quoteId),
      idealDelivery: [format(dateRange[0], dateFormat), format(dateRange[1] || dateRange[0], dateFormat)],
    });
  };

  const onChange = useCallback(() => {
    if (formError) {
      setFormError('');
    }
  }, [formError]);

  useEffect(() => {
    const subscription = watch(() => {
      onChange();
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const handleCancel = useCallback(() => {
    navigate(ROUTES.buyer.dashboard._);
    handleTrackLinkClickEvent({ type: AnalyticsLinkType.BUTTON, text: 'Cancel', path: ROUTES.buyer.dashboard._ });
  }, [handleTrackLinkClickEvent, navigate]);

  const isCreateLoading = useMemo(() => {
    return isCreateOrderLoading || isDocumentsLoading;
  }, [isCreateOrderLoading, isDocumentsLoading]);

  useEffect(() => {
    if (orderId && isCreateOrderSuccess) {
      const activeQuoteProducts =
        quoteData?.quoteproducts.filter(({ status }) => status === QuoteProductStatus.AVAILABLE) ?? [];
      const totalAmount = quoteData?.totalAmount;

      const parsedDocuments = documents?.filter((val) => !!val) || [];
      const parsedAdditionalDocuments = additionalDocuments?.filter((val) => !!val.value).map((val) => val.value) || [];

      const documentsNeeded = [...parsedDocuments, ...parsedAdditionalDocuments].map((document) => ({
        kind: document,
      }));

      const analyticsOptions = getOrderSubmittedAnalyticOptions(
        orderId,
        quoteData,
        activeQuoteProducts,
        totalAmount,
        idealDeliveryDateStart,
        idealDeliveryDateEnd,
        documentsNeeded,
      );

      rudderanalytics.track(RudderAnalyticsName.ORDER_SUBMITTED, analyticsOptions);
    }
  }, [
    additionalDocuments,
    documents,
    idealDeliveryDateEnd,
    idealDeliveryDateStart,
    isCreateOrderSuccess,
    orderId,
    quoteData,
  ]);

  useEffect(() => {
    if (orderId && isCreateOrderSuccess && isDocumentsLoadingSuccess) {
      const path = generatePath(ROUTES.common.order, { id: orderId });
      navigate(path, { replace: true });
    }
  }, [isCreateOrderSuccess, isDocumentsLoadingSuccess, navigate, orderId]);

  return {
    errors,
    control,
    formError,
    isLoading,
    quoteData,
    isCreateLoading,
    handleCancel,
    handleSubmit,
    onSubmit,
  };
};
