export enum GeneticType {
  hybrid = 'HYBRID',
  openPollinated = 'OPEN_POLLINATED',
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export enum OrganicType {
  organicSeed = 'organicSeed',
}

export enum FarmingMethod {
  ORGANIC = 'Organic',
}

export interface ProductCompany {
  id: number;
  displayId: string;
  name: string;
  country: string;
  continent: string;
  city: string;
  logo?: string | null;
  seller: number;
  smallestShipment?: number | null;
}

export interface Product {
  id: number;
  sku: string;
  image?: string | null;
  crop: string;
  variety?: string;
  name?: string | null;
  geneticType: GeneticType;
  inStock: boolean;
  qtyAvailable?: number | null;
  qtyAvailableUnits?: string;
  company: ProductCompany;
  description?: string | null;
  status?: ProductStatus;
  rank?: number | null;
  color?: string;
  thumbnail?: string | null;
  farmingMethod?: FarmingMethod | null;
}

export interface MinMaxNumber {
  min?: number | string;
  max?: number | string;
  units?: string;
}

export interface ProductEdit {
  name: string;
  variety: string;
  description: string;

  // This is a related table field
  crop: string;
  crop_id?: number;

  // This is a related table field
  restrictedCountries: number[];

  // Other fields
  color?: string;
  color_other?: string;
  inner_flesh_color?: string;
  farming_method?: 'Organic' | '';
  sci?: string;
  g_tex?: string;
  shape?: string;
  plant_shape?: string;
  boll_shape?: string;
  warts?: string;
  width?: MinMaxNumber;
  length?: MinMaxNumber;
  plant_height?: string;
  ridges?: string;
  weight?: MinMaxNumber;
  surface?: string;
  maturity?: MinMaxNumber;
  prickles?: string;
  firmness?: '' | 'Moderately Firm' | 'Firm' | 'Very Firm';
  pungency?: '' | 'Low Pungency' | 'Mild Pungency' | 'Pungent' | 'High Pungency';
  flowering_habit?: '' | 'Gynoecious' | 'Monoecious';
  boll_size?: string;
  flowering?: string;
  head_type?: '' | 'Loose' | 'Compact' | 'Semi-Compact' | 'Very Compact';
  monopodia?: string;
  thickness?: string;
  grain_size?: string;
  micronaire?: string;
  uniformity?: string;
  determinate?: '' | 'Determinate' | 'Indeterminate' | 'Semi-determinate' | 'Semi-indeterminate';
  oil_content?: MinMaxNumber;
  root_length?: string;
  keeping_days?: string;
  rows_per_ear?: string;
  seeds_per_pod?: string;
  sugar_content?: string;
  flowering_time?: MinMaxNumber;
  grains_per_row?: string;
  no_of_branches?: string;
  pods_per_plant?: string;
  works_well_for?: string;
  amylose_content?: string;
  number_of_lobes?: string;
  potential_yield?: MinMaxNumber;
  sulphur_content?: MinMaxNumber;
  adaptive_climate?: string;
  first_ear_height?: MinMaxNumber;
  milling_recovery?: MinMaxNumber;
  crude_fat_content?: string;
  planting_interval?: string;
  tillering_ability?: string;
  tillers_per_plant?: string;
  brix_sugar_content?: MinMaxNumber;
  fruits_per_cluster?: string;
  optimal_field_type?: string;
  seed_rate_per_acre?: MinMaxNumber;
  shelling_percentage?: string;
  potential_yield_t_ha?: string;
  seed_yield_per_plant?: string;
  average_grain_per_ear?: string;
  degree_of_development?: MinMaxNumber;
  fruits_pods_per_plant?: string;
  relative_plant_height?: string;
  approx_seeds_per_gram?: string;
  fruit_skin_wall_thickness?: string;
  ideal_growing_temperature?: MinMaxNumber;
  grain_length_to_width_ratio?: string;
  disease_pest_tolerance_specialties?: string;
  environmental_tolerance_specialties?: string;
  brix?: MinMaxNumber;
  relative_height?: string;
  fruits_per_plant?: string;
  number_of_ridges?: string;
  pods_per_cluster?: string;
  relative_maturity?: string;
  number_of_cuttings?: string;
  kernel_rows_per_ear?: string;
  no_of_pod_per_plant?: string;
  number_of_monopodia?: string;
  kernels_grains_per_row?: string;
  planting_distance?: string;
  seeds_per_gm?: string;
  days_to_flower?: string;
  days_to_sprout?: string;
  sun_requirements?: string;
}

export interface UpdateProduct {
  crop?: string;
  variety?: string;
  name?: string | null;
  geneticType?: GeneticType;
  inStock?: boolean;
  qtyAvailable?: number | null;
  qtyAvailableUnits?: number | null;
  description?: string | null;
  status?: ProductStatus;
  color?: string;
  farmingMethod?: FarmingMethod | null;
}

export interface Family {
  id: number;
  name: string;
  products: Product[];
}

export interface HomePageData {
  featuredProducts: Product[];
  featuredSellers: Product[];
  newProducts: Product[];
  cropFamilies: Family[];
}

export interface HomePageSection {
  name: string;
  id: number | string;
  products: Product[];
}

export interface ProductOtherObjectAttribute {
  min?: number | string;
  max?: number | string;
  units?: string;
}

export type ProductOtherAttribute = ProductOtherObjectAttribute | string | number;

export interface Country {
  id: number;
  name: string;
}

export interface ProductDetail extends Product {
  createdAt?: string;
  restrictedCountries?: Country[];
  other?: Record<string, ProductOtherAttribute>;
  companyLegendImage?: string | null;
}

export interface ProductsListRequest {
  limit?: number;
  offset?: number;
  company?: string;
  search?: string;
  geneticType?: string;
  color?: string[];
  continent?: string[];
  crop?: string;
  cropFamily?: string;
  sort?: string;
  featured?: boolean;
  featuredSeller?: boolean;
  minWeight?: number;
  maxWeight?: number;
  applyWeightFilter?: boolean;
  maturityMin?: number;
  maturityMax?: number;
  applyMaturityFilter?: boolean;
  resistances?: string[];
  applyResistancesFilter?: boolean;
  organic?: boolean;
}

export interface ProductsListData {
  count: number;
  next: string | null;
  previos: string | null;
  results: Product[];
}

export interface ProductsFiltersRequest {
  search?: string;
  crop?: string;
  cropFamily?: string;
}

export interface ProductNotFoundRequest {
  msgBody: string;
}

export interface QuoteRequestFormProduct extends Product {
  value: string;
}

export interface ProductsFiltersForm {
  geneticType: string;
  color: (string | boolean)[];
  continent: (string | boolean)[];
  sort: string;
  weight: number[];
  includeProductsWithIncompleteWeight: boolean;
  maturity: number[];
  includeProductsWithIncompleteMaturity: boolean;
  resistances: (string | boolean)[];
  includeProductsWithIncompleteResistances: boolean;
  organic: string;
}

export interface FilterParams {
  geneticType: string | null;
  color: string[] | undefined;
  continent: string[] | undefined;
  sort: string | null;
  minWeight: string | null;
  maxWeight: string | null;
  applyWeightFilter: 'true' | null;
  minMaturity: string | null;
  maxMaturity: string | null;
  applyMaturityFilter: 'true' | null;
  resistances: string[] | undefined;
  applyResistancesFilter: 'true' | null;
  organic: string | null;
}

export interface ProductsSeedsWeight {
  minWeight: number;
  maxWeight: number;
}

export interface ProductsSeedsMaturity {
  maturityMin: number;
  maturityMax: number;
}

export interface ProductsOrganicFlag {
  organic: boolean;
}
