import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  pageWrap: (isBuyer: boolean) => ({
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: `calc(100dvh - ${isBuyer ? '116px' : '64px'})`,
    },
  }),
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  legendWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
  footerWrap: (isMarginBottom: boolean) => ({
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '40px',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'stretch',
      width: 'calc(100% + 28px)',
      boxSizing: 'border-box',
      marginLeft: '-14px',
      marginRight: '-14px',
      ...(isMarginBottom && {
        marginBottom: '86px',
      }),

      '& > div': {
        width: '100%',
        minWidth: '100%',
        boxSizing: 'border-box',
      },
    },
  }),
};
