import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface PageHeaderProps {
  isSampleRequest: boolean;
  isWaiting: boolean;
  subtitle: string;
  title: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ isSampleRequest, isWaiting, subtitle, title }) => {
  return (
    <Box sx={styles.wrap}>
      <Box>
        {isSampleRequest && (
          <Typography component="span" sx={{ ...styles.sampleLabel }}>
            Sample Request
          </Typography>
        )}
        <Box sx={styles.title}>
          <Typography sx={styles.title}>{title}</Typography>
        </Box>
        <Typography sx={styles.subTitle}>{subtitle}</Typography>
      </Box>

      {isWaiting && <Typography sx={styles.tag}>Waiting for an answer</Typography>}
    </Box>
  );
};
