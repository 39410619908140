import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { DEACTIVATED_USER } from '../../../../../app/api/auth/errorCodes';
import { LoginData, LogInError } from '../../../../../app/api/auth/types';
import { ROUTES } from '../../../../../app/routes/constants';
import { useAuth } from '../../../../../hooks/useAuth';
import { loginInitialValues } from './initialValues';

export const useLoginForm = () => {
  const [formError, setFormError] = useState('');
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();
  const navigate = useNavigate();

  const { login, isLoginLoading: isLoading } = useAuth();

  const { mutate: loginHandler, isError, error, reset } = login;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: loginInitialValues,
  });

  const onSubmit = ({ username, ...values }: LoginData) => {
    loginHandler({ username: username.toLowerCase(), ...values });
  };

  const onChange = () => {
    if (formError) {
      setFormError('');
    }
  };

  const onForgotPassword = () => {
    navigate(ROUTES.auth.resetPassword);
    handleTrackLinkClickEvent({
      type: AnalyticsLinkType.BUTTON,
      text: 'Forgot Password?',
      path: ROUTES.auth.resetPassword,
    });
    reset();
  };

  useEffect(() => {
    if (error) {
      const errData = (error as AxiosError)?.response?.data as LogInError;

      if (errData?.user?.code === DEACTIVATED_USER) {
        setFormError(`This account has been deactivated. 
        For any questions please reach out 
        to contact@sproutzo.com`);
        return;
      }

      setFormError('Email or password is incorrect. Please try again.');
    }
  }, [error]);

  return {
    error,
    errors,
    control,
    onChange,
    isError,
    onSubmit,
    formError,
    isLoading,
    handleSubmit,
    onForgotPassword,
  };
};
