import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as LeafLogo } from 'assets/images/leafLogo.svg';
import pageBannerUrl from 'assets/images/pageBanner.png';
import { styles } from './styles';

export const AboutPageBanner: FC = (): JSX.Element => (
  <Box sx={{ ...styles.banner(pageBannerUrl) }}>
    <Box sx={{ ...styles.content }}>
      <LeafLogo />

      <Typography sx={{ ...styles.contentTitle }}>Welcome to Sproutzo</Typography>

      <Typography sx={{ ...styles.contentText }}>The global agriculture marketplace</Typography>
    </Box>
  </Box>
);
