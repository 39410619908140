import { FC, MouseEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { ImageMutationResult, MutationResult, useEditProducts } from 'pages/EditProductsPage/hooks/useProducts';
import { ProductDetail } from 'app/api/products';
import { CommonInput, CommonTooltip } from 'components';
import { ProductDetails } from 'pages/QuoteRequestPage/components/ProductDetails';
import { useEditProduct } from 'pages/EditProductsPage/hooks/useProduct';
import { getSeparator } from 'utils/formatText';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { styles } from './styles';
import { EditProductModal } from '../EditModal';

type TableContext = {
  updateProduct: MutationResult;
  updateProductImage: ImageMutationResult;
  expandedRow: number | null;
  setExpandedRow: React.Dispatch<React.SetStateAction<number | null>>;
};

const rowContent = (num: number, row: ProductDetail, context: TableContext) => {
  return (
    <ProductRow
      product={row}
      updateProduct={context.updateProduct}
      updateProductImage={context.updateProductImage}
      setExpandedRow={context.setExpandedRow}
      expandedRow={context.expandedRow}
    />
  );
};

export const UnitsMenu: FC<{
  units?: string;
  onChange: (unit: string) => void;
}> = ({ units, onChange }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    { label: 'kg', value: 'KG' },
    { label: 'lb', value: 'LB' },
    { label: 'thousand seeds', value: 'THOUSAND_SEEDS' },
  ];

  const onSelect = (value: string) => {
    onChange(value);
    setAnchorEl(null);
  };
  const displayUnit = options.find((option) => option.value === units);
  return (
    <Box sx={styles.units}>
      <Typography sx={styles.unitsLabel}>{displayUnit?.label ?? ''}</Typography>
      <CommonTooltip title="Change Units" placement="top" sx={styles.unitsTooltip}>
        <ButtonBase
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={styles.unitsButton}
        >
          <EditIcon />
        </ButtonBase>
      </CommonTooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          dense: true,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.label} onClick={() => onSelect(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const ProductRowCollapse: FC<
  { product: ProductDetail } & Pick<TableContext, 'expandedRow' | 'setExpandedRow'>
> = ({ product, setExpandedRow }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);
  const { productDetails, isLoading } = useEditProduct({
    productId: product.id,
    isOpen,
  });
  const onClick = () => setExpandedRow(null);
  return (
    <TableCell sx={styles.collapse(isOpen)} colSpan={tableHeaderColumns.length} onClick={onClick}>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <ProductDetails data={productDetails} isLoading={isLoading || !productDetails} isSeller />
      </Collapse>
    </TableCell>
  );
};
export const ProductRow: FC<
  { product: ProductDetail } & Pick<
    TableContext,
    'updateProduct' | 'updateProductImage' | 'setExpandedRow' | 'expandedRow'
  >
> = ({ product, updateProduct, updateProductImage, setExpandedRow, expandedRow }): JSX.Element => {
  const { qtyAvailable, qtyAvailableUnits, inStock } = product;
  const isRowExpanded = expandedRow === product.id;
  const [modalOpen, setModalOpen] = useState(false);
  const [productQuantityAvailable, setProductQuantityAvailable] = useState(qtyAvailable);
  const [productQuantityAvailableUnits, setProductQuantityAvailableUnits] = useState(qtyAvailableUnits);
  const onQuantityBlur = useCallback(() => {
    updateProduct?.mutate({ id: product.id, properties: { qty_available: productQuantityAvailable } });
  }, [updateProduct, productQuantityAvailable, product.id]);
  const onUpdateQtyAvailableUnits = useCallback(
    (value: string) => {
      setProductQuantityAvailableUnits(value);
      updateProduct?.mutate({ id: product.id, properties: { qty_available_units: value } });
    },
    [updateProduct, product.id, setProductQuantityAvailableUnits],
  );

  const toggleInStock = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      const properties: { inStock: boolean; qty_available?: number | null } = { inStock: !inStock };
      if (!properties.inStock) {
        setProductQuantityAvailable(undefined);
        properties.qty_available = null;
      }
      updateProduct?.mutate({ id: product.id, properties });
    },
    [inStock, product.id, updateProduct],
  );
  const toggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
    if (expandedRow === product.id) {
      setExpandedRow(null);
    }
  }, [setModalOpen, setExpandedRow, expandedRow, product.id]);

  const onExpandRow = useCallback(() => {
    setExpandedRow((expanded) => {
      if (expanded === product.id) {
        return null;
      }
      return product.id;
    });
  }, [product.id, setExpandedRow]);

  const stopPropagation = (e: MouseEvent) => e.stopPropagation();
  const displayValue =
    productQuantityAvailable === undefined || productQuantityAvailable === null ? '' : productQuantityAvailable;
  return (
    <>
      <TableCell sx={{ ...styles.cell, minWidth: tableHeaderColumns[0].minWidth }} align={tableHeaderColumns[0].align}>
        <Button sx={styles.inStock(product.inStock)} onClick={toggleInStock}>
          {product.inStock ? 'Yes' : 'No'}
        </Button>
      </TableCell>
      <TableCell sx={{ ...styles.cell, minWidth: tableHeaderColumns[1].minWidth }} align={tableHeaderColumns[1].align}>
        <Box sx={styles.quantityCell} onClick={stopPropagation}>
          <NumericFormat
            value={displayValue}
            sx={{ ...styles.quantityAvailable, input: styles.quantityAvailableInput }}
            customInput={CommonInput}
            decimalSeparator={getSeparator('decimal')}
            thousandSeparator={getSeparator('group')}
            decimalScale={2}
            onValueChange={(value) => {
              if (value.floatValue !== undefined) {
                setProductQuantityAvailable(value.floatValue);
              }
            }}
            onBlur={onQuantityBlur}
          />
          <UnitsMenu units={productQuantityAvailableUnits} onChange={onUpdateQtyAvailableUnits} />
        </Box>
      </TableCell>
      <TableCell sx={{ ...styles.cell, minWidth: tableHeaderColumns[2].minWidth }} align={tableHeaderColumns[2].align}>
        <Typography sx={{ ...styles.labelCell, ...styles.crop }}>{product.crop}</Typography>
      </TableCell>
      <TableCell sx={{ ...styles.cell, minWidth: tableHeaderColumns[3].minWidth }} align={tableHeaderColumns[3].align}>
        <Typography sx={{ ...styles.labelCell }}>{product.name}</Typography>
      </TableCell>
      <TableCell sx={{ ...styles.cell, minWidth: tableHeaderColumns[4].minWidth }} align={tableHeaderColumns[4].align}>
        <Typography sx={{ ...styles.labelCell }}>{product.variety}</Typography>
      </TableCell>
      <TableCell
        sx={{ ...styles.cell, minWidth: tableHeaderColumns[5].minWidth }}
        align={tableHeaderColumns[5].align}
        onClick={stopPropagation}
      >
        <Button variant="outlined" onClick={toggleModal}>
          Edit
        </Button>
        <EditProductModal
          productId={product.id}
          updateProduct={updateProduct}
          updateProductImage={updateProductImage}
          isOpen={modalOpen}
          handleClose={toggleModal}
        />
      </TableCell>
      <TableCell sx={{ ...styles.cell, minWidth: tableHeaderColumns[6].minWidth }} align={tableHeaderColumns[6].align}>
        <Button variant="outlined" onClick={onExpandRow}>
          {isRowExpanded ? 'Collapse' : 'Expand'}
        </Button>
      </TableCell>
    </>
  );
};

const VirtuosoTableRow = ({
  item,
  context,
  ...props
}: {
  item: ProductDetail;
  children?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  'data-index': number;
  'data-item-index': number;
  'data-item-group-index'?: number | undefined;
  'data-known-size': number;
  context?: TableContext | undefined;
}) => {
  const onClickTableRow = () => {
    if (context?.expandedRow === item.id) {
      context?.setExpandedRow(null);
    } else {
      context?.setExpandedRow(item.id);
    }
  };
  const row = (
    <TableRow key={`${item.id}-${props['data-index']}`} sx={styles.tableRow} {...props} onClick={onClickTableRow} />
  );
  if (context?.expandedRow === item.id) {
    return (
      <>
        {row}
        <ProductRowCollapse product={item} expandedRow={context.expandedRow} setExpandedRow={context.setExpandedRow} />
      </>
    );
  }
  return row;
};

const VirtuosoTableComponents: TableComponents<ProductDetail, TableContext> = {
  Table: (props) => <Table stickyHeader {...props} sx={styles.table} />,
  TableHead: forwardRef((props, ref) => <TableHead sx={styles.tableHead} {...props} ref={ref} />),
  TableRow: VirtuosoTableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const tableHeaderColumns: {
  key: string;
  name: string;
  subText?: string;
  align: TableCellProps['align'];
  width?: string | number | undefined;
  minWidth?: string | number | undefined;
  maxWidth?: string | number | undefined;
}[] = [
  { key: 'Stock', name: 'In Stock?', align: 'left', width: '90px', minWidth: '90px' },
  {
    key: 'quantity',
    name: 'Quantity Available',
    subText: '(Recommended)',
    align: 'left',
    width: '240px',
    minWidth: '200px',
  },
  { key: 'crop', name: 'Crop', align: 'left', minWidth: '120px' },
  { key: 'name', name: 'Name', align: 'left', minWidth: '180px' },
  { key: 'variety', name: 'Type', align: 'left', minWidth: '200px' },
  { key: 'edit', name: '', align: 'center', width: '64px' },
  { key: 'details', name: '', align: 'center', width: '90px' },
];

const fixedHeaderContent = () => {
  return (
    <TableRow sx={styles.headerRow}>
      {tableHeaderColumns.map((column) => (
        <TableCell
          key={column.key}
          variant="head"
          align={column.align}
          style={{ minWidth: column.minWidth, width: column.width }}
          sx={styles.tableHeadCell}
        >
          <Box sx={styles.headerCellName}>
            {column.name}
            {column.subText && (
              <Typography sx={styles.headerCellSubText} variant="caption">
                {column.subText}
              </Typography>
            )}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
};

export const EditProductsTable: FC = () => {
  const { updateProduct, products, loadMore, updateProductImage } = useEditProducts();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  return (
    <TableVirtuoso
      data={products}
      style={{ height: 'calc(-258px + 100vh)' }}
      components={VirtuosoTableComponents}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={rowContent}
      endReached={loadMore}
      overscan={500}
      context={{ updateProduct, updateProductImage, expandedRow, setExpandedRow }}
    />
  );
};
