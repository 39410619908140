export const styles = {
  container: {
    padding: '16px 16px 0px',
  },
  desktopOnly: {
    textAlign: 'center',
    fontSize: '20px',
  },
  dashboardLink: {},
};
