import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  container: {
    position: 'relative',
    padding: '0 0 16px 88px',
    margin: '0 -88px 16px',
    overflow: 'hidden',
    [`@media (min-width: 1900px)`]: {
      ':before': {
        position: 'absolute',
        zIndex: 1,
        content: '""',
        height: '100%',
        width: '32px',
        left: 0,
        background: 'linear-gradient(to right, rgba(251, 251, 251, 1), rgba(251, 251, 251, 0))',
      },
      ':after': {
        position: 'absolute',
        zIndex: 2,
        content: '""',
        height: '100%',
        width: '32px',
        right: 0,
        top: 0,
        background: 'linear-gradient(to left, rgba(251, 251, 251, 1), rgba(251, 251, 251, 0))',
      },
    },

    '& .slick-slider:not([dir])': {
      marginLeft: '-22px',
    },

    '& .slick-track': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& .slick-arrow': {
      position: 'absolute',
      top: '8px',
      '&.slick-next': {
        right: '88px',
      },
      '&.slick-prev': {
        right: '128px',
      },
    },
    '& .slick-list': {
      margin: '0 -16px',
      paddingLeft: '16px',
      position: 'relative',
    },
    '& .slick-slide': {
      margin: '0 22px',
      height: 'inherit',
      '& > div': {
        height: '100%',
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 2,

      '& .slick-slider:not([dir])': {
        marginLeft: '-8px',
      },

      '& .slick-list': {
        margin: '0 -12px',
        paddingLeft: '12px',
      },
      '& .slick-slide': {
        margin: '8px',
        padding: 0,
      },
      '& .slick-arrow': {
        top: '0px',
      },
      '& .slick-arrow.slick-prev ': {
        right: '128px',
      },
    },
  },
  header: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 1.2,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '24px',
    },
  },
  subheader: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#71717A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      fontWeight: 600,
    },
  },
  categoryInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '140px',
    boxSizing: 'border-box',
    padding: '8px 8px 8px 16px',
    borderRadius: 2,
    cursor: 'pointer',
    backgroundColor: customTheme.custom.bannerBlue,
    transition: 'all 0.3s ease-out',
    color: customTheme.custom.white,
    ':hover': {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  categoryImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 112,
    height: 112,
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
    marginBottom: 1,
  },
  logo: {
    width: 45,
    height: 45,
    objectFit: 'contain',
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    userSelect: 'none',
  },
};
