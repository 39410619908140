import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Box } from '@mui/material';

import { ProductsFiltersForm } from 'app/api/products';
import { useProductsFiltersWrap } from '../../../../components/ProductsFilters/hooks';
import { geneticFilterValues, organicFilterValues } from '../../../../constants';
import { ColorFilter } from '../ColorFilter';
import { ContinentFilter } from '../ContinentFilter';
import { TypeFilter } from '../TypeFilter';
import { WeightFilter } from '../WeightFilter';
import { MaturityFilter } from '../MaturityFilter';
import { OrganicSeedFilter } from '../OrganicFilter';
import { styles } from './styles';

interface FilterListProps {
  control: Control<ProductsFiltersForm>;
  parsedColors: {
    value: string;
    label: string;
  }[];
  parsedContinents: {
    value: string;
    label: string;
  }[];
  isMaturityFilter: boolean;
  isOrganicFilter: boolean;
  isWeightFilter: boolean;
  isMaturityRange: boolean;
  isWeightRange: boolean;
  minWeightLimit: number;
  maxWeightLimit: number;
  weightStep: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
  maturityStep: number;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
  setValue: UseFormSetValue<ProductsFiltersForm>;
}

export const FilterList: FC<FilterListProps> = ({
  control,
  isMaturityFilter,
  isMaturityRange,
  isOrganicFilter,
  isWeightFilter,
  isWeightRange,
  maxMaturityLimit,
  minMaturityLimit,
  maxWeightLimit,
  minWeightLimit,
  parsedColors,
  parsedContinents,
  maturityStep,
  weightStep,
  setValue,
  reset,
  getValues,
}) => {
  const {
    handleChangeMaturityCheckBox,
    handleChangeMaturitySlider,
    handleChangeWeightCheckBox,
    handleChangeWeightSlider,
    // SPZ-1550
    // handleChangeResistancesCheckBox,
  } = useProductsFiltersWrap({ setValue });

  const isColorFilter = parsedColors.length > 1;

  return (
    <Box sx={styles.wrap}>
      <TypeFilter control={control} data={geneticFilterValues} getValues={getValues} reset={reset} />
      {isColorFilter && (
        <ColorFilter control={control} data={parsedColors} getValues={getValues} reset={reset} setValue={setValue} />
      )}
      <ContinentFilter
        control={control}
        data={parsedContinents}
        getValues={getValues}
        reset={reset}
        setValue={setValue}
      />
      {isWeightFilter && (
        <WeightFilter
          control={control}
          isWeightRange={isWeightRange}
          minWeightLimit={minWeightLimit}
          maxWeightLimit={maxWeightLimit}
          weightStep={weightStep}
          getValues={getValues}
          handleChangeWeightCheckBox={handleChangeWeightCheckBox}
          handleChangeWeightSlider={handleChangeWeightSlider}
          reset={reset}
        />
      )}
      {isMaturityFilter && (
        <MaturityFilter
          control={control}
          isMaturityRange={isMaturityRange}
          minMaturityLimit={minMaturityLimit}
          maxMaturityLimit={maxMaturityLimit}
          maturityStep={maturityStep}
          getValues={getValues}
          handleChangeMaturitySlider={handleChangeMaturitySlider}
          handleChangeMaturityCheckBox={handleChangeMaturityCheckBox}
          reset={reset}
        />
      )}
      {isOrganicFilter && (
        <OrganicSeedFilter control={control} data={organicFilterValues} getValues={getValues} reset={reset} />
      )}
    </Box>
  );
};
