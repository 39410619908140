import { FC } from 'react';

import { useTrackPageViewedEvent } from 'rudderAnalytics';
import { AuthMainUi } from '../../components/AuthMainUi';
import { CreatePasswordForm } from '../CreatePasswordPage/components/CreatePasswordForm';

export const CreateNewPasswordPage: FC = () => {
  useTrackPageViewedEvent();

  return (
    <AuthMainUi>
      <CreatePasswordForm isResetPassword />
    </AuthMainUi>
  );
};
