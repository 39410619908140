import { Navigate, Route } from 'react-router-dom';

import { AuthProvider } from 'app/providers';
import {
  Notification,
  ProtectedBuyerRoute,
  ProtectedRoute,
  ProtectedSellerRoute,
  PublicRoute,
  ProtectedMobileRoute,
} from 'components';
import { Pagelayout } from 'layout/PageLayout';
import {
  AboutUsPage,
  CreatePasswordPage,
  CreateNewPasswordPage,
  ResetPasswordPage,
  LoginPage,
  BuyerHomePage,
  ContactUsPage,
  CompanyDetailsPage,
  ProductDetailsPage,
  DashboardPage,
  PlaceOrderPage,
  ProductsListPage,
  ProductsListPageGuard,
  OrderDetailsPage,
  QuoteRequestPage,
  MessagesPage,
  NotificationsPage,
  ChatPage,
  QuoteDetailsPage,
  MaintenancePage,
  ShipmentTrackingPage,
  ChangePasswordPage,
  EditProductsPage,
} from 'pages';
import { DashboardHomePage } from 'pages/DashboardHomePage';
import { ROUTES } from './constants';

export const routes = (
  <Route
    element={
      <AuthProvider>
        <Notification />
      </AuthProvider>
    }
  >
    <Route path={ROUTES.common.maintenance} element={<MaintenancePage />} />

    <Route element={<PublicRoute />}>
      <Route path={ROUTES.auth.login} element={<LoginPage />} />
      <Route path={ROUTES.auth.resetPassword} element={<ResetPasswordPage />} />
      <Route path={ROUTES.auth.accountActivation} element={<CreatePasswordPage />} />
      <Route path={ROUTES.auth.createNewPassword} element={<CreateNewPasswordPage />} />
    </Route>

    <Route element={<ProtectedRoute />}>
      <Route path={ROUTES.common.changePassword} element={<ChangePasswordPage />} />

      <Route element={<Pagelayout />}>
        <Route path={ROUTES.common.about} element={<AboutUsPage />} />
        <Route path={ROUTES.common.contact} element={<ContactUsPage />} />
        <Route path={ROUTES.common.company} element={<CompanyDetailsPage />} />
        <Route path={ROUTES.common.messages} element={<MessagesPage />} />
        <Route path={ROUTES.common.product} element={<ProductDetailsPage />} />
        <Route path={ROUTES.common.order} element={<OrderDetailsPage />} />
        <Route path={ROUTES.common.shipmentTracking} element={<ShipmentTrackingPage />} />
        <Route path={ROUTES.dashboard._} element={<DashboardHomePage />} />
        <Route path={ROUTES.dashboard._} element={<DashboardHomePage />} />
        <Route path={ROUTES.dashboard.orders._} element={<DashboardPage />} />
        <Route path={ROUTES.dashboard.orders.tab} element={<DashboardPage />} />
        <Route path={ROUTES.dashboard.samples._} element={<DashboardPage />} />
        <Route path={ROUTES.dashboard.samples.tab} element={<DashboardPage />} />

        <Route element={<ProtectedSellerRoute />}>
          <Route path={ROUTES.seller.edit._} element={<EditProductsPage />} />
        </Route>

        <Route element={<ProtectedBuyerRoute />}>
          <Route path={ROUTES.buyer._} element={<BuyerHomePage />} />
          <Route path={ROUTES.buyer.placeOrder} element={<PlaceOrderPage />} />
          <Route path={`${ROUTES.buyer.products}`} element={<ProductsListPageGuard />}>
            <Route path="" element={<ProductsListPage />} />
          </Route>
          <Route path={`${ROUTES.buyer.productsExtended}`} element={<ProductsListPageGuard />}>
            <Route path="" element={<ProductsListPage />} />
          </Route>
          <Route path={ROUTES.buyer.quoteRequest} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.sampleRequest} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.quoteRequestCompany} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.sampleRequestCompany} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.quoteRequestAdd} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.sampleRequestAdd} element={<QuoteRequestPage />} />
        </Route>

        <Route element={<ProtectedMobileRoute />}>
          <Route path={ROUTES.common.notifications} element={<NotificationsPage />} />
          <Route path={ROUTES.common.chat} element={<ChatPage />} />
          <Route path={ROUTES.common.quoteRequestDetails} element={<QuoteDetailsPage />} />
          <Route path={ROUTES.common.sampleRequestDetails} element={<QuoteDetailsPage />} />
        </Route>
      </Route>
    </Route>
    <Route path="*" element={<Navigate to={ROUTES.buyer._} replace />} />
  </Route>
);
