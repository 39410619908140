import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B5,
  },
  value: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'right',
    color: customTheme.custom.white,
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
