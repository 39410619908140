import { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { URLSearchParamsInit, createSearchParams, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { RESTRICTED_CROPS } from 'app/api/crops';
import { ProductDetail, getProductData, PRODUCT_NOT_FOUND_ERROR } from 'app/api/products';
import { getDefaultUserRoute } from 'app/providers/utils';
import { ROUTES } from 'app/routes/constants';
import { useAuth, useChatsListQuery, useCreateChannelMutation, useModal } from 'hooks';
import { getFullTechStats, getKeyTechStats } from 'utils/productDetailsUtils';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { notificationObserver } from 'utils/observer';
import { useQuoteRequestsQuery } from 'pages/DashboardPage/hooks/useQuoteRequestsQuery';
import { SortTypes } from 'pages/DashboardPage/types';
import { QuoteRequestStatus } from 'app/api/quotes';
import { TechStatData } from '../types';
import { getReStartMessageString } from '../utils';

interface ProductDetailsPageHanlers {
  channelUrl: string;
  isBuyer: boolean;
  isCreateChannelLoading: boolean;
  isLoading: boolean;
  data?: ProductDetail;
  hideRequestButton: boolean;
  isChatModalOpen: boolean;
  keyTechStats: TechStatData;
  fullTechStats: TechStatData;
  isVerticalImage: boolean | null;
  legendImage?: string | null;
  startMessageString: string;
  handleCloseChatModal: () => void;
  onRequestQuoteClick: () => void;
  onRequestSampleClick: () => void;
  onSendMessageClick: () => void;
  handleImageLoad: (e: SyntheticEvent) => void;
}

export const useProductDetailsPageHanlers = (): ProductDetailsPageHanlers => {
  const [isVerticalImage, setIsVerticalImage] = useState<boolean | null>(null);

  const {
    open: isChatModalOpen,
    handleOpenModal: handleOpenChatModal,
    handleCloseModal: handleCloseChatModal,
  } = useModal();
  const { isBuyer, activeUser } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const { isLoading, data } = useQuery(['company', id], () => getProductData(Number(id)), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      const isRestricted = RESTRICTED_CROPS.some((crop) => crop === data.crop.toLowerCase());

      if (isRestricted) {
        notificationObserver.publish({
          type: 'error',
          title: PRODUCT_NOT_FOUND_ERROR,
        });
        navigate(getDefaultUserRoute(isBuyer));
      }
    },
    onError: () => {
      notificationObserver.publish({
        type: 'error',
        title: PRODUCT_NOT_FOUND_ERROR,
      });
      navigate(getDefaultUserRoute(isBuyer));
    },
  });

  const buyerCompanyId = Number(activeUser?.company.id);
  const sellerCompanyId = Number(data?.company.id);

  const startMessageString = getReStartMessageString(data?.crop, data?.variety, data?.name);

  const { chatsList, isChatsListLoading } = useChatsListQuery({
    buyerCompanyId,
    sellerCompanyId,
    enabled: isBuyer && !!buyerCompanyId && !!sellerCompanyId,
  });

  const channelUrl = useMemo(() => chatsList?.[0]?.chatId ?? '', [chatsList]);

  const { isCreateChannelLoading, handleCreateChannel } = useCreateChannelMutation({
    buyerCompany: buyerCompanyId,
    sellerCompany: sellerCompanyId,
    onSuccess: handleOpenChatModal,
  });

  const { data: quotes } = useQuoteRequestsQuery({
    enabled: !!sellerCompanyId,
    sortValue: SortTypes.DEFAULT,
    status: [QuoteRequestStatus.OPEN],
    sellerCompanyId,
  });

  const onRequestQuoteClick = useCallback(() => {
    const existingQuoteRequest = quotes?.find((quote) => !quote.isSampleType);
    if (existingQuoteRequest) {
      const path = generatePath(ROUTES.buyer.quoteRequestAdd, {
        companyDisplayId: data?.company.displayId,
        quoteId: existingQuoteRequest.id,
      });
      navigate({
        pathname: path,
        search: createSearchParams({ productId: id } as URLSearchParamsInit).toString(),
      });
      handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
    } else {
      const path = generatePath(ROUTES.buyer.quoteRequest, {
        companyDisplayId: data?.company.displayId,
        productId: id,
      });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
    }
  }, [data?.company.displayId, id, navigate, handleTrackLinkClickEvent, quotes]);

  const onRequestSampleClick = useCallback(() => {
    const existingSampleRequest = quotes?.find((quote) => quote.isSampleType);
    if (existingSampleRequest) {
      const path = generatePath(ROUTES.buyer.sampleRequestAdd, {
        companyDisplayId: data?.company.displayId,
        quoteId: existingSampleRequest.id,
      });
      navigate({
        pathname: path,
        search: createSearchParams({ productId: id } as URLSearchParamsInit).toString(),
      });
      handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
    } else {
      const path = generatePath(ROUTES.buyer.sampleRequest, {
        companyDisplayId: data?.company.displayId,
        productId: id,
      });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: 'Request Sample', type: AnalyticsLinkType.BUTTON });
    }
  }, [data?.company.displayId, id, navigate, handleTrackLinkClickEvent, quotes]);

  const onSendMessageClick = useCallback(() => {
    if (channelUrl?.length) {
      handleOpenChatModal();
    } else {
      handleCreateChannel();
    }
  }, [channelUrl?.length, handleCreateChannel, handleOpenChatModal]);

  const productMainAttributes = useMemo(
    () =>
      data
        ? Object.entries(data)
            .filter(([key]) => key !== 'other')
            .map(([key, value]) => ({ name: key, value }))
        : [],
    [data],
  );

  const productOtherAttributes = useMemo(
    () =>
      data?.other
        ? Object.entries(data.other)
            .filter(([key, value]) => !!key && !!value)
            .map(([key, value]) => ({ name: key, value }))
        : [],
    [data?.other],
  ) as TechStatData;

  const productAllAttributes = [...productMainAttributes, ...productOtherAttributes];
  const keyTechStats = getKeyTechStats(productAllAttributes);
  const fullTechStats = getFullTechStats(productAllAttributes, productOtherAttributes);

  const handleImageLoad = useCallback((event: SyntheticEvent) => {
    const img = event?.target as HTMLImageElement;
    const width = img?.naturalWidth;
    const height = img?.naturalHeight;
    const acpectRatio = width / height;

    setIsVerticalImage(height > width || acpectRatio < 1.35);
  }, []);

  return {
    channelUrl,
    isBuyer,
    isCreateChannelLoading,
    isLoading: isLoading || isChatsListLoading,
    data,
    keyTechStats,
    fullTechStats,
    hideRequestButton: !isBuyer,
    isChatModalOpen,
    isVerticalImage,
    legendImage: data?.image ? data.companyLegendImage : null,
    startMessageString,
    handleCloseChatModal,
    onRequestQuoteClick,
    onRequestSampleClick,
    onSendMessageClick,
    handleImageLoad,
  };
};
