import { customTheme } from 'theme/MuiThemeProvider/theme';
import { layoutMinWidth, layoutMaxWidth } from '../constants/styleConstants';

export const styles = {
  wrap: (isScrollable: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: isScrollable ? 'auto' : 'hidden',
    backgroundColor: customTheme.custom.mainBg,
  }),
  contentWrap: (isSubheader: boolean, isContentWithMenu: boolean) => ({
    flex: '1',
    width: '100%',
    minWidth: layoutMinWidth,
    maxWidth: layoutMaxWidth,
    margin: `${isSubheader ? '-42px' : '0'} auto 0`,
    ...(isContentWithMenu && {
      display: 'flex',
    }),
  }),
  content: {
    flex: '1',
    padding: '24px 24px 0px',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
};
