import { useState } from 'react';

export const useModal = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  return {
    open,
    handleCloseModal,
    handleOpenModal,
  };
};
