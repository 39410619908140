import { FC, MouseEvent, useState } from 'react';

import { Box, ButtonBase, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { CommonTooltip } from 'components';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { UnitsType } from 'app/api/quotes';
import { styles } from './styles';

const options = [
  { label: 'kg', value: UnitsType.KG },
  { label: 'lb', value: UnitsType.LB },
  { label: 'thousand seeds', value: UnitsType.THOUSAND_SEEDS },
];

export const UnitsMenu: FC<{
  units?: UnitsType;
  onChange: (unit: UnitsType) => void;
  sxContainer?: SxProps;
  sxLabel?: SxProps;
  isEditable?: boolean;
}> = ({ units, onChange, sxContainer = {}, sxLabel = {}, isEditable = true }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (value: UnitsType) => {
    onChange(value);
    setAnchorEl(null);
  };
  const displayUnit = options.find((option) => option.value === units);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelStyles: any = sxLabel;
  return (
    <Box sx={{ ...styles.units, ...sxContainer }}>
      <Typography sx={{ ...styles.unitsLabel, ...labelStyles }}>{displayUnit?.label ?? ''}</Typography>
      {isEditable && (
        <>
          <CommonTooltip title="Change Units" placement="top" sx={styles.unitsTooltip}>
            <ButtonBase
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={styles.unitsButton}
            >
              <EditIcon />
            </ButtonBase>
          </CommonTooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.label} onClick={() => onSelect(option.value)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};
