import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
  useController,
} from 'react-hook-form';

import { QuoteProduct, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { useModal } from 'hooks';
import { QuoteTableFormData } from '../types';
import { amountNumberExp, bigNumberExp } from '../utils';

interface UseEditProductAmountProps {
  currentEditId: number | null;
  choosedProducts: FieldArrayWithId<QuoteTableFormData, 'quotes', 'listId'>[];
  control: Control<QuoteTableFormData>;
  setValue: UseFormSetValue<QuoteTableFormData>;
  setCurrentEditId: (id: number | null) => void;
  setCurrentRemoveId: (id: number | null) => void;
  trigger: UseFormTrigger<QuoteTableFormData>;
  clearErrors: UseFormClearErrors<QuoteTableFormData>;
  appendQuoteProductRow: UseFieldArrayAppend<QuoteTableFormData, 'quotes'>;
  removeQuoteProductRow: UseFieldArrayRemove;
}

export const useEditProductAmount = ({
  control,
  currentEditId,
  choosedProducts,
  setCurrentEditId,
  setCurrentRemoveId,
  trigger,
  setValue,
  clearErrors,
  appendQuoteProductRow,
  removeQuoteProductRow,
}: UseEditProductAmountProps) => {
  const { open: isEditAmountModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const [isValidationError, setIsValidationError] = useState(false);

  const product = choosedProducts.find((item) => item.id === currentEditId);
  const index = product ? choosedProducts.indexOf(product) : -1;
  const amountFieldName = `quotes.${index}.updatedAmount` as const;

  const {
    field: { value: updatedAmountValue },
  } = useController({
    control,
    name: amountFieldName,
    defaultValue: product?.amount,
  });

  const {
    field: { value: updatedAmountType },
  } = useController({
    control,
    name: `quotes.${index}.updatedAmountType`,
    defaultValue: product?.amountType,
  });

  useEffect(() => {
    setValue(amountFieldName, product?.amount);
  }, [amountFieldName, product?.amount, setValue]);

  const updatedAmount = updatedAmountValue ? Number(updatedAmountValue) : null;
  const prevAmountRef = useRef<number | null>(null);
  const newAmountValue = updatedAmount?.toString();
  const isZeroAmount = newAmountValue === '0';

  useEffect(() => {
    if (product) {
      prevAmountRef.current = product.amount;
    }
  }, [product]);

  const handleOpenEditAmountModal = useCallback(
    (id: number) => {
      setCurrentEditId(id);
      handleOpenModal();
    },
    [handleOpenModal, setCurrentEditId],
  );

  const handleCloseEditAmountModal = useCallback(() => {
    setCurrentEditId(null);
    handleCloseModal();
    clearErrors(amountFieldName);
  }, [amountFieldName, clearErrors, handleCloseModal, setCurrentEditId]);

  const handleConfirmEditAmount = useCallback(() => {
    if (isValidationError) return;

    if (updatedAmount === prevAmountRef.current) {
      handleCloseEditAmountModal();
      return;
    }

    if (isZeroAmount) {
      setCurrentRemoveId(product?.id ?? null);
    } else if (updatedAmount) {
      const srcProduct = { ...product } as QuoteProduct;

      removeQuoteProductRow(index);
      appendQuoteProductRow({
        id: srcProduct.id,
        amount: updatedAmount,
        updatedAmount,
        amountType: updatedAmountType as UnitsType,
        updatedAmountType,
        amountChanged: true,
        status: QuoteProductStatus.AVAILABLE,
        product: { ...srcProduct.product },
        value: undefined,
        updateAcceptedPrice: undefined,
      });

      handleCloseEditAmountModal();
    }
  }, [
    index,
    isZeroAmount,
    isValidationError,
    updatedAmount,
    updatedAmountType,
    product,
    appendQuoteProductRow,
    handleCloseEditAmountModal,
    removeQuoteProductRow,
    setCurrentRemoveId,
  ]);

  const handleChangeAmount = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await trigger(amountFieldName);

      const { value } = e.target;
      const isError = !bigNumberExp.test(value) || !amountNumberExp.test(value);
      setIsValidationError(isError);
    },
    [amountFieldName, trigger],
  );

  return {
    index,
    isEditAmountModalOpen,
    editableProductData: product as QuoteProduct,
    handleCloseEditAmountModal,
    handleOpenEditAmountModal,
    handleConfirmEditAmount,
    handleChangeAmount,
  };
};
