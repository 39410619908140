import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  text: {
    marginBottom: '24px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      marginBottom: '16px',
    },
  },
  title: {
    marginBottom: '12px',
    fontWeight: '600',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '8px',
      fontSize: '14px',
    },
  },
};
