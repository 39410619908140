import { ReactQueryDevtools } from 'react-query/devtools';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import '@sendbird/uikit-react/dist/index.css';

import { rudderInitialize } from 'rudderAnalytics';
import { initializeSentry } from './initializeSentry';
import { routes } from './routes/routes';
import { AppWrapper } from './AppWrapper';
import { SendBirdWrapProvider } from './providers';

const createdRoutes = createRoutesFromElements(routes);
const router = createBrowserRouter(createdRoutes);

initializeSentry();
rudderInitialize();

export const App = () => (
  <AppWrapper>
    <SendBirdWrapProvider>
      <RouterProvider router={router} />
    </SendBirdWrapProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </AppWrapper>
);
