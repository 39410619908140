import { useCallback, useMemo } from 'react';

import { AxiosError } from 'axios';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { COMPANY_NOT_FOUND_ERROR, getCompanyData } from 'app/api/company';
import { NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import { getProductsListQuoteRequestData } from 'app/api/products/service';
import { QUOTE_REQUEST_NOT_FOUND_ERROR, SAMPLE_REQUEST_NOT_FOUND_ERROR, getQuoteData } from 'app/api/quotes';
import { getUserData } from 'app/api/user/service';
import { UserRole } from 'app/api/auth/types';
import { ROUTES } from 'app/routes/constants';
import { UnitsType } from 'app/api/quotes/types';
import { notificationObserver } from 'utils/observer';
import { NO_PRODUCTS_AVAILABLE_TO_REQUEST_ERROR } from '../constants';

export const useQuoteRequestProducts = () => {
  const { companyDisplayId = '', quoteId } = useParams();
  const navigate = useNavigate();

  const matchAddPropductsPage = useMatch(ROUTES.buyer.quoteRequestAdd);
  const matchAddSamplesPage = useMatch(ROUTES.buyer.sampleRequestAdd);
  const matchRequestSampleCompanyPage = useMatch(ROUTES.buyer.sampleRequestCompany);
  const matchRequestSamplePage = useMatch(ROUTES.buyer.sampleRequest);

  const isAddPropductsPage = !!matchAddPropductsPage;
  const isAddSamplesPage = !!matchAddSamplesPage;

  const {
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    data: userData,
  } = useQuery(['current-user-data'], () => getUserData(), {
    refetchOnWindowFocus: false,
  });

  const activeUser = userData?.find(({ kind }) => kind === UserRole.BUYER);

  const handleNavigateToHomePage = useCallback(
    (error: AxiosError | undefined, message: string) => {
      if (!error || error?.response?.status === NOT_FOUND_ERROR_CODE) {
        notificationObserver.publish({
          type: 'error',
          title: message,
        });
        navigate(ROUTES.buyer._);
      }
    },
    [navigate],
  );

  const { isLoading: isQuoteDetailsLoading, data: quoteData } = useQuery(
    ['quote-data', quoteId],
    () => getQuoteData(Number(quoteId)),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      enabled: (isAddPropductsPage || isAddSamplesPage) && !!quoteId,
      onError: (error: AxiosError) => {
        const errorMessage = isAddSamplesPage ? SAMPLE_REQUEST_NOT_FOUND_ERROR : QUOTE_REQUEST_NOT_FOUND_ERROR;
        handleNavigateToHomePage(error, errorMessage);
      },
    },
  );

  const { isLoading: isProductsLoading, data: productsData } = useQuery(
    ['products-request-quote', companyDisplayId],
    () => getProductsListQuoteRequestData({ company: companyDisplayId }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        if (!data.length) {
          handleNavigateToHomePage(undefined, NO_PRODUCTS_AVAILABLE_TO_REQUEST_ERROR);
        }
      },
    },
  );

  const { isLoading: isCompanyLoading, data: company } = useQuery(
    ['company', companyDisplayId],
    () => getCompanyData(companyDisplayId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: AxiosError) => {
        handleNavigateToHomePage(error, COMPANY_NOT_FOUND_ERROR);
      },
    },
  );

  const data = useMemo(() => {
    return productsData?.sort((a, b) => a.crop.localeCompare(b.crop));
  }, [productsData]);

  const defaultUnit = isAddPropductsPage || isAddSamplesPage ? quoteData?.unitsType : activeUser?.preferredUnitsType;

  return {
    data,
    company,
    isLoading: isUserLoading || isUserFetching || isProductsLoading || isCompanyLoading || isQuoteDetailsLoading,
    isAddPropductsPage: !!matchAddPropductsPage || !!matchAddSamplesPage,
    defaultUnit: defaultUnit ?? UnitsType.KG,
    isRequestSamplePage: !!matchRequestSampleCompanyPage || !!matchRequestSamplePage || !!matchAddSamplesPage,
  };
};
