import * as rudderanalytics from 'rudder-sdk-js';

import { QuoteRequestFormProduct } from 'app/api/products/types';
import { formatProductAnalyticsData } from './formatProductAnalyticsData';

export const getQuoteRequestedEventProducts = (data: QuoteRequestFormProduct[]): rudderanalytics.apiObject[] => {
  const results = data.map(({ value, ...product }) => ({
    ...formatProductAnalyticsData({ product }),
    amount: value,
  })) as unknown as rudderanalytics.apiObject[];

  return results;
};
