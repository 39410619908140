import { FC, MouseEvent, useCallback } from 'react';

import { generatePath, NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ProductDetail, ProductStatus } from 'app/api/products/types';
import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { capitalizeFirstLetter } from 'utils/formatText';
import { getVarietyValue } from 'utils/getVarietyValue';
import { styles } from './styles';

type PageHeaderProps = Pick<ProductDetail, 'crop' | 'variety' | 'name' | 'company' | 'status' | 'inStock'> & {
  scrollDown: (e?: MouseEvent) => void;
};

export const PageHeader: FC<PageHeaderProps> = ({ crop, variety, name, company, status, inStock, scrollDown }) => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const path = generatePath(ROUTES.common.company, { displayId: company.displayId });

  const varietyValue = getVarietyValue({ crop, variety, name });
  const isDraft = status === ProductStatus.DRAFT;

  const handleCompanyLinkClick = useCallback(() => {
    handleTrackLinkClickEvent({ type: AnalyticsLinkType.LINK, path, text: company.name });
  }, [company.name, handleTrackLinkClickEvent, path]);

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ ...styles.wrap }}>
        <Typography component="h4" sx={{ ...styles.crop }}>
          {capitalizeFirstLetter(crop)}
        </Typography>
        <Box>
          {(varietyValue || name) && (
            <>
              <Typography component="h2" sx={{ ...styles.title, ...styles.variety }}>
                {varietyValue}
              </Typography>
              {name && (
                <Typography component="span" sx={{ ...styles.title, ...styles.name }}>
                  {name}
                </Typography>
              )}
            </>
          )}
          {isDraft && <Box sx={{ ...styles.draftLabel }}>Draft</Box>}
        </Box>
        <Box sx={{ ...styles.companyWrap }}>
          <NavLink to={path} onClick={handleCompanyLinkClick}>
            <Typography sx={{ ...styles.link }}>{company.name}</Typography>
          </NavLink>
          <Typography sx={{ ...styles.location }}>
            in {company.city}, {company.country}
          </Typography>
        </Box>
      </Box>
      {!inStock && (
        <Box sx={{ ...styles.outOfStockContainer }}>
          <Box sx={{ ...styles.outOfStock }}>Out of stock</Box>
          <Typography sx={{ ...styles.similarProducts }} onClick={scrollDown}>
            See Similar Products
          </Typography>
        </Box>
      )}
    </Box>
  );
};
