import { FC, useCallback, useState } from 'react';

import { ClickAwayListener } from '@mui/base';
import { Box, Tooltip } from '@mui/material';

import { CommonButton, LoadingButton } from 'components';
import { styles } from './styles';

interface ProductsActionsButtonsProps {
  isCreateChannelLoading: boolean;
  isSampleButtonBorder: boolean;
  isMessageButtonBorder?: boolean;
  inStock?: boolean;
  onRequestQuoteClick: () => void;
  onRequestSampleClick: () => void;
  onSendMessageClick: () => void;
}

const messages = {
  outOfStock: 'Out of stock: ',
  outOfStockDetails:
    'This product is out of stock. You can still message the supplier ' +
    'to ask about expected availability, contract productions, or similar products',
  requestQuote: 'Request Quote',
  requestSample: 'Request Sample',
  askQuestion: 'Ask a Question',
};

export const OutOfStockTooltip: FC = () => {
  return (
    <Box sx={styles.tooltip}>
      <Box component="b">{messages.outOfStock}</Box>
      {messages.outOfStockDetails}
    </Box>
  );
};

const useOpenClose = (shouldOpen: boolean) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    if (shouldOpen) {
      setOpen(true);
    }
  }, [shouldOpen, setOpen]);
  return { open, handleClose, handleOpen };
};
export const ProductsActionsButtons: FC<ProductsActionsButtonsProps> = ({
  isCreateChannelLoading,
  isSampleButtonBorder,
  isMessageButtonBorder = false,
  onRequestQuoteClick,
  onRequestSampleClick,
  onSendMessageClick,
  inStock = true,
}) => {
  const tooltipTitle = inStock ? '' : <OutOfStockTooltip />;
  const { open: quoteOpen, handleOpen: handleQuoteOpen, handleClose: handleQuoteClose } = useOpenClose(!inStock);
  const { open: sampleOpen, handleOpen: handleSampleOpen, handleClose: handleSampleClose } = useOpenClose(!inStock);

  return (
    <Box sx={styles.actionsWrap}>
      <ClickAwayListener onClickAway={handleQuoteClose}>
        <Box onClick={handleQuoteOpen} sx={styles.btnContainer}>
          <Tooltip
            title={tooltipTitle}
            placement="top"
            open={quoteOpen}
            onClose={handleQuoteClose}
            onOpen={handleQuoteOpen}
          >
            <Box component="div">
              <CommonButton sx={styles.quoteButton} onClick={onRequestQuoteClick} disabled={!inStock}>
                {messages.requestQuote}
              </CommonButton>
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={handleSampleClose}>
        <Box onClick={handleSampleOpen} sx={styles.sampleBtnContainer}>
          <Tooltip
            title={tooltipTitle}
            placement="top"
            open={sampleOpen}
            onClose={handleSampleClose}
            onOpen={handleSampleOpen}
          >
            <Box component="div">
              <CommonButton
                sx={styles.sampleButton(isSampleButtonBorder, inStock)}
                onClick={onRequestSampleClick}
                disabled={!inStock}
              >
                {messages.requestSample}
              </CommonButton>
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>

      <LoadingButton
        loading={isCreateChannelLoading}
        sx={styles.messageButton(isMessageButtonBorder)}
        onClick={onSendMessageClick}
      >
        {messages.askQuestion}
      </LoadingButton>
    </Box>
  );
};
