import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const NAV_WIDTH = '240px';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '4px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.black.B5,
    minHeight: '100vh',
  },
  link: (isActive: boolean) => ({
    boxSizing: 'border-box',
    width: '100%',
    ':hover': {
      backgroundColor: customTheme.custom.black.B10,
    },
    ...(isActive && {
      backgroundColor: customTheme.custom.black.B20,
    }),
    color: `${customTheme.palette.info.main} !important`,
    padding: '8px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: '8px',
    },
  }),
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '8px 0 0 16px',
    boxSizing: 'border-box',
    width: '100%',
  },
  pageWrap: {
    minHeight: 'calc(100vh - 150px)',
    margin: '-24px -24px 0 -24px',
    display: 'flex',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
    },
  },
  navigation: {
    position: 'fixed',
    width: NAV_WIDTH,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
      width: 0,
    },
  },
  content: {
    marginLeft: NAV_WIDTH,
    padding: '16px',
    width: '100%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginLeft: 0,
    },
  },
};
