import { FC } from 'react';

import { Box } from '@mui/material';
import { DashboardMainTabs, QuoteRequestsListData } from 'app/api/quotes/types';
import { OrdersListData } from 'app/api/orders/types';
import { DashboardTab } from '../../types';
import { QuoteRequestsTabInner } from '../QuoteRequestsTabInner';
import { OrdersTabInner } from '../OrdersTabInner';
import { styles } from './styles';

interface DashboardPageInnerProps {
  isBuyer: boolean;
  activeTab: DashboardTab;
  quoteRequestsData: {
    list: QuoteRequestsListData;
    canceledList: QuoteRequestsListData;
  };
  ordersData: {
    list: OrdersListData;
    canceledList: OrdersListData;
  };
}

export const DashboardPageInner: FC<DashboardPageInnerProps> = ({
  isBuyer,
  activeTab,
  quoteRequestsData,
  ordersData,
}) => {
  return (
    <Box sx={styles.container}>
      {activeTab.path === DashboardMainTabs.QUOTE_REQUESTS || activeTab.path === DashboardMainTabs.SAMPLE_REQUESTS ? (
        <QuoteRequestsTabInner isBuyer={isBuyer} tab={activeTab.path} data={quoteRequestsData} />
      ) : (
        <OrdersTabInner isBuyer={isBuyer} tab={activeTab.path} data={ordersData} />
      )}
    </Box>
  );
};
