import { FC } from 'react';

import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Box } from '@mui/material';

import { Quote, UnitsType } from 'app/api/quotes';
import { useQuoteSellerTotal } from 'pages/DashboardPage/hooks';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { QuoteSellerInitialCard } from '../QuoteSellerInitialCard';
import { QuoteRequestActions } from '../QuoteRequestActions';
import { styles } from './styles';

interface QuoteSellerInitialListProps {
  control: Control<QuoteTableFormData>;
  isAllConfirmedOrNotAvailable: boolean;
  isAllProductsNotAvailable: boolean;
  isBuyer: boolean;
  isCanceledQuote: boolean;
  isChatOpen: boolean;
  isNewMessages: boolean;
  isWaiting: boolean;
  quoteData: Quote;
  addProducts: () => void;
  handleOpenChat: () => void;
  setValue: UseFormSetValue<QuoteTableFormData>;
  watch: UseFormWatch<QuoteTableFormData>;
}

export const QuoteSellerInitialList: FC<QuoteSellerInitialListProps> = ({
  control,
  isAllConfirmedOrNotAvailable,
  isAllProductsNotAvailable,
  isBuyer,
  isCanceledQuote,
  isChatOpen,
  isNewMessages,
  isWaiting,
  quoteData,
  addProducts,
  handleOpenChat,
  setValue,
  watch,
}) => {
  const { totalPrice, totalAmount } = useQuoteSellerTotal(watch);

  const data = quoteData?.quoteproducts || [];
  const unitsType = quoteData?.unitsType ?? UnitsType.KG;

  return (
    <Box sx={styles.wrap}>
      {data.map((data, index) => (
        <QuoteSellerInitialCard
          key={data.id}
          data={data}
          unitsType={unitsType}
          control={control as unknown as Control}
          index={index}
          isDisabled={isCanceledQuote}
          setValue={setValue}
        />
      ))}

      <QuoteRequestActions
        control={control}
        isAllProductsNotAvailable={isAllProductsNotAvailable}
        isAllConfirmedOrNotAvailable={isAllConfirmedOrNotAvailable}
        isBuyer={isBuyer}
        isCanceledQuote={isCanceledQuote}
        isChatOpen={isChatOpen}
        isNewMessages={isNewMessages}
        isWaiting={isWaiting}
        quoteData={quoteData}
        totalAmount={totalAmount}
        totalPrice={totalPrice}
        unitsType={unitsType}
        addProducts={addProducts}
        handleOpenChat={handleOpenChat}
      />
    </Box>
  );
};
