import { useMemo } from 'react';

import { useQuery } from 'react-query';

import { getQuoteRequestsData } from 'app/api/quotes/service';
import { QuoteRequestStatus, QuoteRequestsListData } from 'app/api/quotes/types';
import { SortTypes } from '../types';
import { sortQuotes } from '../utils';

interface QuoteRequestsQueryI {
  enabled: boolean;
  sortValue: SortTypes;
  requestSample?: boolean;
  buyerCompanyId?: number;
  sellerCompanyId?: number;
  status: QuoteRequestStatus[];
  key?: string;
}

interface QuoteRequestsQueryReturn {
  isLoading: boolean;
  isSuccess: boolean;
  data: QuoteRequestsListData;
}

export const useQuoteRequestsQuery = ({
  enabled,
  sortValue,
  status,
  requestSample,
  buyerCompanyId,
  sellerCompanyId,
  key = 'quote-requests',
}: QuoteRequestsQueryI): QuoteRequestsQueryReturn => {
  const { isLoading, isFetching, isSuccess, data } = useQuery(
    [key, status, requestSample],
    () => getQuoteRequestsData({ status, requestSample, buyerCompanyId, sellerCompanyId }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    },
  );

  const sortedData = useMemo(() => (data ? sortQuotes([...data], sortValue) : []), [data, sortValue]);

  return {
    isLoading: isLoading || isFetching,
    isSuccess,
    data: sortedData,
  };
};
