export const styles = {
  paperStyles: (isChatOpen: boolean) => ({
    minWidth: '862px',
    maxWidth: '2000px',
    minHeight: '236px',
    maxHeight: isChatOpen ? '408px' : 'unset',
    overflow: 'visible',
  }),
  loaderBox: {
    position: 'absolute',
    width: 'calc(100% - 28px)',
    height: 'calc(100% - 80px)',
  },
};
