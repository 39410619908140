import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { useAuth } from 'hooks';

export const PublicRoute = () => {
  const { token, isBuyer } = useAuth();
  const location = useLocation();
  // Note: We ignore the redirect login on login route because the login page
  // will handle the redirect if the user is already logged in
  if (token && location.pathname !== ROUTES.auth.login) {
    const page = isBuyer ? ROUTES.buyer._ : ROUTES.seller.dashboard._;
    return <Navigate to={page} />;
  }

  return <Outlet />;
};
