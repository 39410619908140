import { ChangeEvent, useCallback } from 'react';

import { UseFormSetValue } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { useSearchParams } from 'react-router-dom';
import { AdditionalFiltersControlNames, FiltersNames } from '../../../constants';

interface UseProductsFiltersWrapProps {
  setValue: UseFormSetValue<ProductsFiltersForm>;
}

export const useProductsFiltersWrap = ({ setValue }: UseProductsFiltersWrapProps) => {
  const [, setSearchParams] = useSearchParams();

  const handleChangeWeightSlider = useCallback(
    (newValue: number[]) => {
      setValue(AdditionalFiltersControlNames.WEIGHT, newValue);

      setSearchParams((params) => {
        params.set(FiltersNames.MIN_WEIGHT, newValue[0].toString());
        params.set(FiltersNames.MAX_WEIGHT, newValue[1].toString());
        return params;
      });
    },
    [setSearchParams, setValue],
  );

  const handleChangeWeightCheckBox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.checked;
      setValue(AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT, value);

      if (value) {
        setSearchParams((params) => {
          params.delete(FiltersNames.APPLY_WEIGHT_FILTER);
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.set(FiltersNames.APPLY_WEIGHT_FILTER, 'true');
          return params;
        });
      }
    },
    [setSearchParams, setValue],
  );

  const handleChangeMaturitySlider = useCallback(
    (newValue: number[]) => {
      setValue(AdditionalFiltersControlNames.MATURITY, newValue);

      setSearchParams((params) => {
        params.set(FiltersNames.MIN_MATURITY, newValue[0].toString());
        params.set(FiltersNames.MAX_MATURITY, newValue[1].toString());
        return params;
      });
    },
    [setSearchParams, setValue],
  );

  const handleChangeMaturityCheckBox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.checked;
      setValue(AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY, value);

      if (value) {
        setSearchParams((params) => {
          params.delete(FiltersNames.APPLY_MATURITY_FILTER);
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.set(FiltersNames.APPLY_MATURITY_FILTER, 'true');
          return params;
        });
      }
    },
    [setSearchParams, setValue],
  );

  const handleChangeResistancesCheckBox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.checked;
      setValue(AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_RESISTANCES, value);

      if (value) {
        setSearchParams((params) => {
          params.delete(FiltersNames.APPLY_RESISTANCES_FILTER);
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.set(FiltersNames.APPLY_RESISTANCES_FILTER, 'true');
          return params;
        });
      }
    },
    [setSearchParams, setValue],
  );

  return {
    handleChangeMaturityCheckBox,
    handleChangeMaturitySlider,
    handleChangeWeightCheckBox,
    handleChangeWeightSlider,
    handleChangeResistancesCheckBox,
  };
};
