import { FC } from 'react';

import { Control, UseFormSetValue } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { QuoteProduct, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { TextFormField } from 'components';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { useSellersInitialQuoteActions } from 'pages/DashboardPage/hooks';
import { ProductVariety, SellerActionsMenu } from 'pages/DashboardPage/components';
import { quotePoductStatusLabel } from 'pages/DashboardPage/constants';
import { validationRules } from 'pages/DashboardPage/utils';
import { formatNumber } from 'utils/formatText';
import { cardItemTitles } from '../../constants';
import { CellHeader } from '../CellHeader';
import { useQuoteSellerInitialCard } from './hooks';
import { styles } from './styles';

interface QuoteSellerInitialCardProps {
  data: QuoteProduct;
  unitsType: UnitsType;
  control: Control;
  index: number;
  isDisabled: boolean;
  setValue: UseFormSetValue<QuoteTableFormData>;
}

export const QuoteSellerInitialCard: FC<QuoteSellerInitialCardProps> = ({
  data,
  unitsType,
  control,
  index,
  isDisabled,
  setValue,
}): JSX.Element => {
  const {
    quoteProductStatus,
    isNotAvailable,
    isOpacity,
    isRemoved,
    isRestricted,
    isThousandSeedsUnit,
    isUnavailable,
    totalPrice,
  } = useQuoteSellerInitialCard({ control, data, index, unitsType });

  const { open, anchorEl, handleUndo, handleClick, handleClose, handleMarkNotAvailable, handleRemove } =
    useSellersInitialQuoteActions({
      index,
      setValue,
    });

  const titles = cardItemTitles(unitsType);

  return (
    <Box sx={styles.wrap}>
      <Box sx={{ ...styles.varietyWrap(isRestricted), ...styles.opacityWrap(isOpacity) }}>
        <ProductVariety data={data} fullWidth isThousandSeedsUnit={isThousandSeedsUnit} />
      </Box>

      <Box sx={styles.row(isThousandSeedsUnit)}>
        <Box sx={styles.opacityWrap(isOpacity)}>
          <CellHeader {...titles.amount} isInline={isThousandSeedsUnit} />
          <Box component="span" sx={styles.amountWeight}>
            {formatNumber(data.amount)}
          </Box>
        </Box>

        <Box sx={{ ...styles.quoteWrap, ...styles.opacityWrap(isOpacity) }}>
          <CellHeader {...titles.quotePrice} isInline={!isNotAvailable} />

          {isNotAvailable ? (
            <Typography sx={styles.statusLabel}>
              {quotePoductStatusLabel?.[quoteProductStatus as QuoteProductStatus]}
            </Typography>
          ) : (
            <TextFormField
              wrapStyles={styles.amountField as SxPropsTypes}
              inputStyles={styles.amountFieldInput as SxPropsTypes}
              control={control}
              name={`quotes.${index}.value`}
              placeholder="Amount"
              rules={validationRules.price}
              withoutHighlights
              disabled={isDisabled}
            />
          )}
        </Box>

        <Box sx={styles.subtotals}>
          <Box sx={styles.opacityWrap(isOpacity)}>
            <CellHeader {...titles.quoteSubtotals} />
          </Box>

          <Box sx={styles.price(!!totalPrice)}>
            {isRemoved || isUnavailable ? (
              <Box sx={styles.undoLabel} onClick={handleUndo}>
                Undo
              </Box>
            ) : (
              <>{totalPrice || (isRestricted ? '' : '-')}</>
            )}
          </Box>
        </Box>
      </Box>

      {!isRestricted && (
        <Box sx={styles.actionsWrap}>
          <SellerActionsMenu
            disabled={isDisabled}
            open={open}
            anchorEl={anchorEl}
            isRemoved={isRemoved}
            isUnavailable={isUnavailable}
            handleClick={handleClick}
            handleClose={handleClose}
            handleMarkNotAvailable={handleMarkNotAvailable}
            handleRemove={handleRemove}
          />
        </Box>
      )}
    </Box>
  );
};
