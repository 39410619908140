import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonButton, CommonModal, LoadingButton, TextFormField } from 'components';
import { useDeviceType } from 'hooks';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useFeedbackFormPopup } from './hooks';
import { bodyFieldRules } from './validation';
import { styles } from './styles';

interface FeedbackFormPopupProps {
  isOpen: boolean;
  handleCloseModal: () => void;
}

export const FeedbackFormPopup: FC<FeedbackFormPopupProps> = ({ isOpen, handleCloseModal }) => {
  const { isMobileSize } = useDeviceType();
  const { isLoading, control, handleSubmit, handleConfirmRequest, handleClose } = useFeedbackFormPopup({
    handleCloseModal,
  });

  return (
    <CommonModal
      isOpen={isOpen}
      title="Can't find what you need?"
      handleClose={handleClose}
      paperStyles={{ ...(isMobileSize ? styles.paperMobile : styles.paper) } as SxPropsTypes}
    >
      <Typography sx={styles.subtitle}>
        If you know what you need but can&apos;t find it yet, let us know. We&apos;ll reach out to our network to find
        any requested products.
      </Typography>

      <form onSubmit={handleSubmit(handleConfirmRequest)}>
        <TextFormField
          name="msgBody"
          placeholder="Needed products"
          multiline
          rows={5}
          inputWrapStyles={{ ...(styles.inputStyles as SxPropsTypes) }}
          control={control}
          rules={bodyFieldRules}
        />
        <Box sx={styles.actions}>
          <CommonButton variant="text" color="inherit" sx={styles.cancelButton} onClick={handleClose}>
            Cancel
          </CommonButton>
          <LoadingButton type="submit" loading={isLoading} variant="text" color="inherit" sx={styles.confirmButton}>
            Confirm
          </LoadingButton>
        </Box>
      </form>
    </CommonModal>
  );
};
