import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    display: 'grid',
    gap: '24px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  basicInfo: {
    padding: '16px 24px',
    borderRadius: '16px',
    width: '680px',
    height: '96px',
    backgroundColor: customTheme.custom.white,
    display: 'flex',
    position: 'fixed',
  },
  basicInfoCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  basicInfoSummary: {
    fontSize: '24px',
    fontWeight: 600,
    width: '340px',
  },
  basicInfoRow: {
    display: 'inline-flex',
    width: '340px',
    height: '24px',
    alignItems: 'center',
  },
  basicInfoLabel: {
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '4px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '24px',
      marginRight: '2px',
    },
  },
  fillIcon: {
    '& svg': {
      '& path': {
        fill: customTheme.custom.black.B1000,
      },
    },
  },
  basicInfoValue: {
    display: 'flex',
    gap: '2px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#717171',
  },
  iterationsContainer: {
    backgroundColor: customTheme.custom.white,
    borderRadius: '16px',
    marginTop: '140px',
  },
  pill: {
    padding: '2px 6px',
    borderRadius: '8px',
  },
  units: {
    backgroundColor: '#97DCEB',
    color: '#356C8B',
  },

  currency: {
    backgroundColor: '#97EBC8',
    color: '#358B67',
  },

  container: {
    padding: '0px',
    position: 'relative',
  },
  content: {
    marginBottom: '28px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
    },
  },
  confirmButton: {
    minWidth: '80px',
    color: customTheme.custom.success,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '288px',
    height: '60px',
    position: 'sticky',
    backgroundColor: 'F6FAFA',
    borderBottom: '2px solid #8A8A8A',
  },
  headerItem: {
    minWidth: '180px',
    maxWidth: '180px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:first-child': {
      paddingLeft: '32px',
    },
  },
  headerCrop: {
    fontSize: '12px',
    fontWeight: 500,
  },
  headerGroup: {
    display: 'inline-block',
    lineHeight: '12px',
  },
  headerVariety: {
    fontSize: '12px',
    fontWeight: 500,
  },
  headerName: {
    marginLeft: '2px',
    fontSize: '12px',
    fontWeight: 500,
  },
  rowsContainer: {
    maxHeight: '600px',
    overflowY: 'auto',
  },
  iteration: {
    display: 'flex',
    borderBottom: '1px solid #8A8A8A',
  },
  iterationActor: (isBuyer: boolean) => ({
    minWidth: '160px',
    maxWidth: '160px',
    backgroundColor: isBuyer ? '#1C97A6' : '#4BAB83',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 8px',
    boxSizing: 'border-box',
    color: customTheme.custom.white,
  }),
  rowlabels: (isBuyer: boolean) => ({
    minWidth: '128px',
    maxWidth: '128px',
    backgroundColor: isBuyer ? '#ACE9FF' : '#A9E8CE',
    padding: '24px 16px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 500,
  }),
  actorProposal: {
    fontSize: '12px',
    fontWeight: 500,
  },
  actorName: {
    fontSize: '14px',
    fontWeight: 500,
  },
  actorDate: {
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '8px',
  },
  iterationRows: {
    display: 'flex',
    height: '56px',
    borderBottom: '1px solid #8A8A8A',
  },
  iterationRow: {
    width: '130px',
    padding: '8px',
    boxSizing: 'border-box',
  },
  iterationRowItem: (isFirst: boolean) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '180px',
    ...(isFirst && { paddingLeft: '32px' }),
  }),
  iterationTotalHeader: {
    fontWeight: 500,
  },
  iterationTotals: {
    borderLeft: '1px solid #E7E7E7',
    width: '160px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
  },
  rowCurrencyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rowCurrency: (isAccepted: boolean) => ({
    fontSize: '14px',
    fontWeight: 400,
    color: isAccepted ? customTheme.custom.success : 'inherit',
  }),
  rowCurrencyAccepted: {
    fontSize: '10px',
    fontWeight: 400,
    color: customTheme.custom.success,
  },
};
