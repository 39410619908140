import { FC } from 'react';

import { Box } from '@mui/material';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';

import { useAuth } from 'hooks';
import { PageWithDashboardNavigation } from 'layout/DashboardNavigation';
import { MessagesPageInner } from './components';
import { styles } from './styles';

export const MessagesPage: FC = () => {
  const { isBuyer } = useAuth();

  return (
    <PageWithDashboardNavigation contentSx={styles.mainWrap}>
      <ChannelListProvider isMessageReceiptStatusEnabled={false}>
        <Box sx={styles.pageWrap(isBuyer)}>
          <MessagesPageInner />
        </Box>
      </ChannelListProvider>
    </PageWithDashboardNavigation>
  );
};
