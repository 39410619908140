import { FC, useCallback } from 'react';

import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import { useAuth } from 'hooks';
import { AnalyticsLinkType, LinkClickedEvenetProps, useLinkClickedEvenet } from 'rudderAnalytics';
import { headerNavigationItems } from './constants';
import { styles } from './styles';

interface NavigationContainerProps {
  onItemClick?: () => void;
}

export const NavigationContainer: FC<NavigationContainerProps> = ({ onItemClick }) => {
  const { isBuyer } = useAuth();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const navigationItems = headerNavigationItems(isBuyer);

  const handleItemClick = useCallback(
    ({ path, text }: Pick<LinkClickedEvenetProps, 'path' | 'text'>) => {
      onItemClick?.();
      handleTrackLinkClickEvent({ path, text, type: AnalyticsLinkType.NAV });
    },
    [handleTrackLinkClickEvent, onItemClick],
  );

  return (
    <Box component="nav" sx={{ ...styles.navWrap }}>
      {navigationItems.map(
        ({ text, path, hidden }) =>
          !hidden && (
            <Box key={path} sx={{ ...styles.navItem }}>
              <NavLink to={path} onClick={() => handleItemClick({ path, text })}>
                {text}
                <Box sx={{ ...styles.activeItem }} />
              </NavLink>
            </Box>
          ),
      )}
    </Box>
  );
};
