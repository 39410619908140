import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box } from '@mui/material';

import { Quote, UnitsType } from 'app/api/quotes';
import { ReactComponent as ChatIcon } from 'assets/icons/chatIcon.svg';
import { CommonButton, UnreadMessagesIndicator } from 'components';
import { CreateOrderWrapper } from 'pages/DashboardPage/components';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { QuoteTotal } from '../QuoteTotal';
import { styles } from './styles';

interface QuoteRequestActionsProps {
  control: Control<QuoteTableFormData>;
  isAllConfirmedOrNotAvailable: boolean;
  isAllProductsNotAvailable: boolean;
  isBuyer: boolean;
  isCanceledQuote: boolean;
  isChatOpen: boolean;
  isNewMessages: boolean;
  isWaiting: boolean;
  quoteData: Quote;
  totalAmount: string | number;
  totalPrice: string | number;
  unitsType: UnitsType;
  isSellerInputNeeded?: boolean;
  addProducts: () => void;
  handleOpenChat: () => void;
}

export const QuoteRequestActions: FC<QuoteRequestActionsProps> = ({
  control,
  isAllConfirmedOrNotAvailable,
  isAllProductsNotAvailable,
  isBuyer,
  isCanceledQuote,
  isChatOpen,
  isNewMessages,
  isWaiting,
  quoteData,
  totalAmount,
  totalPrice,
  unitsType,
  isSellerInputNeeded = false,
  addProducts,
  handleOpenChat,
}) => {
  return (
    <Box sx={styles.wrap}>
      <QuoteTotal
        isSellerInputNeeded={isSellerInputNeeded}
        totalAmount={totalAmount}
        totalPrice={totalPrice}
        unitsType={unitsType}
      />
      <Box sx={styles.buttons}>
        <CommonButton
          variant="outlined"
          sx={{ ...styles.buttonCommon, ...styles.chatButton }}
          onClick={(e) => {
            e.preventDefault();
            handleOpenChat();
          }}
        >
          <UnreadMessagesIndicator invisible={!isNewMessages} isTransparent={isChatOpen}>
            <ChatIcon />
          </UnreadMessagesIndicator>
          Chat
        </CommonButton>
        {isBuyer && (
          <CommonButton
            disabled={isWaiting || isCanceledQuote}
            color="secondary"
            sx={{ ...styles.buttonCommon, ...styles.addButton }}
            onClick={addProducts}
          >
            Add product
          </CommonButton>
        )}
        {isAllConfirmedOrNotAvailable && isBuyer ? (
          <CreateOrderWrapper
            data={quoteData?.quoteproducts || []}
            unitsType={unitsType}
            control={control}
            quoteId={quoteData.id}
            isDisabled={isCanceledQuote || isAllProductsNotAvailable}
            seller={quoteData?.seller}
            currentCycle={quoteData?.dealInteractionsCounter ?? 0}
            isSampleRequest={!!quoteData?.isSampleType}
          />
        ) : (
          <CommonButton color="primary" type="submit" sx={styles.buttonCommon} disabled={isCanceledQuote || isWaiting}>
            Send
          </CommonButton>
        )}
      </Box>
    </Box>
  );
};
