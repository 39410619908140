import { FC } from 'react';

import { useTrackPageViewedEvent } from 'rudderAnalytics';
import { AuthMainUi } from '../../components/AuthMainUi';
import { LoginForm } from './components/LoginForm';

export const LoginPage: FC = (): JSX.Element => {
  useTrackPageViewedEvent();

  return (
    <AuthMainUi>
      <LoginForm />
    </AuthMainUi>
  );
};
