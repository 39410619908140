import { FC, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { CommonButton, CommonModal, LoadingButton } from 'components';
import { useAuth } from 'hooks';
import { styles } from './styles';

interface LogOutModalProps {
  title: string;
  handleClose: () => void;
  isOpen: boolean;
}

export const LogOutModal: FC<LogOutModalProps> = ({ title, handleClose, isOpen = false }) => {
  const { logout, signOut } = useAuth();
  const { pathname } = useLocation();
  const pathnameRef = useRef<string>(pathname);

  const { isLoading } = signOut;

  useEffect(() => {
    if (pathnameRef.current !== pathname) {
      pathnameRef.current = pathname;
      handleClose();
    }
  }, [handleClose, pathname]);

  return (
    <CommonModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>Are you sure you want to Log Out?</Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.logoutButton }}
          onClick={logout}
        >
          Log Out
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
