import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { DashboardTabs, QuoteCardStatuses } from 'app/api/quotes/types';
import { CompanyDetail } from 'app/api/company';
import { QuoteStatusTag } from '../QuoteStatusTag';
import { QuoteCardProps } from './index';
import { QuoteProductsList } from './QuoteProductsList';
import { QuoteTotal } from './QuoteTotal';
import { QuoteCompany } from './QuoteCompany';
import { styles } from './styles';

interface QuoteCardContentProps
  extends Pick<QuoteCardProps, 'products' | 'totalAmount' | 'isNew' | 'status' | 'unitsType'> {
  company: Pick<CompanyDetail, 'id' | 'name' | 'address' | 'displayId'>;
  tab: DashboardTabs;
}

export const QuoteCardContent: FC<QuoteCardContentProps> = ({
  company,
  isNew,
  products,
  status,
  tab,
  totalAmount,
  unitsType,
}) => {
  return (
    <Box sx={{ ...styles.content }}>
      <Box sx={{ ...styles.header }}>
        <Typography component="h3" sx={{ ...styles.title }}>
          Seed Types
        </Typography>
        <Box sx={{ ...styles.tagsWrap }}>
          {!!status && <QuoteStatusTag status={status} />}
          {isNew && <QuoteStatusTag status={QuoteCardStatuses.NEW} />}
        </Box>
      </Box>

      <Box sx={styles.companyMobileWrap}>
        <QuoteCompany company={company} />
      </Box>

      <QuoteProductsList products={products} />

      <Box sx={styles.quoteTotalWrap}>
        <QuoteTotal unitsType={unitsType} tab={tab} totalAmount={totalAmount} />
      </Box>
    </Box>
  );
};
