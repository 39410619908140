import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { generatePath, NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import { Crop } from 'app/api/crops/types';
import { ROUTES } from 'app/routes/constants';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow.svg';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { capitalizeFirstLetter } from 'utils/formatText';
import { styles } from './styles';

interface CategoryItemProps extends Crop {
  isActive: boolean;
  isClickable?: boolean;
  isOpened?: boolean;
  handleMouseEnter?: () => void;
  handleClick?: () => void;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  name,
  isOpened,
  isActive,
  isClickable = true,
  handleMouseEnter,
  handleClick,
}) => {
  const path = generatePath(ROUTES.buyer.products, { type: name });
  const [allowEvent, setAllowEvent] = useState(false);

  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const handleNavClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      handleTrackLinkClickEvent({ path, text: capitalizeFirstLetter(name), type: AnalyticsLinkType.NAV });
    },
    [handleTrackLinkClickEvent, name, path],
  );

  useEffect(() => {
    if (!allowEvent) {
      setAllowEvent(true);
    }
  }, [allowEvent]);

  return (
    <Box
      sx={{
        ...styles.navItem,
        ...((isActive || isOpened) && styles.activeNavItem),
        ...(isOpened && styles.isOpenedNavItem),
        ...(isActive && isClickable && styles.currentNavItem),
      }}
      onMouseEnter={allowEvent && !handleClick ? handleMouseEnter : undefined}
      onClick={handleClick}
    >
      <NavLink to={path} onClick={handleNavClick}>
        {capitalizeFirstLetter(name)}
      </NavLink>
      <ArrowRightIcon />
    </Box>
  );
};
