import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  container: {
    padding: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '0px',
    },
  },
};
