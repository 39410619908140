import { ChangeEvent, FC } from 'react';

import { Control } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { QuoteProduct, UnitsType } from 'app/api/quotes/types';
import { CommonButton, CommonModal, LoadingButton, TextFormField } from 'components';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { validationRules } from 'pages/DashboardPage/utils/validationRules';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { ProductVariety } from '../ProductVariety';
import { styles } from './styles';

interface EditProductAmountPopupProps {
  isOpen: boolean;
  product: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  unitsType: UnitsType;
  handleClose: () => void;
  handleConfirmEditAmount: () => void;
  handleChangeAmount: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const EditProductAmountPopup: FC<EditProductAmountPopupProps> = ({
  isOpen,
  product,
  control,
  index,
  unitsType,
  handleClose,
  handleConfirmEditAmount,
  handleChangeAmount,
}) => {
  return (
    <CommonModal title="Edit amount" isOpen={isOpen} handleClose={handleClose}>
      <Box sx={{ ...styles.content }}>
        <ProductVariety fullWidth data={product} />
        <Typography sx={{ ...styles.subTitle }}>
          How much do you need ({getUnitDisplayValue(unitsType, true)})?
        </Typography>
        <TextFormField
          wrapStyles={{ ...styles.amountField } as SxPropsTypes}
          inputStyles={{ ...styles.amountFieldInput } as SxPropsTypes}
          control={control}
          name={`quotes.${index}.updatedAmount`}
          placeholder="Enter Amount"
          rules={validationRules.amount}
          withoutHighlights
          onChange={handleChangeAmount}
        />
      </Box>
      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <LoadingButton
          loading={false}
          variant="text"
          color="inherit"
          sx={{ ...styles.confirmButton }}
          onClick={handleConfirmEditAmount}
        >
          Confirm
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
