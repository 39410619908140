import { mobileLayoutMinWidth } from 'layout/constants/styleConstants';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: mobileLayoutMinWidth,
      marginBottom: '0px',
      paddingBottom: '16px',
      boxSizing: 'border-box',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '24px',
    },
  },
  actionsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',

      '.MuiTabs-root': {
        width: '100%',
      },
    },
  },
  tabsWrap: (isSampleRequests: boolean) => ({
    marginTop: '16px',
    marginBottom: '24px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    ...(isSampleRequests && {
      borderBottom: `1px solid ${customTheme.custom.black.B50}`,
      marginBottom: '12px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
      boxShadow: '0px 2px 4px 0 rgba(0, 0, 0, 0.12)',
      borderBottom: 'none',
      zIndex: 1,

      '.MuiTabs-flexContainer': {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gap: '10px',
        padding: '8px 14px 20px 14px',

        '.MuiTab-root:nth-child(1)': {
          gridColumn: '1 / 4',
          gridRow: '1 / 2',
        },
        '.MuiTab-root:nth-child(2)': {
          gridColumn: '4 / -1',
          gridRow: '1 / 2',
        },
        '.MuiTab-root:nth-child(3)': {
          gridColumn: '1 / 3',
          gridRow: '2 / -1',
        },
        '.MuiTab-root:nth-child(4)': {
          gridColumn: '3 / 5',
          gridRow: '2 / -1',
        },
        '.MuiTab-root:nth-child(5)': {
          gridColumn: '5 / -1',
          gridRow: '2 / -1',
        },
      },
    },
  }),
  tabWrap: {
    minHeight: '40px',
    minWidth: '150px',
    width: 'fit-content',
    padding: '10px 12px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    textTransform: 'inherit',
    color: customTheme.custom.black.B70,
    '&.Mui-selected': {
      borderRadius: '25px',
      color: customTheme.custom.white,
      background: customTheme.palette.secondary.S100,
      borderBottom: 'none',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '0 8px',
      height: '40px',
      whiteSpace: 'pre-wrap',
      width: '100%',
      minWidth: 'unset',
      background: customTheme.custom.white,
      boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.12)',
      lineHeight: '16px',

      '&.Mui-selected, &.MuiButtonBase-root.MuiTab-root': {
        alignItems: 'center',
        borderRadius: '10px',
      },
    },
  },
  innerTabsWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      width: '100%',
      boxShadow: '0px 2px 4px 0 rgba(0, 0, 0, 0.12)',
      zIndex: 2,

      background: customTheme.custom.white,
    },
  },
};
