import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CompanyDetail } from 'app/api/company/types';
import { Product } from 'app/api/products/types';
import { PageLoader } from 'components';
import { UnitsType } from 'app/api/quotes/types';
import { EmptyQuotesState } from '../EmptyQuotesState';
import { ChoosedProducts } from '../ChoosedProducts';
import { CompanyProductsTable } from '../CompanyProductsTable';
import { UnitDropdown } from '../UnitDropdown';
import { useQuoteRequestFormWrapper } from './hooks';
import { styles } from './styles';

export interface QuoteRequestFormWrapperProps {
  data: Product[];
  company: CompanyDetail | undefined;
  defaultUnit: UnitsType;
  isAddPropductsPage: boolean;
  isRequestSamplePage?: boolean;
}

export const QuoteRequestFormWrapper: FC<QuoteRequestFormWrapperProps> = ({
  data,
  company,
  defaultUnit,
  isAddPropductsPage,
  isRequestSamplePage = false,
}): JSX.Element => {
  const {
    watch,
    control,
    isDirty,
    dirtyFields,
    choosedProducts,
    choosedProductsKeys,
    productId,
    unitsType,
    handleSubmit,
    setChoosedProducts,
    removeChoosedProducts,
    allowToNavigate,
  } = useQuoteRequestFormWrapper({ data, defaultUnit, companyDisplayId: company?.displayId });

  if (!isDirty && productId && !choosedProducts.length) {
    return (
      <Box sx={styles.loaderWrap} data-testid="loader">
        <PageLoader />
      </Box>
    );
  }

  return (
    <form>
      <Box sx={{ ...styles.headerWrap }}>
        <Typography sx={{ ...styles.title(isRequestSamplePage) }}>
          Request {isRequestSamplePage ? 'Sample' : 'Quote'}
        </Typography>
        <Box sx={{ ...styles.dropdownWrap }}>
          <Typography component="span" sx={{ ...styles.label }}>
            Unit
          </Typography>
          <UnitDropdown control={control} disabled={isAddPropductsPage} />
        </Box>
      </Box>

      {choosedProducts.length ? (
        <ChoosedProducts
          watch={watch}
          control={control}
          choosedProducts={choosedProducts}
          handleSubmit={handleSubmit}
          removeChoosedProducts={removeChoosedProducts}
          isFormDirty={isDirty || !!dirtyFields?.quotes}
          allowToNavigate={allowToNavigate}
          seller={company}
          unitsType={unitsType}
          isSampleRequest={isRequestSamplePage}
        />
      ) : (
        <EmptyQuotesState unitsType={unitsType} isRequestSample={isRequestSamplePage} />
      )}

      {data.length && (
        <Box data-testid="company-products">
          <CompanyProductsTable
            data={data || []}
            company={company}
            choosedProductsKeys={choosedProductsKeys}
            setChoosedProducts={setChoosedProducts}
            isSampleRequest={isRequestSamplePage}
          />
        </Box>
      )}
    </form>
  );
};
