import * as rudderanalytics from 'rudder-sdk-js';

const DEV_HOSTNAME = 'app-dev.sproutzo.com';
const TEST_HOSTNAME = 'app-test.sproutzo.com';
const PROD_HOSTNAME = 'app.sproutzo.com';
const LOCAL_HOSTNAME = 'localhost';

const DATAPLANE_URL = 'https://southphstqmblh.dataplane.rudderstack.com';

const DEV_WRITE_KEY = '2WIp6WNC4VColQpIf78haQnhuLY';
const TEST_WRITE_KEY = '2WIp9APvxXCuqWwhahluSXmgtvh';
const PROD_WRITE_KEY = '2WIpAh9FI11uZXX6DYeRSwPvwdU';

function getWriteKey(host: string): string {
  switch (host) {
    case TEST_HOSTNAME:
      return TEST_WRITE_KEY;

    case PROD_HOSTNAME:
      return PROD_WRITE_KEY;

    default:
      return DEV_WRITE_KEY;
  }
}

export function rudderInitialize() {
  const { hostname } = window.location;
  const isDev = hostname === DEV_HOSTNAME;
  const isLocalhost = hostname === LOCAL_HOSTNAME;

  if (!isLocalhost)
    rudderanalytics.load(getWriteKey(hostname), DATAPLANE_URL, {
      ...(isDev && { logLevel: 'DEBUG' }),
    });
}
