import { FC } from 'react';

import { Typography } from '@mui/material';

import { styles } from './styles';

interface ContactUsPointItemProps {
  title: string;
  text: string;
}

export const ContactUsPointItem: FC<ContactUsPointItemProps> = ({ title, text }): JSX.Element => (
  <>
    <Typography sx={{ ...styles.title }}>{title}</Typography>

    <Typography sx={{ ...styles.text }}>{text}</Typography>
  </>
);
