import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { TextFormField } from 'components';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonPopup';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

interface SellerRemoveProductPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  handleRemove: () => void;
  handleUpdateQuantity: (quantity: number) => void;
}

const messages = {
  title: 'Out of stock?',
  description: 'If the product is in stock in lesser quantity, you can choose to offer the buyer a different quantity.',
  offerDifferentQuantity: 'Offer different quantity',
  submitDifferentQuantity: 'Submit different quantity',
  outOfStock: 'Out of stock',
  differentQuantity: 'Different quantity:',
};

export const SellerRemoveProductPopup: FC<SellerRemoveProductPopupProps> = ({
  handleClose,
  handleRemove,
  handleUpdateQuantity,
  isOpen = false,
}) => {
  const [isDifferentQuantity, setIsDifferentQuantity] = useState(false);
  const { control, watch } = useForm<{ quantity: undefined | number }>({
    defaultValues: {
      quantity: undefined,
    },
  });

  const quantity = watch('quantity');

  const handleClickDifferentQuantity = () => {
    if (isDifferentQuantity) {
      if (quantity) {
        handleUpdateQuantity(quantity);
        setIsDifferentQuantity(false);
      }
    } else {
      setIsDifferentQuantity(true);
    }
  };

  const onClickRemove = () => {
    setIsDifferentQuantity(false);
    handleRemove();
  };
  return (
    <CommonModal title={messages.title} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        Are you sure you want to remove the selected product from the Quote?
      </Typography>

      {isDifferentQuantity && (
        <Box sx={{ ...styles.differentQuantity }}>
          <Typography sx={{}}>{messages.differentQuantity}</Typography>
          <TextFormField
            wrapStyles={{ ...styles.quantityField } as SxPropsTypes}
            control={control}
            name="quantity"
            placeholder="Enter Amount"
            withoutHighlights
            disabled={false}
          />
        </Box>
      )}

      <Box sx={{ ...styles.actions }}>
        <CommonButton
          variant="text"
          color="primary"
          sx={{ ...styles.closeButton }}
          onClick={handleClickDifferentQuantity}
        >
          {isDifferentQuantity ? messages.submitDifferentQuantity : messages.offerDifferentQuantity}
        </CommonButton>
        <CommonButton variant="text" color="error" sx={{ ...styles.closeButton }} onClick={onClickRemove}>
          {messages.outOfStock}
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
