import { Typography } from '@mui/material';
import { FC } from 'react';

import { styles } from './styles';

interface AboutUsPointItemProps {
  title: string;
  text: string;
}

export const AboutUsPointItem: FC<AboutUsPointItemProps> = ({ title, text }): JSX.Element => (
  <>
    <Typography sx={{ ...styles.title }}>{title}</Typography>

    <Typography sx={{ ...styles.text }}>{text}</Typography>
  </>
);
