import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box, Menu, MenuItem } from '@mui/material';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { CommonButton, CommonTooltip } from 'components';
import { QuoteFormData } from '../../hooks/types';
import { useUnitDropdown } from './hooks';
import { styles } from './styles';

export interface UnitDropdownProps {
  control: Control<QuoteFormData>;
  disabled: boolean;
}

export const UnitDropdown: FC<UnitDropdownProps> = ({ control, disabled }) => {
  const { options, anchorEl, open, dropdownTitle, handleOptionClick, handleClick, handleClose } = useUnitDropdown({
    control,
  });

  return (
    <>
      <CommonTooltip title="Units can only be changed when requesting a New Quote." disableHoverListener={!disabled}>
        <span>
          <CommonButton
            sx={{
              ...styles.button,
              ...(open && styles.menuButtonOpened),
            }}
            onClick={handleClick}
            disableRipple
            disabled={disabled}
            data-testid="unit-dropdown"
          >
            {dropdownTitle}
            <Box
              sx={{
                ...styles.menuButtonIcon,
                ...(open && styles.menuButtonIconopened),
              }}
            >
              <ArrowDownIcon />
            </Box>
          </CommonButton>
        </span>
      </CommonTooltip>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 10,
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} onClick={() => handleOptionClick(value)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
