import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  title: {
    margin: '24px 0 12px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  text: {
    maxWidth: '272px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  divider: {
    margin: '24px 0',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '12px 0',
    },
  },
  bottomSection: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: '16px',
    },
  },
  button: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
};
