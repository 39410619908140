import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    display: 'flex',
    jystifyContent: 'center',
    alignItems: 'center',
    padding: '0px 1000px',
    margin: '0px -1000px 18px',
    height: '65px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '8px 14px',
      margin: '0px -14px 8px',
      height: 'inherit',
    },

    svg: {
      fill: '#71717A',
      height: '17px',
      width: '17px',
    },
  },
  header: {
    color: '#71717A',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.2',
    marginRight: '24px',
    minWidth: '100px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'inherit',
      fontSize: '16px',
      marginRight: '16px',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    height: '43px',
    flexWrap: 'wrap',
    overflow: 'hidden',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: 2,
      padding: 0,
    },
  },
  logo: (withRadius: boolean) => ({
    maxWidth: '88px',
    maxHeight: '43px',
    height: '100%',
    objectFit: 'contain',
    ...(withRadius && { borderRadius: '3px' }),
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'auto',
      maxWidth: '48px',
      maxHeight: '40px',
    },
  }),
};
