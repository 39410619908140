import { FC } from 'react';

import { Box } from '@mui/material';

import { CommonButton, LoadingButton } from 'components';
import { styles } from './styles';

interface CompanyActionsButtonsProps {
  isCreateChannelLoading: boolean;
  isRequestButtons: boolean;
  onRequestQuoteClick?: () => void;
  onRequestSampleClick?: () => void;
  onSendMessageClick?: () => void;
}

export const CompanyActionsButtons: FC<CompanyActionsButtonsProps> = ({
  isCreateChannelLoading,
  isRequestButtons,
  onRequestQuoteClick,
  onRequestSampleClick,
  onSendMessageClick,
}) => {
  return (
    <Box sx={styles.actionsWrap}>
      <LoadingButton loading={isCreateChannelLoading} sx={styles.messageButton} onClick={onSendMessageClick}>
        Send Message
      </LoadingButton>
      {isRequestButtons && (
        <>
          <CommonButton sx={styles.quoteButton} onClick={onRequestQuoteClick}>
            Request Quote
          </CommonButton>
          <CommonButton variant="outlined" sx={styles.sampleButton} onClick={onRequestSampleClick}>
            Request Sample
          </CommonButton>
        </>
      )}
    </Box>
  );
};
