import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isSliderCard: boolean, isHorizontalOrientation: boolean, isRestricted: boolean, size: 'small' | 'large') => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    padding: '12px 12px 10px 12px',
    borderRadius: '10px',
    background: customTheme.custom.white,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    ...(!isRestricted && {
      ':hover': {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
      },
    }),
    ...(size === 'large' && {
      height: '404px',
    }),

    ...(isRestricted && {
      cursor: 'not-allowed',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      borderRadius: '10px',
      padding: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      height: 'inherit',

      ...(isHorizontalOrientation && {
        flexDirection: 'row',
        gap: '10px',
      }),
    },
  }),
  image: (isVerticalImage: boolean, isHorizontal: boolean) => ({
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '10px',
    aspectRatio: '1 / 1',
    position: 'relative',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      ...(isVerticalImage && {
        aspectRatio: '140 / 177',
      }),
      ...(isHorizontal && {
        width: '124px',
      }),
    },
  }),
  imageFilter: (isVerticalImage: boolean, isHorizontal: boolean) => ({
    width: 'calc(100% - 24px)',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '10px',
    aspectRatio: '1 / 1',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'calc(100% - 16px)',
      ...(isVerticalImage && {
        aspectRatio: '140 / 177',
      }),
      ...(isHorizontal && {
        width: '124px',
      }),
    },
    position: 'absolute',
    background: customTheme.custom.gray.G100,
    opacity: 0.5,
  }),

  container: {
    position: 'relative',
  },
  organicTag: (isHorizontal: boolean, inStock: boolean) => ({
    display: 'inline-flex',
    padding: '1px 2px 3px 7px',
    justifyContent: 'flex-start',
    alignItems: 'center',

    position: 'absolute',
    backgroundColor: customTheme.custom.organic,
    fontWeight: 600,
    color: customTheme.custom.white,
    right: '12px',
    top: '27px',
    ...(!inStock && { top: '72px' }),
    width: '59px',
    fontSize: '12px',

    textShadow: '0px 1px 8px rgba(0, 0, 0, 0.1)',

    borderRadius: '8px 0px 0px 8px',
    background: customTheme.custom.organic,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.2)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      right: '8px',
      ...(isHorizontal && {
        left: '71px',
        width: '52px',
        right: 'auto',
      }),
    },
  }),
  outOfStock: (isHorizontal: boolean) => ({
    display: 'inline-flex',
    padding: '4px 8px',
    justifyContent: 'flex-start',
    alignItems: 'center',

    position: 'absolute',
    fontWeight: 600,
    color: customTheme.custom.black,
    right: '12px',
    top: '27px',
    width: '100px',
    fontSize: '14px',

    borderRadius: '8px 0px 0px 8px',
    background: customTheme.custom.gray.G100,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.2)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      right: '8px',
      ...(isHorizontal && {
        left: '71px',
        width: '52px',
        right: 'auto',
      }),
    },
  }),
  crop: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.palette.primary.P100,
  },
  content: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1',
  },
  header: {
    minHeight: '34px',
    marginBottom: '6px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,
    },
  },
  variety: (isHorizontal: boolean) => ({
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    color: customTheme.custom.black.B100,
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    paddingBottom: '2px',

    overflow: 'hidden',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: 0,

      ...(isHorizontal && {
        fontSize: '14px',
      }),
    },
  }),
  name: {
    display: 'inline',
    color: customTheme.custom.black.B50,
    wordBreak: 'keep-all',
  },
  tagsWrap: (isHorizontal: boolean) => ({
    marginBottom: '8px',
    display: 'flex',
    gap: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,

      ...(isHorizontal && {
        marginBottom: '12px',
      }),
    },
  }),
  type: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    borderRadius: '3px',
    color: customTheme.palette.secondary.S100,
    backgroundColor: customTheme.palette.secondary.S20,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '4px',
      fontSize: '8px',
      lineHeight: '10px',
    },
  },
  stock: {
    padding: '6px',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.info,
    backgroundColor: customTheme.custom.blue.B50,
    borderRadius: '3px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '4px',
      fontSize: '8px',
      lineHeight: '10px',
    },
  },
  stockTitle: {
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.info,
    backgroundColor: customTheme.custom.blue.B50,
    marginRight: '4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '8px',
      lineHeight: '10px',
    },
  },
  quantity: {
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.info,
    backgroundColor: customTheme.custom.blue.B50,
    borderRadius: '3px',
    fontWeight: 400,
    padding: 0,
    display: 'inline-block',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      fontSize: '8px',
      lineHeight: '10px',
    },
  },
  footer: (isHidden: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    gap: '10px',
    height: '24px',
    paddingBottom: '2px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      ...(isHidden && {
        display: 'none',
      }),
      gap: '6px',
      height: '26px',
    },
  }),
  company: (isHorizontal: boolean) => ({
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B50,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '7px',
      lineHeight: '8px',
      WebkitLineClamp: '3',

      ...(isHorizontal && {
        fontSize: '9px',
        lineHeight: '11px',
      }),
    },
  }),
  country: {
    fontWeight: 600,
    color: customTheme.custom.black.B50,
    marginLeft: '3px',
    display: 'inline',
  },
  logo: (isSmall: boolean, isHorizontal: boolean) => ({
    maxWidth: '70px',
    maxHeight: isSmall ? '34px' : '46px',
    position: 'relative',
    bottom: '-4px',
    right: 0,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxHeight: isSmall ? '28px' : '46px',
      maxWidth: '50px',

      ...(isHorizontal && {
        maxWidth: '70px',
        maxHeight: '36px',
      }),
    },
  }),
  draftLabel: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    padding: '6px',
    borderRadius: '3px',
    background: '#E8E8E8',
    color: '#5E6062',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: '0.2px',
  },
};
