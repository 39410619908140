import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  mainWrap: {
    padding: '0px',
    '.sendbird-channel-list': {
      width: '100%',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '100%',

      '.sendbird-channel-list': {
        minHeight: '100%',
      },
    },
  },
  pageWrap: (isBuyer: boolean) => ({
    position: 'relative',
    height: 'calc(100vh - 150px)',
    display: 'flex',
    overflow: 'hidden',
    background: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'calc(100% + 28px)',
      margin: '-14px -14px 0 -14px',
      display: 'grid',
      maxHeight: `calc(100svh - ${isBuyer ? '144px' : '104px'})`,
      height: 'fit-content',
      overflowY: 'auto',
      ...scrollMixin,
    },
  }),
};
