import { ReactElement } from 'react';

import cornUrl from 'assets/images/corn.png';
import sackUrl from 'assets/images/sack.png';
import watermelonUrl from 'assets/images/watermelon.png';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';

const messages = {
  main: 'Simplifying Global Agriculture Trade',
  sub: 'Effortlessly discover, quote, and procure from companies across the globe.',
};

export const HomePageBanner = (): ReactElement => {
  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ ...styles.innerContainer }}>
        <Typography sx={{ ...styles.contentTitle }}>{messages.main}</Typography>
        <Typography sx={{ ...styles.contentText }}>{messages.sub}</Typography>
        {/* three images */}
        <Box sx={{ ...styles.bannerImgs }}>
          <Box sx={{ ...styles.imgContainer, ...styles.sack }}>
            <Box component="img" src={sackUrl} alt="Banner sack" sx={styles.bannerImg} width={180} />
          </Box>
          <Box sx={{ ...styles.imgContainer, ...styles.watermelon }}>
            <Box component="img" src={watermelonUrl} alt="Banner watermelon" sx={styles.bannerImg} width={180} />
          </Box>
          <Box sx={{ ...styles.imgContainer, ...styles.corn }}>
            <Box component="img" src={cornUrl} alt="Banner corn" sx={{ ...styles.bannerImg }} width={130} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
