import { useEffect } from 'react';

import { useNavigate, useLocation, matchPath, useParams } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { useAuth } from 'hooks';
import { DashboardTab } from '../types';
import { dasboardTabs, sampleRequestsTabs } from '../constants';

interface DashboardTabsReturn {
  isBuyer: boolean;
  activeTab: DashboardTab | undefined;
  isSampleRequestsTab: boolean;
}

export const useDashboardTabs = (): DashboardTabsReturn => {
  const { isBuyer } = useAuth();
  const { pathname } = useLocation();
  const { tab } = useParams();
  const navigate = useNavigate();

  // const queryClient = useQueryClient();
  // const { handleTrackLinkClickEvent } = useLinkClickedEvenet();
  //     // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //     queryClient.invalidateQueries({ queryKey: ['orders-counter'] });

  const role = isBuyer ? 'buyer' : 'seller';
  const mainDashboardRoute = ROUTES[role].dashboard._;
  const isSampleRequestsTab = !!matchPath({ path: ROUTES.dashboard.samples.tab }, pathname);

  const validTabs = isSampleRequestsTab ? sampleRequestsTabs : dasboardTabs;
  const activeTab = validTabs.find((view) => tab === view.path);

  useEffect(() => {
    if (!activeTab) {
      navigate(mainDashboardRoute);
    }
  }, [activeTab, mainDashboardRoute, navigate]);

  return {
    isBuyer,
    activeTab,
    isSampleRequestsTab,
  };
};
