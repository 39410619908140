import { FC, Fragment } from 'react';

import { Box } from '@mui/material';

import { CompanyChatModal, PageLoader } from 'components';
import { useCompanyChatHandlers } from 'hooks';
import { SCROLLABLE_LIST_ID } from 'components/ProductsList/components/InfinityListWrapper';
import { Footer } from 'layout/Footer';
import { PageWithDashboardNavigation } from 'layout/DashboardNavigation';
import { Description, PageHeader, TeamList, CompanyProducts, MobileCompanyActionsButtons } from './components';
import { useCompanyDetailsPageHanlers } from './hooks';
import { styles } from './styles';

export const CompanyDetailsPage: FC = () => {
  const {
    channelUrl,
    isBuyer,
    isCreateChannelLoading,
    isChatModalOpen,
    isLoading,
    data,
    products,
    isTeamList,
    isProductsList,
    isProductsNextPageLoading,
    hasProductsNextPage,
    hideAllButtons,
    handleCloseChatModal,
    onLoadMore,
    onRequestQuoteClick,
    onRequestSampleClick,
    onSendMessageClick,
    onProductCardClick,
  } = useCompanyDetailsPageHanlers();

  const {
    control,
    isLoading: isSendMessageLoading,
    isMessageData,
    handleConfirm,
    handleSubmit,
  } = useCompanyChatHandlers({ channel: channelUrl });

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  const ParentContainer = isBuyer ? Fragment : PageWithDashboardNavigation;

  return (
    <ParentContainer contentSx={styles.container}>
      <Box id={SCROLLABLE_LIST_ID} sx={styles.pageWrap(isBuyer)}>
        {data ? (
          <>
            <PageHeader logo={data.logo} name={data.name} address={data.address} categories={data.meta?.categories} />
            <Description
              description={data.description}
              image={data.image}
              minOrder={data.smallestShipment}
              hideAllButtons={hideAllButtons}
              isCreateChannelLoading={!!isCreateChannelLoading}
              isProductsList={!!products.length}
              onRequestQuoteClick={onRequestQuoteClick}
              onRequestSampleClick={onRequestSampleClick}
              onSendMessageClick={onSendMessageClick}
            />
            {isTeamList && <TeamList data={data.teamMembers} />}
            {isProductsList && (
              <CompanyProducts
                data={products}
                hasNextPage={hasProductsNextPage}
                isLoading={isProductsNextPageLoading}
                onLoadMore={onLoadMore}
                openCard={onProductCardClick}
              />
            )}
            <Box sx={styles.footerWrap(!hideAllButtons)}>
              <Footer />
            </Box>
            {!hideAllButtons && (
              <MobileCompanyActionsButtons
                isCreateChannelLoading={!!isCreateChannelLoading}
                isRequestButtons={isProductsList}
                onRequestQuoteClick={onRequestQuoteClick}
                onRequestSampleClick={onRequestSampleClick}
                onSendMessageClick={onSendMessageClick}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
      <CompanyChatModal
        channelUrl={channelUrl}
        control={control}
        disableSendButton={!isMessageData}
        isOpen={isChatModalOpen}
        isSendMessageLoading={isSendMessageLoading}
        otherCompanyName={data?.name ?? ''}
        handleClose={handleCloseChatModal}
        handleSendMessage={handleSubmit(handleConfirm)}
      />
    </ParentContainer>
  );
};
