import * as rudderanalytics from 'rudder-sdk-js';

import { CompanyDetail } from 'app/api/company';
import { QuoteProduct } from 'app/api/quotes';
import { AdditionalQuoteProductFormProps } from 'pages/DashboardPage/types';
import { formatNestedProductAnalyticsData } from './formatNestedProductAnalyticsData';

interface GetQuoteRespondedToEventProductsProps {
  products: (QuoteProduct & AdditionalQuoteProductFormProps)[];
  seller: CompanyDetail | undefined;
}

export const getQuoteRespondedToEventProducts = ({
  products,
  seller,
}: GetQuoteRespondedToEventProductsProps): rudderanalytics.apiObject[] => {
  const results = products.map(
    ({ id, price, updateAcceptedPrice, updatedAmount, acceptedPrice, value, amount, status, product }) => ({
      quote_product_id: updatedAmount ? null : id,
      supplier_name: seller?.name ?? '',
      supplier_id: seller?.id ?? '',
      continent: seller?.address?.continent ?? '',
      price_offered: price || '',
      price_accepted: !!updateAcceptedPrice || !!acceptedPrice,
      price_counter_offered: value ? Number(value) : '',
      amount,
      status,
      ...formatNestedProductAnalyticsData(product),
    }),
  ) as unknown as rudderanalytics.apiObject[];

  return results;
};
