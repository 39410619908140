import { ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import { generatePath } from 'react-router-dom';

import flower from 'assets/images/flower.png';
import ornamental from 'assets/images/ornamental.png';
import oilseed from 'assets/images/oilseed.png';
import carrot from 'assets/images/carrotSlanted.png';
import herb from 'assets/images/herb.png';
import forage from 'assets/images/forage.png';
import grass from 'assets/images/grass.png';
import grain from 'assets/images/grain.png';
import fruit from 'assets/images/fruit.png';

import { useCropFamiliesQuery } from 'hooks';
import { Link } from 'components/Link';
import { CustomArrowButton } from 'components/ProductsSlider/CustomArrowButton';
import { ROUTES } from 'app/routes/constants';
import { styles } from './styles';

const messages = {
  main: 'Browse by category',
  sub: 'Request samples, pricing and quality certificates.',
};

const getPath = (type: string) => generatePath(ROUTES.buyer.products, { type });

const categories = [
  { name: 'Vegetable', logo: carrot, path: getPath('Vegetable') },
  { name: 'Fruit', logo: fruit, path: getPath('Fruit') },
  { name: 'Grain', logo: grain, path: getPath('Grain') },
  { name: 'Forage', logo: forage, path: getPath('Forage') },
  { name: 'Oilseed', logo: oilseed, path: getPath('Oilseed') },
  { name: 'Herb', logo: herb, path: getPath('Herb') },
  { name: 'Grass', logo: grass, path: getPath('Grass') },
  { name: 'Flower', logo: flower, path: getPath('Flower') },
  { name: 'Ornamental', logo: ornamental, path: getPath('Ornamental') },
];

const settings: Settings = {
  infinite: false,
  slidesToScroll: 9,
  slidesToShow: 9,
  nextArrow: <CustomArrowButton direction="next" />,
  prevArrow: <CustomArrowButton direction="prev" />,
  variableWidth: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1680,
      settings: { slidesToShow: 8 },
    },
    {
      breakpoint: 1574,
      settings: { slidesToShow: 7, slidesToScroll: 7 },
    },
    {
      breakpoint: 1417,
      settings: { slidesToShow: 6, slidesToScroll: 6 },
    },
    {
      breakpoint: 1245,
      settings: { slidesToShow: 5, slidesToScroll: 5 },
    },
    {
      breakpoint: 1072,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 904,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 512,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
  ],
};

export const Categories = (): ReactElement => {
  const { cropFamiliesData, isCropFamiliesFetching, isCropFamiliesLoading } = useCropFamiliesQuery({
    enabled: false,
  });

  const validCategories = cropFamiliesData.reduce(
    (categories, cropCategory) => categories.add(cropCategory.name),
    new Set(),
  );

  const displayCategories =
    isCropFamiliesFetching || isCropFamiliesLoading ? [] : categories.filter((c) => validCategories.has(c.name));

  return (
    <Box component="section" sx={{ ...styles.container }}>
      <Typography sx={styles.header}>{messages.main}</Typography>
      <Typography sx={styles.subheader}>{messages.sub}</Typography>
      <Box>
        <Slider {...settings}>
          {displayCategories.map((category) => (
            <Link key={category.name} path={category.path} text={category.name}>
              <Box key={category.name} sx={styles.categoryInnerContainer}>
                <Typography sx={styles.name}>{category.name}</Typography>
                <Box component="img" src={category.logo} alt={category.name} sx={styles.logo} />
              </Box>
            </Link>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};
