import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    margin: 0,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    '::before': {
      display: 'none',
    },
    '&.MuiAccordion-root, & .Mui-expanded': {
      margin: 0,
    },
  },
  summary: {
    width: 'fit-content',
    padding: '4px 8px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: customTheme.custom.black.B100,
    backgroundColor: customTheme.custom.black.B10,
    borderRadius: '8px',
    '&.MuiAccordionSummary-gutters': {
      minHeight: '24px',
    },
    '& .MuiAccordionSummary-content, & .Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'inherit',
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      line: '22px',
      marginBottom: '16px',
    },
  },
  details: {
    padding: '18px 0',
  },
};
