import { FC } from 'react';

import { Box } from '@mui/material';

import { DashboardTabs } from 'app/api/quotes/types';
import { useDeviceType } from 'hooks';
import { CommonButton } from '../CommonButton';
import { QuoteCardProps } from './index';
import { QuoteDates } from './QuoteDates';
import { QuoteCompany } from './QuoteCompany';
import { styles } from './styles';

interface QuoteCardMetaProps
  extends Pick<QuoteCardProps, 'company' | 'isHighlighted' | 'createdAt' | 'estimatedDeliveryDate' | 'deliveredAt'> {
  tab?: DashboardTabs;
  onClick: () => void;
  orderNumber?: string;
}

export const QuoteCardMeta: FC<QuoteCardMetaProps> = ({
  company,
  isHighlighted,
  createdAt = '',
  estimatedDeliveryDate = '',
  deliveredAt = '',
  tab,
  orderNumber,
  onClick,
}) => {
  const { isMobileSize } = useDeviceType();

  return (
    <Box sx={{ ...styles.meta }}>
      <Box sx={{ ...styles.companyWrap }}>
        <QuoteCompany company={company} />
      </Box>
      <Box sx={{ ...styles.metaFooter }}>
        <QuoteDates
          createdAt={createdAt}
          deliveredAt={deliveredAt}
          estimatedDeliveryDate={estimatedDeliveryDate}
          tab={tab}
          orderNumber={orderNumber}
        />
        {!isMobileSize && (
          <CommonButton variant={isHighlighted ? 'contained' : 'outlined'} sx={styles.viewButton} onClick={onClick}>
            View Details
          </CommonButton>
        )}
      </Box>
    </Box>
  );
};
