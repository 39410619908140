import { FC, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ProductDetail } from 'app/api/products/types';
import { KeyStatItem } from '../KeyStatItem';
import { ProductsActionsButtons } from '../ProductsActionsButtons';
import { styles } from './styles';

interface KeyStatsCardProps extends Pick<ProductDetail, 'image'> {
  hideButton: boolean;
  isCreateChannelLoading: boolean;
  inStock: boolean;
  stats: { name: string; value: string }[];
  onRequestQuoteClick: () => void;
  onRequestSampleClick: () => void;
  onSendMessageClick: () => void;
}

export const KeyStatsCard: FC<KeyStatsCardProps> = ({
  image,
  inStock,
  hideButton,
  isCreateChannelLoading,
  stats,
  onRequestQuoteClick,
  onRequestSampleClick,
  onSendMessageClick,
}) => {
  const [isImage, setIsImage] = useState(!!image);

  const handleImageError = useCallback(() => {
    setIsImage(false);
  }, []);

  return (
    <Box sx={{ ...styles.wrap }}>
      {isImage && <Box component="img" src={image || ''} sx={{ ...styles.image }} onError={handleImageError} />}
      <Box sx={{ ...styles.inner(isImage) }}>
        <Box sx={styles.keyStatsWrap(!hideButton)}>
          <Typography sx={{ ...styles.title }}>Key Tech Stats</Typography>
          <Box sx={{ ...styles.list(!hideButton) }}>
            {stats.map((stat) => (
              <KeyStatItem key={stat.name} {...stat} />
            ))}
          </Box>
        </Box>
        {!hideButton && (
          <ProductsActionsButtons
            inStock={inStock}
            isCreateChannelLoading={isCreateChannelLoading}
            isSampleButtonBorder={!isImage}
            onRequestQuoteClick={onRequestQuoteClick}
            onRequestSampleClick={onRequestSampleClick}
            onSendMessageClick={onSendMessageClick}
          />
        )}
      </Box>
    </Box>
  );
};
