import { FC } from 'react';

import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useQuoteRequestMessagesHandlers } from '../../hooks';
import { QuoteRequestDetailsForm } from '../QuoteRequestDetailsForm';
import { MultiplePopups } from '../MultiplePopups';
import { QuoteChatModal } from '../QuoteChatModal';
import { useQuoteRequestDetails } from './hooks';
import { styles } from './styles';

export interface QuoteRequestDetailsProps {
  title: string;
  subTitle: string;
  isOpen: boolean;
  quoteId: number;
  isSampleRequest: boolean;
  handleClose: () => void;
}

export const QuoteRequestDetails: FC<QuoteRequestDetailsProps> = ({
  title,
  subTitle,
  quoteId,
  isSampleRequest,
  isOpen = false,
  handleClose,
}) => {
  const { isLoading, quoteData, closeDetails } = useQuoteRequestDetails(quoteId, handleClose);

  const { message, files, handleMessageChange, handleFilesChange } = useQuoteRequestMessagesHandlers();

  return (
    <MultiplePopups
      title={title}
      isOpen={isOpen}
      isChatOpen={!isLoading}
      isSampleRequest={isSampleRequest}
      handleClose={closeDetails}
      paperStyles={styles.paperStyles(true) as SxPropsTypes}
      // eslint-disable-next-line react/no-unstable-nested-components
      chat={() => (
        <QuoteChatModal
          otherCompanyName={title}
          quoteId={quoteId}
          channelUrl={isOpen ? quoteData?.chatId ?? '' : ''}
          isSampleRequest={!!quoteData?.isSampleType}
          seller={quoteData?.seller}
          defaultValues={{ message, files }}
          handleMessageChange={handleMessageChange}
          handleFilesChange={handleFilesChange}
        />
      )}
    >
      {isLoading ? (
        <Box sx={{ ...styles.loaderBox }}>
          <PageLoader />
        </Box>
      ) : (
        <QuoteRequestDetailsForm
          isChatOpen
          quoteData={quoteData}
          quoteId={quoteId}
          subTitle={subTitle}
          closeDetails={closeDetails}
        />
      )}
    </MultiplePopups>
  );
};
