import { useCallback, useMemo } from 'react';

import { Control, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as rudderanalytics from 'rudder-sdk-js';

import { AddMessageData } from 'app/api/messages/types';
import { addMessage } from 'app/api/messages/service';
import { CompanyDetail } from 'app/api/company/types';
import { ChatFormData } from 'app/api/SBChat';
import { useAuth } from 'hooks';
import { RudderAnalyticsName } from 'rudderAnalytics';
import { notificationObserver } from 'utils/observer';

const handleAttachmentError = (dataItem: AddMessageData, err: AttachmentError) => {
  if (dataItem?.image) {
    const errorMessage = err?.response?.data?.image?.[0];

    if (errorMessage.length)
      notificationObserver.publish({
        type: 'error',
        title: errorMessage,
      });
  }
};

interface AttachmentError {
  response: {
    data: {
      image: string[];
    };
  };
}

interface SendMessageI {
  channel: string;
  quoteId: number;
  orderId: number;
  isSampleRequest: boolean;
  seller: CompanyDetail;
  control: Control<ChatFormData>;
  handleAction: () => void;
  handleClearMessage: () => void;
  handleUpdateFiles: (
    files: {
      file: File;
    }[],
  ) => void;
}

export const useSendMessage = ({
  channel,
  quoteId,
  orderId,
  isSampleRequest,
  seller,
  control,
  handleAction,
  handleClearMessage,
  handleUpdateFiles,
}: SendMessageI) => {
  const { activeUser } = useAuth();
  const queryClient = useQueryClient();

  const {
    mutate: handleAddMessages,
    isLoading: isSendMessageLoading,
    isSuccess: isMessageSuccessfullySent,
  } = useMutation(
    (data: AddMessageData[]) => {
      let sendErrorFiles: { file: File }[] = [];
      data.forEach((msg) => !!msg.image && sendErrorFiles.push({ file: msg.image }));

      return Promise.all(
        data.map(
          (dataItem) =>
            (!!dataItem.image || !!dataItem.text?.trim()) &&
            addMessage(dataItem)
              .then(() => {
                if (dataItem?.image) {
                  sendErrorFiles = [...sendErrorFiles].filter((file) => {
                    return file.file.name !== dataItem.image?.name;
                  });
                } else {
                  handleClearMessage();
                }

                const analyticsEvent = dataItem?.image
                  ? RudderAnalyticsName.ATTACHMENT_UPLOADED
                  : RudderAnalyticsName.MESSAGE_SENT;

                const analyticsOptions = {
                  quote_id: quoteId,
                  order_id: orderId,
                  supplier_name: seller?.name,
                  supplier_id: seller?.id,
                  is_sample_request: isSampleRequest,
                };

                rudderanalytics.track(analyticsEvent, analyticsOptions);
              })
              .catch((err: AttachmentError) => {
                handleAttachmentError(dataItem, err);
              })
              .finally(() => {
                handleUpdateFiles(sendErrorFiles);
              }),
        ),
      );
    },
    {
      onSuccess: () => {
        handleAction();
      },
      onSettled: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['order-messages'] });
      },
      onError: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.resetQueries(['order-messages']);
      },
    },
  );

  const message = useWatch({
    control,
    name: 'message',
  });

  const files = useWatch({
    control,
    name: 'files',
  });

  const fileMessages = useMemo(() => {
    return files?.map((file) => ({
      channel,
      image: file.file,
      quote: quoteId,
      author: activeUser?.user.id,
    }));
  }, [channel, activeUser, files, quoteId]);

  const handleConfirm = useCallback(() => {
    const messages: AddMessageData[] = [];

    if (message) {
      messages.push({
        channel,
        text: message,
        quote: quoteId,
        author: activeUser?.user.id,
      });
    }

    if (fileMessages) {
      messages.push(...fileMessages);
    }

    if (messages.length) {
      handleAddMessages(messages);
    }

    if (!messages.length) {
      handleAction();
    }
  }, [channel, handleAction, handleAddMessages, message, fileMessages, activeUser, quoteId]);

  return { isSendMessageLoading, isMessageSuccessfullySent, handleConfirm };
};
