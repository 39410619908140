import { useState, useCallback, useEffect, useMemo } from 'react';

import { generatePath, useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import { Product } from 'app/api/products/types';
import { UnitsType } from 'app/api/quotes/types';
import { ROUTES } from 'app/routes/constants';
import { QuoteFormData } from '../../../hooks/types';

interface UseQuoteRequestFormWrapperProps {
  data: Product[];
  defaultUnit: UnitsType;
  companyDisplayId?: string;
}

export const useQuoteRequestFormWrapper = ({
  data,
  defaultUnit,
  companyDisplayId = '',
}: UseQuoteRequestFormWrapperProps) => {
  const { productId, quoteId } = useParams();
  const [searchParams] = useSearchParams();

  const [choosedProductsKeys, setChoosedProductsKeys] = useState<Set<number>>(new Set());

  const navigate = useNavigate();
  const matcQuoteRequestPage = useMatch(ROUTES.buyer.quoteRequest);
  const matchSampleRequestPage = useMatch(ROUTES.buyer.sampleRequest);

  const isValidProductId = useMemo(() => data.some(({ id }) => id.toString() === productId), [data, productId]);
  const isAddProductPage = !!matcQuoteRequestPage || !!matchSampleRequestPage;

  useEffect(() => {
    if (isAddProductPage && data && !!productId?.length && !isValidProductId) {
      const path = generatePath(
        matchSampleRequestPage ? ROUTES.buyer.sampleRequestCompany : ROUTES.buyer.quoteRequestCompany,
        { companyDisplayId },
      );

      navigate(path);
    }
  }, [
    choosedProductsKeys,
    companyDisplayId,
    data,
    isAddProductPage,
    isValidProductId,
    matchSampleRequestPage,
    navigate,
    productId,
    productId?.length,
  ]);

  const updateProductsKeys = useCallback((value: number) => {
    setChoosedProductsKeys((prev) => {
      const newKeys = new Set([...prev]);
      newKeys.add(value);
      return newKeys;
    });
  }, []);

  const removeProductKey = useCallback((value: number) => {
    setChoosedProductsKeys((prev) => {
      const newKeys = new Set([...prev]);
      newKeys.delete(value);
      return newKeys;
    });
  }, []);

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm<QuoteFormData>({
    defaultValues: {
      unitsType: defaultUnit,
    },
  });

  const {
    fields: choosedProducts,
    append,
    remove,
  } = useFieldArray<QuoteFormData, 'quotes', 'listId'>({
    control,
    name: 'quotes',
    keyName: 'listId',
  });

  const unitsType = watch('unitsType');

  const setChoosedProducts = useCallback(
    (value: Product) => {
      append({ ...value, value: '' });
      updateProductsKeys(value.id);
    },
    [append, updateProductsKeys],
  );

  const removeChoosedProducts = useCallback(
    (id: number, index: number) => {
      remove(index);
      removeProductKey(id);
    },
    [remove, removeProductKey],
  );

  useEffect(() => {
    if (productId && isValidProductId) {
      reset(
        { quotes: [{ ...data?.find((item) => item.id === Number(productId)), value: '' }] },
        { keepDefaultValues: true },
      );
      updateProductsKeys(Number(productId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidSearchProductId = useMemo(
    () => searchParams.has('productId') && data.some(({ id }) => id.toString() === searchParams.get('productId')),
    [data, searchParams],
  );
  useEffect(() => {
    if (searchParams.has('productId') && isValidSearchProductId) {
      const searchProductId = searchParams.get('productId');
      reset(
        { quotes: [{ ...data?.find((item) => item.id === Number(searchProductId)), value: '' }] },
        { keepDefaultValues: true },
      );
      updateProductsKeys(Number(searchProductId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allowToNavigate = useCallback(() => {
    const { quotes } = getValues();
    const product = quotes?.find((item) => item.id === Number(productId));

    if (product && choosedProducts.length === 1) {
      return product.value === '';
    }

    return false;
  }, [productId, choosedProducts, getValues]);

  return {
    watch,
    register,
    quoteId,
    productId,
    control,
    unitsType,
    isDirty,
    dirtyFields,
    choosedProducts,
    choosedProductsKeys,
    handleSubmit,
    setChoosedProducts,
    removeChoosedProducts,
    allowToNavigate,
  };
};
