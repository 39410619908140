import { FC, useCallback, useState } from 'react';
import { Box, Menu } from '@mui/material';
import { useWatch, Control } from 'react-hook-form';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { CommonButton } from 'components/CommonButton';
import { RadioGroupController } from 'components/RadioGroupController';
import { RadioGroupItem } from 'components/RadioGroupController/types';

import { styles } from './styles';

export const useSelectInpuut = (control: Control, controlName: string) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const currentVal = useWatch({
    control,
    name: controlName,
  });

  return {
    anchorEl,
    currentValue: currentVal,
    open,
    handleClick,
    handleClose,
  };
};

interface SelectInputProps {
  data: RadioGroupItem[];
  control: Control;
  controlName: string;
}

export const SelectInput: FC<SelectInputProps> = ({ data, control, controlName }) => {
  const { anchorEl, open, handleClick, handleClose } = useSelectInpuut(control, controlName);
  const value = useWatch({
    control,
    name: controlName,
  });

  return (
    <>
      <CommonButton
        sx={{
          ...styles.menuButton,
          ...(open && styles.menuButtonOpened),
        }}
        onClick={handleClick}
        disableRipple
      >
        {value}
        <Box
          sx={{
            ...styles.menuButtonIcon,
            ...(open && styles.menuButtonIconopened),
          }}
        >
          <ArrowDownIcon />
        </Box>
      </CommonButton>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroupController controlName={controlName} control={control} data={data} handleClose={handleClose} />
      </Menu>
    </>
  );
};
