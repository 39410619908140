import { UnitsType } from 'app/api/quotes';
import { amountSubtitleUnits } from 'pages/DashboardPage/constants';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';

export const cardItemTitles = (unitsType: UnitsType) => ({
  amount: {
    title: 'Amount',
    subtitle: `(${amountSubtitleUnits[unitsType]})`,
  },
  quotePrice: {
    title: 'Quote',
    subtitle: `($ per ${getUnitDisplayValue(unitsType, true)})`,
  },
  quoteSubtotals: {
    title: 'Quote',
    subtitle: '(subtotals)',
  },
  latestOfferPrice: {
    title: 'Latest Offer',
    subtitle: `($ per ${getUnitDisplayValue(unitsType, true)})`,
  },
  latestOfferSubtotals: {
    title: 'Latest Offer',
    subtitle: '(subtotals)',
  },
  counterOfferPrice: {
    title: 'Counter Offer',
    subtitle: `($ per ${getUnitDisplayValue(unitsType, true)})`,
  },
});
