import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  header: {
    padding: '28px 8px 4px 22px ',
    display: 'grid',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    background: customTheme.custom.white,
    zIndex: 5,
  },
  logo: {
    height: '26px',
    padding: '10px 0',
    flex: '0 0 174px',
    cursor: 'pointer',

    svg: {
      width: '174px',
    },
  },
  accountButton: {
    minWidth: 'unset',
    width: 'fit-content',
    height: '20px',
    justifySelf: 'end',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
  accountButtonText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    textDecorationLine: 'underline',
    color: customTheme.palette.primary.P100,
  },
};
