import { ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import semillas from 'assets/images/logo_tus_semillas.svg';
import basf from 'assets/images/basf_img.png';
import may from 'assets/images/may_img.png';
import chaiTai from 'assets/images/chai_tai.png';
import hollarSeeds from 'assets/images/hollarSeeds.png';
import indoAmerica from 'assets/images/indoamerica.png';
import knownYouSeed from 'assets/images/known-you-seed.png';
import ramiroArnedo from 'assets/images/ramiro-arnedo.png';
import seedBound from 'assets/images/seedbound.png';
import feltrinSeed from 'assets/images/feltrin-seed.png';
import condor from 'assets/images/condor-seed.png';
import agriseed from 'assets/images/agriseed.png';

import { styles } from './styles';

const messages = {
  main: 'Source from',
};

// NOTE: We are unable to display Tus Semillas logo as they are not yet onboarded
const leadingSuppliers = [
  { name: 'BASF', logo: basf, withRadius: true },
  { name: 'Tus Semillas', logo: semillas },
  { name: 'agriseed', logo: agriseed },
  { name: 'May', logo: may, withRadius: true },
  { name: 'Chai Tai', logo: chaiTai },
  { name: 'knownYouSeed', logo: knownYouSeed },
  { name: 'condor', logo: condor },
  { name: 'Hollar Seeds', logo: hollarSeeds },
  { name: 'feltrinSeed', logo: feltrinSeed },
  { name: 'indoAmerica', logo: indoAmerica },
  { name: 'seedBound', logo: seedBound },
  { name: 'ramiroArnedo', logo: ramiroArnedo },
];

export const LeadingSuppliers = (): ReactElement => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>{messages.main}</Typography>
      <Box sx={styles.logoContainer}>
        {leadingSuppliers.map((supplier) => (
          <Box
            key={supplier.name}
            component="img"
            src={supplier.logo}
            alt={supplier.name}
            sx={styles.logo(!!supplier.withRadius)}
          />
        ))}
      </Box>
    </Box>
  );
};
