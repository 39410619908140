import { FC } from 'react';

import { Box } from '@mui/material';

import { PageLoader, ProductsList } from 'components';
import { useDeviceType } from 'hooks';
import { EmptyState } from '../EmptyState';
import { useProducts } from './hooks';
import { styles } from './styles';

interface ProductsListWrapperProps {
  searchParam: string | undefined;
  geneticTypeValue: string;
  colorValue: (string | boolean)[];
  continentValue: (string | boolean)[];
  resistancesValue: (string | boolean)[];
  applyResistancesFilter: boolean;
  sortValue: string;
  minWeightValue: number | undefined;
  maxWeightValue: number | undefined;
  applyWeightFilter: boolean;
  minMaturityValue: number | undefined;
  maxMaturityValue: number | undefined;
  applyMaturityFilter: boolean;
  organicValue: string;
}

export const ProductsListWrapper: FC<ProductsListWrapperProps> = ({
  searchParam,
  geneticTypeValue,
  colorValue,
  continentValue,
  resistancesValue,
  applyResistancesFilter,
  sortValue,
  minWeightValue,
  maxWeightValue,
  applyWeightFilter,
  minMaturityValue,
  maxMaturityValue,
  applyMaturityFilter,
  organicValue,
}): JSX.Element => {
  const { isMobileSize } = useDeviceType();
  const { onLoadMore, products, isLoading, onProductCardClick, isProductsNextPageLoading, hasNextPage } = useProducts({
    searchParam,
    geneticTypeValue,
    colorValue,
    continentValue,
    resistancesValue,
    applyResistancesFilter,
    sortValue,
    minWeightValue,
    maxWeightValue,
    applyWeightFilter,
    minMaturityValue,
    maxMaturityValue,
    applyMaturityFilter,
    organicValue,
  });

  if (isLoading) {
    return (
      <Box sx={styles.loaderWrap}>
        <PageLoader />
      </Box>
    );
  }

  if (!products.length) {
    return (
      <Box sx={styles.emptyWrap}>
        <EmptyState />
      </Box>
    );
  }

  return (
    <Box sx={styles.listWrap}>
      <ProductsList
        columnCount={isMobileSize ? 1 : undefined}
        data={products}
        isLoading={isProductsNextPageLoading}
        hasNextPage={hasNextPage}
        mobileCardOrientation="horizontal"
        openCard={onProductCardClick}
        loadMore={onLoadMore}
      />
    </Box>
  );
};
