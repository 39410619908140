import { CSSProperties } from 'react';

import { customTheme } from 'theme/MuiThemeProvider/theme';
import { mobileViewPortMinWidth } from '../../../constants/styleConstants';

export const styles = {
  mainWrap: (isShadow: boolean, isExpanded: boolean, minHeight?: CSSProperties['minHeight']) => ({
    zIndex: 10,
    display: 'grid',
    left: 0,
    gap: '8px',
    alignItems: 'center',
    flex: `0 0 ${isExpanded ? '88px' : '28px'}`,
    padding: '8px 14px',
    width: '100%',
    minWidth: mobileViewPortMinWidth,
    backgroundColor: customTheme.custom.white,
    ...(isShadow && {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    }),

    ...(minHeight && {
      minHeight,
    }),
  }),
  toolBar: (isJustifyCenter: boolean) => ({
    minHeight: 'unset',
    padding: 0,
    justifyContent: isJustifyCenter ? 'center' : 'space-between',
    maxHeight: '32px',
    boxSizing: 'border-box',
  }),
  leftSideWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  logo: {
    height: '27px',
    flex: '0 0 114px',
    cursor: 'pointer',

    svg: {
      width: '114px',
    },
  },
  menuButton: {
    padding: '4px',
    marginLeft: '-4px',
  },
};
