import { FC } from 'react';

import { UseFormWatch } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import { formatNumber } from 'utils/formatText';

import { useQuoteSellerTotal } from '../../../hooks';
import { QuoteTableFormData } from '../../../types';
import { styles } from './styles';
import { QuoteColumns, QuoteColumn } from '../QuoteTable/constants';
import { headerStyleMap } from '../QuoteTable';

interface QuoteSellerTotalProps {
  columns: QuoteColumns[];
  watch: UseFormWatch<QuoteTableFormData>;
}

const Column = ({ id, watch }: { id: QuoteColumn; watch: UseFormWatch<QuoteTableFormData> }) => {
  const { totalPrice, totalAmount } = useQuoteSellerTotal(watch);
  // const totalAmountWidth = '100px';
  // const totalTitleWidth = isScrolledList ? '198px' : '204px';
  const headerStyles = headerStyleMap[id];

  if (id === QuoteColumn.Quantity) {
    return <TableCell sx={{ ...styles.cell, ...headerStyles }}>{formatNumber(totalAmount) || '-'}</TableCell>;
  }
  if (id === QuoteColumn.Subtotals) {
    return <TableCell sx={{ ...styles.cell, ...headerStyles }}>{totalPrice}</TableCell>;
  }
  return <TableCell sx={{ ...styles.cell, ...headerStyles }} />;
};

export const QuoteSellerTotal: FC<QuoteSellerTotalProps> = ({ columns, watch }) => {
  // const { totalPrice, totalAmount, isScrolledList } = useQuoteSellerTotal(watch);

  // const totalAmountWidth = isScrolledList ? '312px' : '316px';
  // const totalTitleWidth = isScrolledList ? '198px' : '204px';

  return (
    <TableRow sx={{ ...styles.footer }}>
      <TableCell sx={{ ...styles.cell, ...styles.totalCell('230px') }}>Total</TableCell>

      {columns.slice(1).map((column) => (
        <Column key={column.col} id={column.col} watch={watch} />
      ))}
    </TableRow>
  );
};
