import { FC } from 'react';

import { Box } from '@mui/material';

import { SidebarNavigationContainer } from 'layout/SidebarNavigationContainer';
import { SideBarSectionTag } from '../SideBarSectionTag';
import { styles } from './styles';

interface MobileMainMenuProps {
  onItemClick: () => void;
}

export const MobileMainMenu: FC<MobileMainMenuProps> = ({ onItemClick }) => {
  return (
    <>
      <SideBarSectionTag title="Main Menu" />
      <Box sx={styles.listWrap}>
        <SidebarNavigationContainer onItemClick={onItemClick} />
      </Box>
    </>
  );
};
