import * as rudderanalytics from 'rudder-sdk-js';

import { QuoteProduct } from 'app/api/quotes/types';
import { CompanyDetail } from 'app/api/company/types';
import { formatNestedProductAnalyticsData } from './formatNestedProductAnalyticsData';

interface GetOrderSubmittedEventProductsProps {
  products: QuoteProduct[];
  seller?: CompanyDetail;
}

interface GetOrderSubmittedEventDocumentsProps {
  documents: {
    kind: string;
  }[];
}

export const getOrderSubmittedEventProducts = ({
  products,
  seller,
}: GetOrderSubmittedEventProductsProps): rudderanalytics.apiObject[] => {
  const results = products.map(({ id, amount, price, product }) => ({
    quote_product_id: id,
    supplier_name: seller?.name ?? '',
    supplier_id: seller?.id ?? '',
    continent: seller?.address?.continent ?? '',
    price,
    amount,
    ...formatNestedProductAnalyticsData(product),
  })) as unknown as rudderanalytics.apiObject[];

  return results;
};

export const getOrderSubmittedEventDocuments = ({ documents }: GetOrderSubmittedEventDocumentsProps) => {
  return documents.map(({ kind }) => ({ type: kind }));
};
