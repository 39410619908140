import { formatNumber } from './formatText';

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  GBP = 'GBP',
  CNY = 'CNY',
  AUD = 'AUD',
  CAD = 'CAD',
  HKD = 'HKD',
  SGD = 'SGD',
  KRW = 'KRW',
  NZD = 'NZD',
  INR = 'INR',
  MXN = 'MXN',
  TWD = 'TWD',
  ZAR = 'ZAR',
  BRL = 'BRL',
}

type CurrenyDetails = {
  label: string;
  abbr: Currency;
  symbol: string;
};

export const currencies: CurrenyDetails[] = [
  { label: 'U.S. dollar', abbr: Currency.USD, symbol: '$' },
  { label: 'Euro', abbr: Currency.EUR, symbol: '€' },
  { label: 'Japanese yen', abbr: Currency.JPY, symbol: '¥' },
  { label: 'Sterling', abbr: Currency.GBP, symbol: '£' },
  { label: 'Renminbi', abbr: Currency.CNY, symbol: 'CN¥' },
  { label: 'Australian dollar', abbr: Currency.AUD, symbol: 'A$' },
  { label: 'Canadian dollar', abbr: Currency.CAD, symbol: 'C$' },
  { label: 'Hong Kong dollar', abbr: Currency.HKD, symbol: 'HK$' },
  { label: 'Singapore dollar', abbr: Currency.SGD, symbol: 'S$' },
  { label: 'South Korean won', abbr: Currency.KRW, symbol: '₩' },
  { label: 'New Zealand dollar', abbr: Currency.NZD, symbol: 'NZ$' },
  { label: 'Indian rupee', abbr: Currency.INR, symbol: '₹' },
  { label: 'Mexican peso', abbr: Currency.MXN, symbol: 'MX$' },
  { label: 'New Taiwan dollar', abbr: Currency.TWD, symbol: 'NT$' },
  { label: 'South African rand', abbr: Currency.ZAR, symbol: 'R' },
  { label: 'Brazilian real', abbr: Currency.BRL, symbol: 'R$' },
];

export const getCurrency = (currency: Currency) => {
  return currencies.find((c) => c.abbr === currency) as CurrenyDetails;
};

export const currencyFormat = (num?: number, curr: Currency = Currency.USD) => {
  if (!num) {
    return '';
  }
  const currency = getCurrency(curr);
  if (!currency) {
    return '';
  }
  return `${currency.symbol}${formatNumber(num, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};
