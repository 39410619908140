import { apiRoutes } from '../apiRoutes';
import { customerApi, fileUploadApi } from '../index';
import {
  AddMessageData,
  MessagesListData,
  MessagesListRequest,
  UnreadMessagesIndicatorRequest,
  UnreadMessagesIndicatorData,
} from './types';

export const getMessagesData = async (params: MessagesListRequest) => {
  const response = await customerApi.get<MessagesListData>(apiRoutes.messages._, { params });

  return response?.data;
};

export const addMessage = async (data: AddMessageData) => {
  await fileUploadApi.post<MessagesListData>(apiRoutes.messages._, data);
};

export const getUnreadMessagesIndicator = async ({ quoteId }: UnreadMessagesIndicatorRequest) => {
  const response = await customerApi.get<UnreadMessagesIndicatorData>(
    apiRoutes.messages.unreadMessagesIndicator(quoteId),
  );

  return response?.data;
};
