import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  pageWrap: {
    padding: '0 76px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      marginTop: '-14px',
    },
  },
  pageContentWrap: {
    display: 'flex',
    gap: '40px',
    margin: '40px 0 16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: '16px',
      margin: '16px 0 0 0',
    },
  },
  column: {
    flex: '1',
  },
  sectionTitle: {
    marginBottom: '24px',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    color: customTheme.custom.black,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '16px',
    },
  },
};
