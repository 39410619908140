import { FC } from 'react';

import { Box, Drawer } from '@mui/material';
import { ClientSentMessages } from 'SendbirdUIKitGlobal';

import { SBLoader, SBSearchMessages } from 'components';
import { styles } from './styles';

interface SearchMessagesWrapProps {
  channelName: string;
  channelUrl: string;
  collapsed: boolean;
  isLoading: boolean;
  selectedMessageId?: number;
  handleResultClick: (message: ClientSentMessages) => void;
  handleToggleSearchPannel: () => void;
}

export const SearchMessagesWrap: FC<SearchMessagesWrapProps> = ({
  collapsed,
  channelUrl,
  isLoading,
  channelName,
  selectedMessageId,
  handleResultClick,
  handleToggleSearchPannel,
}) => {
  return (
    <Box sx={styles.wrap}>
      <Drawer sx={styles.drawer} variant="persistent" anchor="right" open={!collapsed}>
        <Box sx={styles.drawerInner}>
          {isLoading ? (
            <SBLoader isFullHeight />
          ) : (
            <Box sx={styles.inner}>
              {!collapsed && (
                <SBSearchMessages
                  channelUrl={channelUrl}
                  channelName={channelName}
                  selectedMessageId={selectedMessageId}
                  handleResultClick={handleResultClick}
                  handleToggleSearchPannel={handleToggleSearchPannel}
                />
              )}
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};
