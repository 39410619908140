import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  pageWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      marginTop: '-14px',
    },
  },
  pageContentWrap: {
    display: 'flex',
    gap: '40px',
    margin: '40px 0 16px',
    padding: '0 76px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: '16px',
      margin: '16px 0 0 0',
      padding: 0,
    },
  },
  column: {
    flex: '1',
  },
};
