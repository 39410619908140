import { useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { FEATURED_SELLERS_TYPE, FEATURED_TYPE, SEARCH_KEY, SEARCH_TYPE } from '../constants';

export const useProductsListPage = () => {
  const { type, subType } = useParams();
  const [searchParams] = useSearchParams();

  const searchParam = useMemo(() => {
    if (type !== SEARCH_TYPE) {
      return undefined;
    }

    return searchParams.get(SEARCH_KEY) || undefined;
  }, [searchParams, type]);

  const title = useMemo(() => {
    if (type === FEATURED_TYPE || type === FEATURED_SELLERS_TYPE) {
      return type;
    }

    return type === SEARCH_TYPE ? `"${String(searchParam)}"` : subType || type || '';
  }, [type, subType, searchParam]);

  return {
    title,
    searchParam,
  };
};
