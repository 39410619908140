import { FC } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { ReactComponent as EmptyIcon } from 'assets/icons/noResult.svg';
import { CommonButton } from 'components';
import { useModal } from 'hooks';
import { FeedbackFormPopup } from '../FeedbackFormPopup';
import { styles } from './styles';

export const EmptyState: FC = (): JSX.Element => {
  const { open, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <Box>
        <EmptyIcon />
        <Typography sx={styles.title}>No product found</Typography>
        <Typography sx={styles.text}>We didn&apos;t find any matches for your request.</Typography>

        <Divider sx={styles.divider} />

        <Box sx={styles.bottomSection}>
          <Typography sx={styles.text}>Can&apos;t find what you are looking for? Let us know! </Typography>
          <CommonButton onClick={handleOpenModal} sx={styles.button}>
            Request Product
          </CommonButton>
        </Box>
      </Box>
      <FeedbackFormPopup isOpen={open} handleCloseModal={handleCloseModal} />
    </>
  );
};
