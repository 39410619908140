import { MouseEventHandler, useCallback, useMemo, useState } from 'react';

import { useQuery } from 'react-query';
import * as rudderanalytics from 'rudder-sdk-js';

import { ProductDetail, Product } from 'app/api/products/types';
import { getProductData } from 'app/api/products/service';
import { RudderAnalyticsName, formatProductAnalyticsData } from 'rudderAnalytics';

interface UseProductRowProps {
  choosedProductsKeys: Set<number>;
  data: ProductDetail;
  isSampleRequest: boolean;
  setChoosedProducts: (value: Product) => void;
}

export const useProductRow = ({
  choosedProductsKeys,
  data,
  isSampleRequest,
  setChoosedProducts,
}: UseProductRowProps) => {
  const [open, setOpen] = useState(false);

  const isChoosed = useMemo(() => {
    return choosedProductsKeys.has(data.id);
  }, [data, choosedProductsKeys]);

  const handleAddProduct = useCallback<MouseEventHandler<SVGElement>>(
    (e) => {
      e?.stopPropagation();
      setChoosedProducts(data);

      if (open) {
        const analyticsOptions = {
          ...formatProductAnalyticsData({ product: data }),
          is_sample_request: isSampleRequest,
        };

        rudderanalytics.track(RudderAnalyticsName.PRODUCT_ADDED_TO_QUOTE_FROM_DROPDOWN, analyticsOptions);
      }
    },
    [data, isSampleRequest, open, setChoosedProducts],
  );

  const handleViewDetails = useCallback(() => {
    setOpen((prev) => {
      if (!prev) {
        const analyticsOptions = formatProductAnalyticsData({ product: data });

        rudderanalytics.track(RudderAnalyticsName.PRODUCT_DROPDOWN_VIEWED, analyticsOptions);
      }

      return !prev;
    });
  }, [data]);

  const { isLoading, data: detailsData } = useQuery(['company', data.id], () => getProductData(data.id), {
    enabled: open,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    detailsData,
    isChoosed,
    isLoading,
    open,
    handleAddProduct,
    handleViewDetails,
  };
};
