import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  actionsWrap: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '16px 24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '12px',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
      gridTemplateRows: 'min-content',
      gridAutoRows: '1fr',
      alignItems: 'stretch',

      '&:only-child': {
        gridTemplateColumns: '1fr',
      },
    },
  },
  quoteButton: {
    gridColumn: '1 / 2',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      height: 'auto',
      minHeight: '40px',
      padding: '6px 14px',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17px',
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      gridColumn: 'unset',
    },
  },
  sampleButton: {
    gridColumn: '2 / 3',
    color: customTheme.custom.black.B50,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      height: 'auto',
      minHeight: '40px',
      padding: '6px 14px',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17px',
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      gridColumn: 'unset',
    },
  },
  messageButton: {
    backgroundColor: customTheme.palette.secondary.S100,
    gridColumn: '1 / 3',

    '&.MuiButton-containedPrimary': {
      ':hover': {
        backgroundColor: customTheme.palette.secondary.S70,
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      height: 'auto',
      minHeight: '40px',
      padding: '6px 14px',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17px',
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      gridColumn: 'unset',
    },
  },
};
