import { FC, useCallback } from 'react';

import { generatePath } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { Box } from '@mui/material';

import { ROUTES } from 'app/routes/constants';
import { capitalizeAll, capitalizeFirstLetters } from 'utils/formatText';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { useDeviceType } from 'hooks';
import { Company } from 'app/api/company';
import { Link } from 'components/Link';
import { CustomArrowButton } from '../ProductsSlider/CustomArrowButton';
import { LinkButton } from '../LinkButton';
import { styles } from './styles';

interface CompanySliderProps {
  name: string;
  pathName: string;
  companies: Company[];
}

const settings: Settings = {
  infinite: false,
  slidesToScroll: 5,
  slidesToShow: 5,
  nextArrow: <CustomArrowButton direction="next" />,
  prevArrow: <CustomArrowButton direction="prev" />,
  variableWidth: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 1164,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 903,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
    {
      breakpoint: 660,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
    {
      breakpoint: 450,
      settings: { slidesToShow: 1, slidesToScroll: 1 },
    },
  ],
};

const CompanyCard = ({ company, cardWidth }: { company: Company; cardWidth: number }) => {
  const path = generatePath(ROUTES.common.company, { displayId: company.displayId });
  return (
    <Link
      key={company.displayId}
      path={path}
      trackType={AnalyticsLinkType.CARD}
      sx={{ ...styles.slideWrap, height: cardWidth, minWidth: cardWidth, maxWidth: cardWidth }}
    >
      <Box component="img" src={company.logo || ''} sx={styles.img} />
    </Link>
  );
};

export const CompanySlider: FC<CompanySliderProps> = ({ name, pathName, companies }) => {
  const { isMobileSize } = useDeviceType();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const path = generatePath(ROUTES.buyer.products, {
    type: capitalizeFirstLetters(pathName),
  });

  const cardWidth = isMobileSize ? 175 : 250;

  const handleTitleClick = useCallback(() => {
    handleTrackLinkClickEvent({ path, text: capitalizeAll(pathName), type: AnalyticsLinkType.BUTTON });
  }, [handleTrackLinkClickEvent, pathName, path]);

  return (
    <Box component="section" sx={{ ...styles.wrap }}>
      <LinkButton to={path} sx={{ ...styles.title }} onClick={handleTitleClick}>
        {capitalizeAll(name)}
      </LinkButton>
      <Slider {...settings}>
        {companies.map((company) => (
          <CompanyCard key={company.displayId} company={company} cardWidth={cardWidth} />
        ))}
      </Slider>
    </Box>
  );
};
