import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    height: '32px',
    minHeight: '32px',
    paddingLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    background: customTheme.custom.black.B10,
  },
  label: {
    fontSize: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B70,
  },
};
