export type QuoteColumns = {
  title: string;
  subTitle?: string;
  note?: string;
  isDisabled?: boolean;
  col: QuoteColumn;
};

export enum QuoteColumn {
  Variety,
  Quantity,
  Units,
  PriceperUnit,
  Quote,
  LastestOffer,
  RequestedPrice,
  Subtotals,
  CounterOffer,
  AcceptPrice,
  NotAvailable,
  BuyerRemove,
}

export const tableHeaderColumns = (
  isBuyer: boolean,
  isEditMode: boolean,
  hasMadeOffer: boolean,
  hasCounterOffer: boolean,
  currency: string,
): QuoteColumns[] =>
  [
    {
      title: 'Variety',
      col: QuoteColumn.Variety,
      subTitle: 'Requested',
      isDisabled: false,
    },
    {
      title: 'Quantity',
      col: QuoteColumn.Quantity,
      isDisabled: false,
    },
    {
      title: 'Units',
      col: QuoteColumn.Units,
      isDisabled: false,
    },
    {
      title: 'Price per Unit',
      col: QuoteColumn.PriceperUnit,
      note: 'Only displayed to seller on initial quote to input price',
      isDisabled: true, // hasMadeOffer || hasCounterOffer || isBuyer,
    },
    {
      title: 'Your Last Offer',
      col: QuoteColumn.LastestOffer,
      subTitle: '(per unit)',
      note: 'This is what the user has last requested.',
      isDisabled: !hasMadeOffer || !isEditMode,
    },
    {
      title: isBuyer ? 'Seller Offer' : 'Buyer Request',
      col: QuoteColumn.RequestedPrice,
      note: 'This is what the OPPOSING user has last offered/requested.',
      subTitle: '(per unit)',
      isDisabled: (!isBuyer && !hasCounterOffer) || (!isBuyer && !isEditMode && hasMadeOffer),
    },
    {
      title: !hasCounterOffer || !isEditMode ? 'Price per Unit' : 'Counter Offer',
      col: QuoteColumn.CounterOffer,
      subTitle: `(${currency} per unit)`,
      isDisabled: isBuyer && !isEditMode,
    },
    {
      // eslint-disable-next-line no-nested-ternary
      title: hasCounterOffer ? 'Subtotals' : 'Quote',
      col: QuoteColumn.Subtotals,
      subTitle: '(subtotals)',
      isDisabled: false,
    },
    {
      title: 'Accept Price?',
      col: QuoteColumn.AcceptPrice,
      subTitle: '(Singular Product)',
      isDisabled: !hasCounterOffer || !isEditMode || (!isBuyer && !hasMadeOffer),
    },
    {
      title: 'Not Available',
      col: QuoteColumn.NotAvailable,
      subTitle: '(Out of Stock)',
      note: 'Only allowed for seller',
      isDisabled: !isEditMode || isBuyer,
    },
    {
      title: '',
      col: QuoteColumn.BuyerRemove,
      subTitle: '',
      note: 'Buyer Remove Item',
      isDisabled: !isEditMode || !isBuyer,
    },
  ].filter((column) => !column.isDisabled);
