import { useMemo } from 'react';

import { Quote } from 'app/api/quotes';
import { useAuth } from 'hooks';

interface UsePageInnerProps {
  data: Quote;
}

export const usePageInner = ({ data }: UsePageInnerProps) => {
  const { activeUser, isBuyer } = useAuth();

  const company = isBuyer ? data.seller : data.buyer;
  const isSampleRequest = data.isSampleType;
  const subtitle = `${company.address.city}, ${company.address.country}`;
  const title = company.name;

  const isWaiting = useMemo(() => activeUser?.kind === data.waitAnswer, [activeUser?.kind, data.waitAnswer]);

  return {
    isBuyer,
    isSampleRequest,
    isWaiting,
    subtitle,
    title,
  };
};
