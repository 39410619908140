import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { CommonFiltersDrawer } from '../CommonFiltersDrawer';
import { FilterList } from '../FilterList';

interface FilterListPopupProps {
  control: Control<ProductsFiltersForm>;
  isAnySelected: boolean;
  parsedColors: {
    value: string;
    label: string;
  }[];
  parsedContinents: {
    value: string;
    label: string;
  }[];
  isMaturityFilter: boolean;
  isWeightFilter: boolean;
  isMaturityRange: boolean;
  isWeightRange: boolean;
  isOrganicFilter: boolean;
  minWeightLimit: number;
  maxWeightLimit: number;
  weightStep: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
  maturityStep: number;
  isOpen: boolean;
  handleClearFilters: () => void;
  handleClose: () => void;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
  setValue: UseFormSetValue<ProductsFiltersForm>;
}

export const FilterListPopup: FC<FilterListPopupProps> = ({
  control,
  isAnySelected,
  isOpen,
  isMaturityFilter,
  isMaturityRange,
  isOrganicFilter,
  isWeightFilter,
  isWeightRange,
  maxMaturityLimit,
  minMaturityLimit,
  maxWeightLimit,
  minWeightLimit,
  maturityStep,
  weightStep,
  parsedColors,
  parsedContinents,
  handleClearFilters,
  handleClose,
  setValue,
  getValues,
  reset,
}) => {
  return (
    <CommonFiltersDrawer
      closeButtonType="cross"
      isClearButton
      isOpen={isOpen}
      title="Filter"
      handleClear={isAnySelected ? handleClearFilters : undefined}
      handleClose={handleClose}
    >
      <FilterList
        control={control}
        isMaturityFilter={isMaturityFilter}
        isMaturityRange={isMaturityRange}
        isOrganicFilter={isOrganicFilter}
        isWeightFilter={isWeightFilter}
        isWeightRange={isWeightRange}
        maxMaturityLimit={maxMaturityLimit}
        minMaturityLimit={minMaturityLimit}
        maxWeightLimit={maxWeightLimit}
        minWeightLimit={minWeightLimit}
        maturityStep={maturityStep}
        weightStep={weightStep}
        parsedColors={parsedColors}
        parsedContinents={parsedContinents}
        setValue={setValue}
        getValues={getValues}
        reset={reset}
      />
    </CommonFiltersDrawer>
  );
};
