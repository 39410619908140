import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface FullStatItemProps {
  name: string;
  value: string;
}

export const FullStatItem: FC<FullStatItemProps> = ({ name, value }) => {
  return (
    <Box sx={{ ...styles.wrap }}>
      <Typography sx={{ ...styles.title }}>{name}</Typography>
      <Typography sx={{ ...styles.value }}>{value}</Typography>
    </Box>
  );
};
