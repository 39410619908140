import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { UserRole } from 'app/api/auth';
import { ShipmentMethod } from 'app/api/company';
import { QuoteProduct, QuoteRequestStatus, QuoteProductStatus, Quote } from 'app/api/quotes';
import { useCancelQuoteRequestMutation, useAuth } from 'hooks';
import { QuoteTableFormData } from '../../../types';

interface UseQuoteRequestDetailsFormProps {
  data: QuoteProduct[];
  quoteData: Quote | undefined;
  quoteId: number;
  waitAnswer: UserRole | undefined;
  isSampleRequest: boolean;
  handleOpenSendQuoteModal: () => void;
  handleClose: () => void;
}

export const useQuoteRequestDetailsForm = ({
  data,
  quoteId,
  quoteData,
  handleOpenSendQuoteModal,
  handleClose,
}: UseQuoteRequestDetailsFormProps) => {
  const { isBuyer, activeUser } = useAuth();
  const waitAnswer = quoteData?.waitAnswer;
  const isSampleRequest = !!quoteData?.isSampleType;

  const { isLoadingCancel, handleCancelRequest } = useCancelQuoteRequestMutation({
    isSampleRequest,
    quoteId,
    onSuccessAction: handleClose,
  });

  const [confirmedProductsCount, setConfirmedProductsCount] = useState<number>(
    data.filter((item) => item.acceptedPrice).length,
  );

  const increaseConfirmedProducsCount = useCallback(() => {
    setConfirmedProductsCount((prevCount) => prevCount + 1);
  }, []);

  const decreaseConfirmedProducsCount = useCallback(() => {
    setConfirmedProductsCount((prevCount) => prevCount - 1);
  }, []);

  const { watch, setValue, control, register, handleSubmit, trigger, clearErrors } = useForm<QuoteTableFormData>({
    defaultValues: {
      quotes: data.map((item) => item),
      priceUnits: quoteData?.priceUnits,
      incoterms: quoteData?.incoterms ?? ShipmentMethod.exw,
    },
  });

  const quotesData = useWatch({
    control,
    name: 'quotes',
  });

  const allDataWithPrices = useMemo(() => {
    return data.filter((item) => item.price);
  }, [data]);

  const availableProducts = quotesData.filter(({ status }) => status === QuoteProductStatus.AVAILABLE);
  const isAllProductsNotAvailable = availableProducts.length === 0;

  const isAllConfirmed = useMemo(() => {
    return confirmedProductsCount === availableProducts.length;
  }, [confirmedProductsCount, availableProducts.length]);
  const isAllConfirmedOrNotAvailable = quotesData.every(
    (product) =>
      product?.status === QuoteProductStatus.NOT_AVAILABLE ||
      product?.status === QuoteProductStatus.REMOVED ||
      product?.status === QuoteProductStatus.RESTRICTED ||
      !!product?.updateAcceptedPrice ||
      !!product?.acceptedPrice,
  );
  const isAllReadyToBeConfirmedOrNotAvailable = quotesData.every(
    (product) =>
      product?.status === QuoteProductStatus.NOT_AVAILABLE ||
      product?.status === QuoteProductStatus.REMOVED ||
      product?.status === QuoteProductStatus.RESTRICTED ||
      !!product?.updateAcceptedPrice ||
      !!product?.acceptedPrice ||
      !!product?.value,
  );

  const buyerCanAccept = quotesData.every(
    (product) =>
      product?.status === QuoteProductStatus.NOT_AVAILABLE ||
      product?.status === QuoteProductStatus.REMOVED ||
      product?.status === QuoteProductStatus.RESTRICTED ||
      !!product?.updateAcceptedPrice ||
      !!product?.acceptedPrice ||
      !!product?.price,
  );

  const isWaiting = useMemo(() => {
    if (isBuyer && isAllConfirmedOrNotAvailable) {
      return false;
    }

    return activeUser?.kind === waitAnswer;
  }, [activeUser, isBuyer, isAllConfirmedOrNotAvailable, waitAnswer]);

  const isDiscussion = useMemo(() => {
    return isBuyer || isWaiting || (!isBuyer && allDataWithPrices.length);
  }, [isBuyer, allDataWithPrices, isWaiting]);

  const onSubmit = useCallback(() => {
    handleOpenSendQuoteModal();
  }, [handleOpenSendQuoteModal]);

  const handleCancel = useCallback(
    (reason?: string) => {
      handleCancelRequest({ status: QuoteRequestStatus.CANCELED, metadata: { reason } });
    },
    [handleCancelRequest],
  );

  return {
    watch,
    control,
    onSubmit,
    register,
    setValue,
    handleSubmit,
    trigger,
    clearErrors,
    isAllConfirmed,
    increaseConfirmedProducsCount,
    decreaseConfirmedProducsCount,
    isDiscussion,
    handleCancelRequest,
    isLoadingCancel,
    handleCancel,
    isWaiting,
    isAllProductsNotAvailable,
    isAllConfirmedOrNotAvailable,
    isAllReadyToBeConfirmedOrNotAvailable,
    buyerCanAccept,
  };
};
