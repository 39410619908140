import { FC } from 'react';

import { Control, FieldArrayWithId, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { CompanyDetail } from 'app/api/company/types';
import { Prompt, LoadingButton, FormError } from 'components';
import { UnitsType } from 'app/api/quotes/types';
import { QuoteFormData } from '../../hooks/types';
import { getUnitDisplayValue } from '../../utils';
import { ChoosedProductRow } from '../ChoosedProductRow';
import { useChoosedProducts } from './hooks';
import { styles } from './styles';

export interface ChoosedProductsProps {
  control: Control<QuoteFormData>;
  choosedProducts: FieldArrayWithId<QuoteFormData, 'quotes', 'listId'>[];
  isFormDirty: boolean;
  seller: CompanyDetail | undefined;
  unitsType: UnitsType;
  isSampleRequest: boolean;
  removeChoosedProducts: (id: number, index: number) => void;
  handleSubmit: UseFormHandleSubmit<QuoteFormData>;
  watch: UseFormWatch<QuoteFormData>;
  allowToNavigate: () => boolean;
}

export const ChoosedProducts: FC<ChoosedProductsProps> = ({
  control,
  choosedProducts,
  isFormDirty,
  seller,
  unitsType,
  isSampleRequest,
  removeChoosedProducts,
  handleSubmit,
  watch,
  allowToNavigate,
}): JSX.Element => {
  const { formError, onSubmit, isLoading, addQuoteLoading, isSuccessRequest } = useChoosedProducts({
    watch,
    seller,
    unitsType,
    isSampleType: isSampleRequest,
  });

  return (
    <Box sx={{ ...styles.wrap }}>
      <Box>
        <Box sx={{ ...styles.headerRow }}>
          <Typography sx={{ ...styles.columnTitle, ...styles.productColumnTitle }}>
            You are about to request a{' '}
            {isSampleRequest && (
              <Typography data-testid="sample-label" component="span" sx={{ ...styles.sampleLabel }}>
                sample{' '}
              </Typography>
            )}
            quote for
          </Typography>
          <Typography sx={{ ...styles.columnTitle, ...styles.countColumnTitle }}>
            {isSampleRequest
              ? 'How big of a sample do you need?'
              : `How much do you need (${getUnitDisplayValue(unitsType, true)})?`}
          </Typography>
        </Box>

        <Box data-testid="choosed-products" sx={{ ...styles.rowsWrap }}>
          {choosedProducts.map((data, index) => (
            <ChoosedProductRow
              key={data.listId}
              index={index}
              control={control}
              data={data}
              formError={formError}
              removeChoosedProducts={removeChoosedProducts}
            />
          ))}
        </Box>
      </Box>

      {formError && (
        <Box aria-label="error" sx={{ ...styles.errorBox }}>
          <FormError text={formError} />
        </Box>
      )}

      <Box sx={{ ...styles.actions }}>
        <LoadingButton loading={isLoading || addQuoteLoading} onClick={handleSubmit(onSubmit)}>
          Send Request
        </LoadingButton>
      </Box>

      <Prompt when={isFormDirty && !isSuccessRequest} allowToNavigate={allowToNavigate} />
    </Box>
  );
};
