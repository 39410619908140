import { useCallback, useEffect, useMemo } from 'react';

import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { QuoteProduct, QuoteProductStatus, QuoteRequestStatus, deleteQuoteProduct } from 'app/api/quotes';
import { useAuth, useCancelQuoteRequestMutation } from 'hooks';
import { currencyFormat } from 'utils/currencyFormat';
import { QuoteTableFormData } from '../types';

interface UseQuoteDiscussionRowProps {
  data: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  quoteId: number;
  isNotlastProduct: boolean;
  isSampleRequest: boolean;
  removeChoosedProducts: (index: number) => void;
  setValue: UseFormSetValue<QuoteTableFormData>;
  increaseConfirmedProducsCount: () => void;
  decreaseConfirmedProducsCount: () => void;
  handleCloseModal: () => void;
  closeDetails: () => void;
  handleCloseEditAmountModal: () => void;
  handleNavigate?: () => void;
}

export const useQuoteDiscussionRow = ({
  control,
  data,
  index,
  isNotlastProduct,
  isSampleRequest,
  quoteId,
  closeDetails,
  decreaseConfirmedProducsCount,
  handleCloseEditAmountModal,
  handleCloseModal,
  increaseConfirmedProducsCount,
  removeChoosedProducts,
  setValue,
  handleNavigate,
}: UseQuoteDiscussionRowProps) => {
  const queryClient = useQueryClient();
  const { isBuyer } = useAuth();

  const { mutate: removeProduct, isLoading } = useMutation((id: number) => deleteQuoteProduct(id), {
    onSuccess: () => {
      if (isNotlastProduct) {
        handleRemove();
      }
      handleCloseEditAmountModal();
    },
  });

  const { isLoadingCancel, isCancelSuccess, handleCancelRequest } = useCancelQuoteRequestMutation({
    isSampleRequest,
    quoteId,
  });

  const quotePrice = useWatch({
    control,
    name: `quotes.${index}.value`,
  });

  const counterOfferPrice = useMemo(() => {
    return data.acceptedPrice || data.price;
  }, [data]);

  const totalPrice = useMemo(() => {
    if (!counterOfferPrice) {
      return '-';
    }

    return currencyFormat(data.amount * counterOfferPrice);
  }, [data, counterOfferPrice]);

  const lastOffer = useMemo(() => currencyFormat(Number(counterOfferPrice) || 0), [counterOfferPrice]);

  const isConfirm = useWatch({
    control,
    name: `quotes.${index}.updateAcceptedPrice`,
  });

  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  const handleConfirm = useCallback(() => {
    setValue(`quotes.${index}.updateAcceptedPrice`, counterOfferPrice);
    increaseConfirmedProducsCount();
  }, [setValue, index, increaseConfirmedProducsCount, counterOfferPrice]);

  const handleUndo = useCallback(() => {
    setValue(`quotes.${index}.updateAcceptedPrice`, 0);
    decreaseConfirmedProducsCount();
  }, [setValue, index, decreaseConfirmedProducsCount]);

  const handleRemove = useCallback(() => {
    if (!isConfirm && !data.acceptedPrice) {
      increaseConfirmedProducsCount();
    }

    removeChoosedProducts(index);
    handleCloseModal();

    queryClient.removeQueries('quote-messages');
  }, [
    isConfirm,
    handleCloseModal,
    removeChoosedProducts,
    index,
    increaseConfirmedProducsCount,
    queryClient,
    data.acceptedPrice,
  ]);

  const handleRemoveRequest = useCallback(() => {
    removeProduct(data.id);
  }, [data, removeProduct]);

  const handleCancel = useCallback(() => {
    handleRemoveRequest();
    handleCancelRequest({ status: QuoteRequestStatus.CANCELED });
    handleNavigate?.();
  }, [handleRemoveRequest, handleCancelRequest, handleNavigate]);

  const isCancelLoading = isLoadingCancel || isLoading;

  const isActive = quoteProductStatus === QuoteProductStatus.AVAILABLE;
  const isRemoved = quoteProductStatus === QuoteProductStatus.REMOVED;
  const isUnavailable = quoteProductStatus === QuoteProductStatus.NOT_AVAILABLE;
  const isRestricted = quoteProductStatus === QuoteProductStatus.RESTRICTED;
  const isOpacity = !isActive;
  const isSellerInputNeeded = isBuyer && isActive && !lastOffer;

  useEffect(() => {
    if (isCancelSuccess) {
      closeDetails();
    }
  }, [isCancelSuccess, closeDetails]);

  return {
    quotePrice,
    isLoading,
    lastOffer,
    totalPrice,
    isConfirm,
    isOpacity,
    isActive,
    isRemoved,
    isUnavailable,
    isRestricted,
    isSellerInputNeeded,
    counterOfferPrice,
    isCancelLoading,
    handleConfirm,
    handleUndo,
    handleRemoveRequest,
    handleCancel,
  };
};
