import { FC } from 'react';

import { Box } from '@mui/material';

import { NotificationsContent } from 'components';
import { useNotificationsHandlers } from 'hooks';
import { styles } from './styles';

export const NotificationsPage: FC = () => {
  const { handleItemClick } = useNotificationsHandlers();

  return (
    <Box sx={styles.wrap}>
      <NotificationsContent
        isNotificationsQueryEnabled
        isRefetchInterval={false}
        loaderSize={96}
        handleItemClick={handleItemClick}
      />
    </Box>
  );
};
