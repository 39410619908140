export const apiRoutes = {
  auth: {
    logIn: '/api/v1/login/',
    logOut: '/api/v1/logout/',
    createPassword: '/api/v1/invitation/accept/',
    changePassword: '/api/v1/password/change_password/',
    resetPassword: {
      start: '/api/v1/reset-password/start/',
      submit: '/api/v1/reset-password/submit/',
    },
  },
  cropsFamilies: '/api/v1/crop-families/',
  homePage: '/api/v1/home-page/',
  featuredSellers: '/api/v1/company/featured/',
  user: {
    customers: 'api/v1/customers/',
    customerProfile: (id: number) => `/api/v1/customer-profile/${id}/`,
  },
  common: {
    contactUs: '/contact-us/',
  },
  company: {
    byId: (displayId: string) => `/api/v1/company/${displayId}`,
  },
  country: {
    _: `/api/v1/country`,
  },
  products: {
    _: '/api/v1/products/',
    byId: (id: number) => `/api/v1/products/${id}/`,
    similar: (id: number) => `/api/v1/search/similar/?product_id=${id}`,
    imageById: (id: number) => `/api/v1/products/${id}/image/`,
    colors: '/api/v1/products/colors/',
    continents: '/api/v1/products/continents/',
    seedsWeight: '/api/v1/products/seeds_weight/',
    seedsMaturity: '/api/v1/products/seeds_maturity/',
    // SPZ-1550: update after API integration
    seedsResistances: '/api/v1/products/seeds_resistances/',
    organicSeed: '/api/v1/products/organic_seed/',
  },
  messages: {
    _: '/api/v1/messages/',
    unreadMessagesIndicator: (id: number) => `/api/v1/quotes/${id}/unread-messages-indicator/`,
  },
  orders: {
    _: '/api/v1/orders/',
    byId: (id: number) => `/api/v1/orders/${id}/`,
    paymentInfo: (id: number) => `/api/v1/order/${id}/payment-info/`,
    counter: '/api/v1/orders/counter/',
  },
  search: {
    autocomplete: '/api/v1/search/autocomplete/',
  },
  shipment: {
    ordersTracking: (id: number) => `/api/v1/shipment/orders-tracking/${id}/`,
  },
  quote: {
    quoteRequest: '/api/v1/quotes/',
    quoteData: (id: number) => `/api/v1/quotes/${id}/`,
    quoteProduct: `/api/v1/quote-products/`,
    quoteProducts: (id: number) => `/api/v1/quote-products/${id}/`,
    prices: `/api/v1/prices/`,
  },
  documents: {
    folders: `/api/v1/document-folders/`,
    folder: (id: number) => `/api/v1/document-folders/${id}/`,
    markAsComplete: (id: number) => `/api/v1/document-folders/${id}/mark-as-complete/`,
    markAsIncomplete: (id: number) => `/api/v1/document-folders/${id}/mark-as-incomplete/`,
  },
  order: {
    upload: '/api/v1/upload',
  },
  orderDocuments: {
    _: '/api/v1/order-documents/',
    byId: (id: number) => `/api/v1/order-documents/${id}/`,
  },
  notifications: {
    _: '/api/v1/notifications/',
    totalCount: '/api/v1/notifications/total_count/',
    byId: (id: number) => `/api/v1/notifications/${id}/`,
  },
  productNotFoundRequests: {
    _: '/api/v1/product-not-found-requests/',
  },
  chat: {
    _: '/api/v1/chat/',
    token: '/api/v1/chat/token/',
    byId: (id: string) => ({
      _: `/api/v1/chat/${id}/`,
      memberMuteStatus: `/api/v1/chat/${id}/member_mute_status/`,
    }),
  },
};
