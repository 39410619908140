import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  headerWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  title: (isRequestSamplePage: boolean) => ({
    marginBottom: '16px',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '34px',
    color: isRequestSamplePage ? customTheme.palette.primary.P100 : customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,
      fontSize: '14px',
      lineHeight: '24px',
    },
  }),
  dropdownWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  label: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      lineHeight: '24px',
    },
  },
  loaderWrap: {
    height: 'calc(100dvh - 264px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
};
