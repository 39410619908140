import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import bannerUrl from 'assets/images/contactUsBanner.png';
import mobileBannerUrl from 'assets/images/contactUsBannerMobile.png';
import { useDeviceType } from 'hooks';
import { styles } from './styles';

export const ContactUsBanner: FC = (): JSX.Element => {
  const { isMobileSize } = useDeviceType();

  const src = isMobileSize ? mobileBannerUrl : bannerUrl;

  return (
    <Box sx={{ ...styles.banner(src) }}>
      <Box sx={{ ...styles.wrap }}>
        <Typography sx={{ ...styles.contentTitle }}>Contact us</Typography>

        <Typography sx={{ ...styles.contentText }}>We’d love to hear from you!</Typography>
      </Box>
    </Box>
  );
};
