export const getReStartMessageString = (
  crop: string | undefined,
  variety: string | undefined,
  name: string | null | undefined,
): string => {
  const isVarietyOrName = Boolean(variety || name);
  const cropValueEndString = isVarietyOrName ? ', ' : ' ';
  const cropValue = crop ? `${crop}${cropValueEndString}` : '';
  const varietyValue = variety ? `${variety} ` : '';
  const nameValue = name ? ` ${name} ` : '';

  return ['RE: ', cropValue, varietyValue, nameValue, '- '].join('');
};
