import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  container: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '0',
    },
  },
  pageWrap: (isBuyer: boolean) => ({
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: `calc(100vh - ${isBuyer ? '116px' : '64px'})`,
      overflowX: 'hidden',
      overflowY: 'auto',
      marginLeft: '-14px',
      marginRight: '-14px',
      paddingRight: '14px',
      paddingLeft: '14px',
      display: 'flex',
      flexDirection: 'column',
      ...scrollMixin,
    },
  }),
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  footerWrap: (isMarginBottom: boolean) => ({
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexGrow: 1,
      display: 'flex',
      width: 'calc(100% - 28px)',
      boxSizing: 'border-box',
      marginLeft: '-14px',
      marginRight: '-14px',
      ...(isMarginBottom && {
        marginBottom: '84px',
      }),
      alignItems: 'end',
      justifyContent: 'stretch',
    },
  }),
};
