import { FC } from 'react';

import { useTrackPageViewedEvent } from 'rudderAnalytics';
import { AuthMainUi } from '../../components/AuthMainUi';
import { ResetPasswordPageInner } from './components';

export const ResetPasswordPage: FC = () => {
  useTrackPageViewedEvent();

  return (
    <AuthMainUi>
      <ResetPasswordPageInner />
    </AuthMainUi>
  );
};
