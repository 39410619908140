import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'relative',
    padding: '16px',
    borderRadius: '10px',
    backgroundColor: customTheme.custom.white,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: customTheme.palette.primary.P5,
      boxShadow: '4px 8px 15px rgba(94, 96, 98, 0.2)',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '20px 6px 18px 16px',
      display: 'grid',
      gap: '8px',
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',

      ':hover': {
        backgroundColor: customTheme.custom.white,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  innerWrap: {
    display: 'flex',
    gap: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '100%',
      gap: '14px',
      overflow: 'hidden',
    },
  },
  wrapHighlighted: {
    backgroundColor: customTheme.palette.secondary.S30,
    ':hover': {
      backgroundColor: customTheme.palette.secondary.S20,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      ':hover': {
        backgroundColor: customTheme.palette.secondary.S30,
      },
    },
  },
  overlay: {
    '::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: customTheme.palette.primary.P5,
      opacity: 0.5,
      pointerEvents: 'none',
      zIndex: 2,
    },
  },
  image: {
    height: '140px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '10px',
    aspectRatio: '1.29 / 1',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '84px',
    },
  },
  content: {
    flex: '1 1 0',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: 'calc(100% - 92px)',
      flex: '1 0 0',
      overflow: 'hidden',
    },
  },
  header: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
      maxWidth: '100%',
    },
  },
  tagsWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  meta: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'end',
    height: '100%',
  },
  metaFooter: {
    width: '234px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  productsList: {
    height: '42px',
    marginBottom: '12px',
    lineHeight: '21px',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
    },
  },
  productItem: {
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
    },
  },
  companyWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    gap: '2px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: 'calc(100% - 112px)',
    },
  },
  companyName: {
    maxWidth: '240px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '100%',
    },
  },
  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  companyLink: {
    textDecoration: 'underline',
    transition: 'all 0.3s ease-out',
    ':hover': {
      cursor: 'pointer',
      color: customTheme.palette.info.main,
    },
  },
  location: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
  },
  totalTitle: {
    marginBottom: '2px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
  },
  totalAmount: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '24px',
    color: customTheme.palette.secondary.S100,
    marginRight: '2px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'inline-block',
      height: 'fit-content',
      maxHeight: '26px',
    },
  },
  totalUnits: (isThousandSeeds: boolean) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: customTheme.palette.secondary.S100,
    position: 'relative',
    top: '2px',
    ...(isThousandSeeds && {
      marginLeft: '2px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '64px',
      display: 'inline-block',
      whiteSpace: 'pre-wrap',
      ...(isThousandSeeds && {
        top: '1px',
        fontSize: '10px',
        lineHeight: '11px',
      }),
    },
  }),
  datesWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    gap: '4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingBottom: '4px',
      flexGrow: 1,
    },
  },
  date: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      whiteSpace: 'nowrap',
    },
  },
  order: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B100,
  },
  viewButton: {
    zIndex: 5,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  quoteTotalWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  mobileFooterWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: '100%',
    },
  },
  metaWrap: {
    width: '234px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  companyMobileWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
    },
  },
  mobileDatesWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      alignItems: 'end',
    },
  },
  totalInnerWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  totalWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
  amountWrap: {
    display: 'flex',
  },
};
