import { useQuery } from 'react-query';

import { getContactUsData } from 'app/api/contactUs/service';

export const useContactUsPage = () => {
  const { data, isLoading } = useQuery(['contact-us-data'], () => getContactUsData(), {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return {
    data,
    isLoading,
  };
};
