import { FC, SyntheticEvent } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import imagePlaceholder from 'assets/images/imagePlaceholderLarge.png';
import { ProductDetail } from 'app/api/products';
import { useImageHandlers } from 'hooks';
import { KeyStatsCard } from '../KeyStatsCard';
import { styles } from './styles';

interface KeyStatsSectionProps extends Pick<ProductDetail, 'image'> {
  hideButton: boolean;
  inStock: boolean;
  stats: { name: string; value: string }[];
  minOrder?: number | null;
  isVerticalImage: boolean | null;
  isCreateChannelLoading: boolean;
  isLegend: boolean;
  onRequestQuoteClick: () => void;
  onRequestSampleClick: () => void;
  onSendMessageClick: () => void;
  handleImageLoad: (e: SyntheticEvent) => void;
}

export const KeyStatsSection: FC<KeyStatsSectionProps> = ({
  image,
  hideButton,
  inStock,
  minOrder,
  isCreateChannelLoading,
  isLegend,
  isVerticalImage,
  stats,
  onRequestQuoteClick,
  onRequestSampleClick,
  onSendMessageClick,
  handleImageLoad,
}) => {
  const { handleImageError } = useImageHandlers();
  const isImage = !!image;
  const isVertical = isVerticalImage !== null && isVerticalImage;

  return (
    <Box sx={{ ...styles.mainWrap }}>
      <Box sx={{ ...styles.wrap }}>
        <Box sx={styles.leftWrap(isVertical, !isImage, isLegend)}>
          {!inStock && <Box sx={{ ...styles.outOfStockTag }}>Out of Stock</Box>}
          {isImage && (
            <Box component="img" src={image || ''} sx={{ ...styles.bgImage }} onError={() => handleImageError(image)} />
          )}
          <Box sx={styles.inner(isImage)}>
            <Box
              component="img"
              src={image || imagePlaceholder}
              sx={{ ...styles.image(isVertical) } as SxProps}
              onError={handleImageError(imagePlaceholder)}
              onLoad={handleImageLoad}
            />
          </Box>
        </Box>

        <Box sx={{ ...styles.cardWrap }}>
          <KeyStatsCard
            image={image}
            inStock={inStock}
            hideButton={hideButton}
            isCreateChannelLoading={isCreateChannelLoading}
            onRequestQuoteClick={onRequestQuoteClick}
            onRequestSampleClick={onRequestSampleClick}
            onSendMessageClick={onSendMessageClick}
            stats={stats}
          />
        </Box>
      </Box>
      {!!minOrder && <Typography sx={{ ...styles.minOrder }}>Minimum Order: {minOrder}KG</Typography>}
    </Box>
  );
};
