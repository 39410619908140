import { useState, MouseEvent, useCallback } from 'react';

import { UseFormSetValue } from 'react-hook-form';

import { QuoteProductStatus } from 'app/api/quotes';
import { QuoteTableFormData } from '../types';

interface UseSellersInitialQuoteActionsProps {
  index: number;
  setValue: UseFormSetValue<QuoteTableFormData>;
}

export const useSellersInitialQuoteActions = ({ index, setValue }: UseSellersInitialQuoteActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMarkNotAvailable = useCallback(() => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.NOT_AVAILABLE);
    handleClose();
  }, [handleClose, index, setValue]);

  const handleRemove = useCallback(() => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.REMOVED);
    handleClose();
  }, [handleClose, index, setValue]);

  const handleUndo = useCallback(() => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.AVAILABLE);
  }, [index, setValue]);

  const [isRemoveProductPopupOpen, setIsRemoveProductPopupOpen] = useState(false);
  const toggleIsRemovePoductOpen = () => setIsRemoveProductPopupOpen((v) => !v);
  return {
    open,
    anchorEl,
    handleClick,
    handleClose,
    handleMarkNotAvailable,
    handleRemove,
    handleUndo,
    isRemoveProductPopupOpen,
    toggleIsRemovePoductOpen,
  };
};
