import * as rudderanalytics from 'rudder-sdk-js';

import { Product } from 'app/api/products/types';

interface FormatProductAnalyticsDataProps {
  product: Product;
  isRank?: boolean;
}

export const formatProductAnalyticsData = ({
  product: { id, sku, variety, name, geneticType, company, color, rank },
  isRank = false,
}: FormatProductAnalyticsDataProps): rudderanalytics.apiObject => {
  return {
    product_id: id,
    sku,
    variety,
    name: name ?? '',
    supplier_name: company.name,
    supplier_id: company.id,
    type: geneticType,
    continent: company.continent,
    color: color ?? '',
    ...(isRank && {
      rank: rank ?? '',
    }),
  };
};
