import { useCallback, useMemo, useState } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getFeaturedSellers, getHomePageData, HomePageSection, Product } from 'app/api/products';
import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { shuffleArray } from 'utils/shuffleArray';
import { Company } from 'app/api/company';

interface BuyerHomePageHandlers {
  isLoading: boolean;
  homePageData: HomePageSection[];
  handleProductClick: (id: number) => void;

  featuredProducts: Product[];
  newProducts: Product[];
  featuredSuppliers: Company[];
}

// NOTE: This is a hard-coded list of featured suppliers
const featuredSuppliersOrder = [
  185, 191, 188, 17, 8, 49, 18, 135, 48, 24, 50, 26, 28, 2, 16, 30, 23, 29, 25, 66, 22, 84,
];
const featuredSuppliersOrderSet = new Set(featuredSuppliersOrder);

export const useBuyerHomePageHandlers = (): BuyerHomePageHandlers => {
  const [data, setData] = useState<HomePageSection[]>([]);
  const [featuredSuppliers, setFeaturedSuppliers] = useState<Company[]>([]);
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([]);
  const [newProducts, setNewProducts] = useState<Product[]>([]);
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const { isLoading, isFetching } = useQuery(['home-page'], () => getHomePageData(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // to show the Vagetable at the top of the crop families list
      // https://yalantis.atlassian.net/browse/SPZ-1953
      const vegetableIndex = data.cropFamilies.findIndex((family) => family.name.toLowerCase() === 'vegetable');
      const vegetableSection = data.cropFamilies.splice(vegetableIndex, 1)[0];
      data.cropFamilies.unshift(vegetableSection);
      setFeaturedProducts(shuffleArray<Product>(data.featuredProducts));
      setNewProducts(shuffleArray<Product>(data.newProducts));
      setData(data.cropFamilies);
    },
  });

  const { isLoading: isFeaturedSellersLoading, isFetching: isFeaturedSellersFetching } = useQuery(
    ['featured-sellers'],
    () => getFeaturedSellers(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const allCompanies = data.filter((supplier) => !!supplier.logo);
        const companyMap = allCompanies.reduce((acc, supplier) => {
          return { ...acc, [supplier.id]: supplier };
        }, {} as { [id: number]: Company });
        const orderedCompanies = featuredSuppliersOrder.map((id) => companyMap[id]).filter(Boolean);
        allCompanies.forEach((company) => {
          if (!featuredSuppliersOrderSet.has(company.id)) {
            orderedCompanies.push(company);
          }
        });
        setFeaturedSuppliers(orderedCompanies);
      },
    },
  );

  const filteredData = useMemo(() => [...data].filter((section) => !!section.products.length), [data]);

  const homePageData = useMemo(
    () =>
      filteredData.map(({ products, ...section }) => ({
        products: shuffleArray<Product>(products),
        ...section,
      })),
    [filteredData],
  );

  const handleProductClick = useCallback(
    (id: number) => {
      const path = generatePath(ROUTES.common.product, { id });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: '', type: AnalyticsLinkType.CARD });
    },
    [handleTrackLinkClickEvent, navigate],
  );

  return {
    isLoading: isLoading || isFetching || isFeaturedSellersLoading || isFeaturedSellersFetching,
    homePageData,
    handleProductClick,
    newProducts,
    featuredSuppliers,
    featuredProducts,
  };
};
