import { FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import logo from 'assets/images/headerLogo.svg';

import { getDefaultUserRoute } from 'app/providers/utils';
import { useAuth } from 'hooks';
import { UserMenu } from '../UserMenu';
import { HeaderSearch } from '../HeaderSearch';
import { NavigationContainer } from '../NavigationContainer';
import { Notifications } from '../Notifications';
import { styles } from './styles';

interface HeaderProps {
  isShadow: boolean;
  isActionsEnabled?: boolean;
}

export const Header: FC<HeaderProps> = ({ isShadow, isActionsEnabled = true }) => {
  const navigate = useNavigate();
  const { isBuyer } = useAuth();

  const handleRedirectHome = useCallback(() => {
    navigate(getDefaultUserRoute(isBuyer));
  }, [isBuyer, navigate]);

  return (
    <Box sx={{ ...styles.mainWrap(isShadow) }}>
      <Box sx={{ ...styles.wrap }}>
        <Box sx={{ ...styles.leftSideWrap }}>
          <Box sx={{ ...styles.logo }}>
            <Box component="img" src={logo} onClick={handleRedirectHome} />
          </Box>

          {isActionsEnabled && (
            <>
              <Box sx={{ ...styles.spacer }} />
              <NavigationContainer />{' '}
            </>
          )}
        </Box>

        {isActionsEnabled && (
          <Box sx={{ ...styles.rightSideWrap }}>
            {isBuyer && <HeaderSearch />}
            <Notifications />
            <UserMenu />
          </Box>
        )}
      </Box>
    </Box>
  );
};
