import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '10px',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
  sampleLabel: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 600,
    color: customTheme.palette.primary.P100,
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4px',
  },
  subTitle: {
    fontSize: '10px',
    lineHeight: '16px',
    color: customTheme.custom.black.B70,
  },
  tag: {
    height: 'fit-content',
    width: 'min-content',
    minWidth: '60px',
    padding: '6px',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 600,
    borderRadius: '3px',
    color: customTheme.custom.black.B70,
    backgroundColor: customTheme.custom.black.B10,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
};
