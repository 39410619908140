import { FC } from 'react';

import { PageLoader } from 'components';
import { PageWithDashboardNavigation } from 'layout/DashboardNavigation';

import { PageHeader, DashboardPageInner } from './components';
import { useDashboardPageHandlers, useDashboardTabs } from './hooks';
import { styles } from './styles';

export const DashboardPage: FC = () => {
  const { isBuyer, activeTab, isSampleRequestsTab } = useDashboardTabs();
  const { isLoading, quoteRequestsData, control, sortOptions, ordersData } = useDashboardPageHandlers({
    activeTab,
    isSampleRequestsTab,
  });

  return (
    <PageWithDashboardNavigation contentSx={styles.container}>
      {isLoading || !activeTab ? (
        <PageLoader />
      ) : (
        <>
          <PageHeader control={control} activeTab={activeTab} sortOptions={sortOptions} />
          <DashboardPageInner
            isBuyer={isBuyer}
            activeTab={activeTab}
            quoteRequestsData={quoteRequestsData}
            ordersData={ordersData}
          />
        </>
      )}
    </PageWithDashboardNavigation>
  );
};
