import { FC, useCallback, useState } from 'react';

import { NavLink, generatePath, matchPath, useLocation } from 'react-router-dom';
import { Box, Collapse, Link } from '@mui/material';

import { useAuth } from 'hooks';
import { AnalyticsLinkType, LinkClickedEvenetProps, useLinkClickedEvenet } from 'rudderAnalytics';
import { ROUTES } from 'app/routes/constants';
import { DashboardMainTabs, SampleRequestsTabs } from 'app/api/quotes';

import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/order.svg';
import { ReactComponent as SampleIcon } from 'assets/icons/sample.svg';
import { ReactComponent as SendMessageIcon } from 'assets/icons/sendMessage.svg';
import { ReactComponent as EmailThinIcon } from 'assets/icons/emailThin.svg';
import { ReactComponent as StorefrontIcon } from 'assets/icons/storefront.svg';
import { ReactComponent as LeafIcon } from 'assets/icons/leaf.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { styles } from './styles';

interface NavigationContainerProps {
  onItemClick?: () => void;
}

interface HeaderNavigationItem {
  text: string;
  route: string;
  roles: ('buyer' | 'seller')[];
  routeProps?: { [key: string]: string };
  Icon: FC;
  children?: {
    name: string;
    route: string;
    routeProps?: { [key: string]: string };
  }[];
}

export const headerNavigationItems = (): HeaderNavigationItem[] => [
  {
    text: 'Home',
    route: ROUTES.buyer._,
    Icon: HomeIcon,
    roles: ['buyer'],
  },
  {
    text: 'Dashboard',
    route: ROUTES.dashboard._,
    Icon: DashboardIcon,
    roles: ['buyer'],
  },
  {
    text: 'Dashboard',
    route: ROUTES.dashboard._,
    Icon: DashboardIcon,
    roles: ['seller'],
  },
  {
    text: 'Orders',
    route: ROUTES.dashboard.orders.tab,
    routeProps: { tab: DashboardMainTabs.QUOTE_REQUESTS },
    Icon: OrderIcon,
    roles: ['buyer', 'seller'],
    children: [
      {
        name: 'Quote Requests',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.QUOTE_REQUESTS },
      },
      {
        name: 'Open Orders',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.OPEN_ORDERS },
      },
      {
        name: 'En Route',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.EN_ROUTE },
      },
      {
        name: 'Past',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.PAST_ORDERS },
      },
    ],
  },
  {
    text: 'Samples',
    route: ROUTES.dashboard.samples.tab,
    Icon: SampleIcon,
    roles: ['buyer', 'seller'],
    children: [
      {
        name: 'Sample Requests',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.SAMPLE_REQUESTS },
      },
      {
        name: 'Open Sample Orders',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.SAMPLE_SEND_IN_PROGRESS },
      },
      {
        name: 'Outbound',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.SAMPLE_EN_ROUTE },
      },
      {
        name: 'Past',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.PAST },
      },
    ],
  },
  {
    text: 'Messages',
    Icon: SendMessageIcon,
    route: ROUTES.common.messages,
    roles: ['buyer', 'seller'],
  },
  {
    text: 'My Storefront',
    route: ROUTES.common.company,
    Icon: StorefrontIcon,
    roles: ['seller'],
  },
  { text: 'Products', route: ROUTES.seller.edit._, Icon: LeafIcon, roles: ['seller'] },
  {
    text: 'Contact us',
    Icon: EmailThinIcon,
    route: ROUTES.common.contact,
    roles: ['buyer', 'seller'],
  },
];

const Section = ({
  role,
  nav,
  onItemClick,
  companyId,
}: {
  role: 'buyer' | 'seller';
  nav: HeaderNavigationItem;
  onItemClick?: () => void;
  companyId: string;
}) => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();
  const handleItemClick = useCallback(
    ({ path, text }: Pick<LinkClickedEvenetProps, 'path' | 'text'>) => {
      onItemClick?.();
      handleTrackLinkClickEvent({ path, text, type: AnalyticsLinkType.NAV });
    },
    [handleTrackLinkClickEvent, onItemClick],
  );

  const location = useLocation();
  const hasChildActive = nav.children?.some((c) => matchPath(c.route, location.pathname)) ?? false;
  const [isOpen, setIsOpen] = useState(hasChildActive);

  return (
    <Box key={nav.route} sx={{ ...styles.navItem }}>
      {nav.children ? (
        <Box sx={[styles.rowItem]} onClick={() => setIsOpen((v) => !v)}>
          <nav.Icon />
          <Box>{nav.text}</Box>
        </Box>
      ) : (
        <Link
          sx={styles.rowItem}
          component={NavLink}
          to={generatePath(nav.route, { companyDisplayId: companyId, displayId: companyId, role })}
          onClick={() => handleItemClick({ path: nav.route, text: nav.text })}
          end
        >
          <nav.Icon />
          <Box>{nav.text}</Box>
        </Link>
      )}
      {nav.children && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ ...styles.children }}>
            {nav.children.map((child) => (
              <Link
                sx={styles.childItem}
                component={NavLink}
                end
                key={child.name}
                to={generatePath(child.route, {
                  ...child.routeProps,
                  role,
                })}
              >
                {child.name}
              </Link>
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export const SidebarNavigationContainer: FC<NavigationContainerProps> = ({ onItemClick }) => {
  const { isBuyer, activeUser } = useAuth();

  const navigationItems = headerNavigationItems();

  const role = isBuyer ? 'buyer' : 'seller';
  const companyId = activeUser?.company?.displayId ?? '';
  return (
    <Box component="nav" sx={{ ...styles.navWrap }}>
      {navigationItems
        .filter((nav) => nav.roles.includes(role))
        .map((nav) => (
          <Section key={nav.route} role={role} nav={nav} companyId={companyId} onItemClick={onItemClick} />
        ))}
    </Box>
  );
};
