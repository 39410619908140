import { SxProps } from '@mui/material';

import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  banner: (bgImage: string): SxProps => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    aspectRatio: '308 / 51',
    borderRadius: '30px',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'calc(100% + 28px)',
      height: '210px',
      boxSizing: 'border-box',
      marginLeft: '-14px',
      marginRight: '-14px',
      paddingTop: '56px',
      paddingLeft: '18px',
      alignItems: 'start',
      borderRadius: 0,
      backgroundPosition: '35% top',
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.18)',
      overflow: 'visible',
    },
  }),
  content: {
    width: '25%',
    minWidth: '320px',
    maxWidth: '700px',
    padding: '38px 0 38px 76px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      minWidth: 'unset',
      width: '100%',
    },
  },
  contentTitle: {
    margin: '0 0 12px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '6px',
      fontSize: '24px',
      lineHeight: '28px',
      whiteSpace: 'pre-wrap',
    },
  },
  contentText: {
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      fontSize: '14px',
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
    },
  },
};
