import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    padding: '0px 1000px',
    height: '230px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.bannerBlue,
    overflow: 'hidden',
    margin: '0px -1000px',
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% + 2000px)',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100vw',
      height: '164px',
      marginLeft: '-14px',
      marginRight: '-14px',
      padding: '32px 16px',
      borderRadius: 0,
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.18)',
      overflow: 'visible',
    },
  },
  innerContainer: {
    maxWidth: '2000px',
    position: 'relative',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentTitle: {
    color: customTheme.custom.brightGreen,
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '50px',
    marginBottom: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '12px',
      fontSize: '24px',
      lineHeight: '28px',
      maxWidth: '100%',
    },
  },
  contentText: {
    color: customTheme.custom.white,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    maxWidth: '360px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '18px',
      maxWidth: '100%',
    },
  },
  bannerImgs: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    bottom: '-115px',
    right: 0,
    width: '65%',
    maxWidth: 840,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  bannerImg: {
    objectFit: 'contain',
  },
  sack: {
    img: {
      height: '308px',
    },
  },
  watermelon: {
    marginBottom: '16px',
  },
  corn: {
    marginBottom: '-120px',
    img: {
      height: '494px',
    },
  },
};
