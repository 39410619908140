import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    gap: '40px',
    flex: '1 1 0px',
    justifyContent: 'space-between',
    alignItems: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'baseline',
      gap: '28px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.palette.primary.P100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  value: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.gray.G600,
    textAlign: 'right',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
};
