import { useCallback, useState, MouseEvent } from 'react';

import { useWatch, Control, useController } from 'react-hook-form';

import { unitDropdownValues } from '../../../constants';
import { QuoteFormData } from '../../../hooks/types';
import { getUnitDisplayValue } from '../../../utils';

interface UseUnitDropdownProps {
  control: Control<QuoteFormData>;
}

export const useUnitDropdown = ({ control }: UseUnitDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const {
    field: { onChange },
  } = useController({
    control,
    name: 'unitsType',
  });

  const currentValue = useWatch({
    control,
    name: 'unitsType',
  });

  const options = unitDropdownValues.filter(({ value }) => value !== currentValue);

  const dropdownTitle = getUnitDisplayValue(currentValue);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(!open ? event.currentTarget : null);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOptionClick = useCallback(
    (value: string) => {
      handleClose();
      onChange(value);
    },
    [handleClose, onChange],
  );

  return {
    anchorEl,
    options,
    open,
    dropdownTitle,
    handleOptionClick,
    onChange,
    handleClick,
    handleClose,
  };
};
