import { FC, useState } from 'react';

import { Control, UseFormClearErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { Box } from '@mui/material';

import { Quote, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { useDiscussionTable, useDiscussionTotal, useEditProductAmount } from 'pages/DashboardPage/hooks';
import { EditProductAmountPopup } from 'pages/DashboardPage/components';
import { QuoteDiscussionCard } from '../QuoteDiscussionCard';
import { QuoteRequestActions } from '../QuoteRequestActions';
import { styles } from './styles';

interface QuoteDiscussionListProps {
  control: Control<QuoteTableFormData>;
  isAllConfirmedOrNotAvailable: boolean;
  isAllProductsNotAvailable: boolean;
  isWaiting: boolean;
  isBuyer: boolean;
  isChatOpen: boolean;
  isCanceledQuote: boolean;
  isNewMessages: boolean;
  quoteId: number;
  quoteData: Quote;
  unitsType: UnitsType;
  setValue: UseFormSetValue<QuoteTableFormData>;
  trigger: UseFormTrigger<QuoteTableFormData>;
  clearErrors: UseFormClearErrors<QuoteTableFormData>;
  increaseConfirmedProducsCount: () => void;
  decreaseConfirmedProducsCount: () => void;
  addProducts: () => void;
  closeDetails: () => void;
  watch: UseFormWatch<QuoteTableFormData>;
  handleOpenChat: () => void;
  handleNavigateToDashboard: () => void;
}

export const QuoteDiscussionList: FC<QuoteDiscussionListProps> = ({
  control,
  isAllConfirmedOrNotAvailable,
  isAllProductsNotAvailable,
  isBuyer,
  isChatOpen,
  isWaiting,
  isCanceledQuote,
  isNewMessages,
  quoteData,
  quoteId,
  unitsType,
  addProducts,
  setValue,
  trigger,
  clearErrors,
  increaseConfirmedProducsCount,
  decreaseConfirmedProducsCount,
  closeDetails,
  watch,
  handleOpenChat,
  handleNavigateToDashboard,
}) => {
  const [currentEditId, setCurrentEditId] = useState<number | null>(null);
  const [currentRemoveId, setCurrentRemoveId] = useState<number | null>(null);

  const { choosedProducts, removeChoosedProducts, removeQuoteProductRow, appendQuoteProductRow } = useDiscussionTable(
    control,
    decreaseConfirmedProducsCount,
  );

  const {
    index,
    isEditAmountModalOpen,
    editableProductData,
    handleOpenEditAmountModal,
    handleCloseEditAmountModal,
    handleConfirmEditAmount,
    handleChangeAmount,
  } = useEditProductAmount({
    currentEditId,
    choosedProducts,
    control,
    setCurrentEditId,
    trigger,
    clearErrors,
    setValue,
    setCurrentRemoveId,
    appendQuoteProductRow,
    removeQuoteProductRow,
  });

  const { totalPrice, totalAmount } = useDiscussionTotal({ data: choosedProducts, watch });

  const isSellerInputNeeded =
    isBuyer &&
    isWaiting &&
    choosedProducts.every(
      ({ acceptedPrice, price, status }) => !acceptedPrice && !price && status === QuoteProductStatus.AVAILABLE,
    );

  return (
    <>
      <Box sx={styles.wrap}>
        {choosedProducts.map((data, index) => (
          <QuoteDiscussionCard
            key={data.id}
            data={data}
            unitsType={unitsType}
            control={control}
            index={index}
            isWaiting={isWaiting}
            productsCount={choosedProducts.length}
            quoteId={quoteId}
            isDisabled={isCanceledQuote}
            isCurrentProductRemoveModal={data.id === currentRemoveId}
            isSampleRequest={quoteData.isSampleType}
            setValue={setValue}
            removeChoosedProducts={removeChoosedProducts}
            increaseConfirmedProducsCount={increaseConfirmedProducsCount}
            decreaseConfirmedProducsCount={decreaseConfirmedProducsCount}
            handleOpenEditAmountModal={handleOpenEditAmountModal}
            closeDetails={closeDetails}
            handleCloseEditAmountModal={handleCloseEditAmountModal}
            setCurrentRemoveId={setCurrentRemoveId}
            handleNavigateToDashboard={handleNavigateToDashboard}
          />
        ))}
      </Box>
      {isEditAmountModalOpen && (
        <EditProductAmountPopup
          unitsType={unitsType}
          product={editableProductData}
          control={control}
          index={index}
          handleClose={handleCloseEditAmountModal}
          isOpen={isEditAmountModalOpen}
          handleConfirmEditAmount={handleConfirmEditAmount}
          handleChangeAmount={handleChangeAmount}
        />
      )}

      <QuoteRequestActions
        control={control}
        isAllProductsNotAvailable={isAllProductsNotAvailable}
        isAllConfirmedOrNotAvailable={isAllConfirmedOrNotAvailable}
        isBuyer={isBuyer}
        isCanceledQuote={isCanceledQuote}
        isChatOpen={isChatOpen}
        isNewMessages={isNewMessages}
        isSellerInputNeeded={isSellerInputNeeded}
        isWaiting={isWaiting}
        quoteData={quoteData}
        totalAmount={totalAmount}
        totalPrice={totalPrice}
        unitsType={unitsType}
        addProducts={addProducts}
        handleOpenChat={handleOpenChat}
      />
    </>
  );
};
