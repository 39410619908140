import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { ProductsFiltersForm } from 'app/api/products';
import { ReactComponent as FiltersIcon } from 'assets/icons/filters.svg';
import { CommonButton, CommonBadge } from 'components';
import { FilterListPopup } from './components';
import { useStickyFiltersBar } from './hooks';
import { styles } from './styles';

interface StickyFiltersBarProps {
  control: Control<ProductsFiltersForm>;
  isAnySelected: boolean;
  parsedColors: {
    value: string;
    label: string;
  }[];
  parsedContinents: {
    value: string;
    label: string;
  }[];
  isMaturityFilter: boolean;
  isOrganicFilter: boolean;
  isWeightFilter: boolean;
  isMaturityRange: boolean;
  isWeightRange: boolean;
  minWeightLimit: number;
  maxWeightLimit: number;
  weightStep: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
  maturityStep: number;
  selectedFiltersCount: number;
  clearFilters: () => void;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
  setValue: UseFormSetValue<ProductsFiltersForm>;
}

export const StickyFiltersBar: FC<StickyFiltersBarProps> = ({
  control,
  isAnySelected,
  isMaturityFilter,
  isMaturityRange,
  isOrganicFilter,
  isWeightFilter,
  isWeightRange,
  maxMaturityLimit,
  minMaturityLimit,
  maxWeightLimit,
  minWeightLimit,
  maturityStep,
  parsedColors,
  parsedContinents,
  selectedFiltersCount,
  weightStep,
  clearFilters,
  getValues,
  reset,
  setValue,
}) => {
  const { isFilterListOpen, handleOpenFilterList, handleCloseFilterList, handleClearFilters } = useStickyFiltersBar({
    clearFilters,
  });

  return (
    <>
      <Box sx={styles.wrap}>
        <CommonButton sx={styles.filtersButton} onClick={handleOpenFilterList}>
          <Typography sx={styles.buttonLabel}>Filter</Typography>
          <CommonBadge badgeContent={selectedFiltersCount}>
            <FiltersIcon />
          </CommonBadge>
        </CommonButton>
      </Box>

      <FilterListPopup
        control={control}
        isAnySelected={isAnySelected}
        isOpen={isFilterListOpen}
        isMaturityFilter={isMaturityFilter}
        isMaturityRange={isMaturityRange}
        isWeightFilter={isWeightFilter}
        isWeightRange={isWeightRange}
        isOrganicFilter={isOrganicFilter}
        maxMaturityLimit={maxMaturityLimit}
        minMaturityLimit={minMaturityLimit}
        maxWeightLimit={maxWeightLimit}
        minWeightLimit={minWeightLimit}
        parsedColors={parsedColors}
        parsedContinents={parsedContinents}
        maturityStep={maturityStep}
        weightStep={weightStep}
        handleClearFilters={handleClearFilters}
        handleClose={handleCloseFilterList}
        getValues={getValues}
        reset={reset}
        setValue={setValue}
      />
    </>
  );
};
