import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { TextFormField } from 'components';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonPopup';
import { QuoteProduct, UnitsType } from 'app/api/quotes';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';

import { ProductVariety } from '../ProductVariety';
import { styles } from './styles';

interface EditQuantityPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  unitsType: UnitsType;
  data: QuoteProduct;
  handleUpdateQuantity: (quantity: number) => void;
}

const messages = {
  title: 'Edit Quantity',
  differentQuantity: 'Change quantity to:',
  cancel: 'Cancel',
  confirm: 'Confirm',
};

export const EditQuantityPopup: FC<EditQuantityPopupProps> = ({
  handleClose,
  handleUpdateQuantity,
  unitsType,
  data,
  isOpen = false,
}) => {
  const { control, watch } = useForm<{ quantity: undefined | number }>({
    defaultValues: {
      quantity: undefined,
    },
  });

  const quantity = watch('quantity');

  const handleConfirm = () => {
    if (quantity) {
      handleUpdateQuantity(quantity);
    }
  };
  if (!data) {
    return null;
  }
  return (
    <CommonModal title={messages.title} isOpen={isOpen} handleClose={handleClose}>
      <ProductVariety fullWidth data={data} />

      <Box sx={{ ...styles.content }}>
        <Typography sx={{ ...styles.subTitle }}>
          How much do you need ({getUnitDisplayValue(unitsType, true)})?
        </Typography>
        <TextFormField
          wrapStyles={{ ...styles.amountField } as SxPropsTypes}
          inputStyles={{ ...styles.amountFieldInput } as SxPropsTypes}
          control={control}
          name="quantity"
          placeholder="Enter Amount"
          withoutHighlights
          disabled={false}
        />
      </Box>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          {messages.cancel}
        </CommonButton>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.confirmButton }} onClick={handleConfirm}>
          {messages.confirm}
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
