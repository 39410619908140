import { Box } from '@mui/material';
import { FC } from 'react';
import { AboutUsPointItem } from './AboutUsPointItem';
import { aboutUsPointsData } from './constants';

export const AboutUsPoints: FC = (): JSX.Element => (
  <Box>
    {aboutUsPointsData.map((pointItem) => (
      <AboutUsPointItem key={pointItem.title} title={pointItem.title} text={pointItem.text} />
    ))}
  </Box>
);
