import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { Input } from '@mui/base/Input';

import { FC, forwardRef, useCallback, useEffect } from 'react';
import { AutocompleteChangeDetails, Box, IconButton, Popper, useForkRef } from '@mui/material';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';

import { ROUTES } from 'app/routes/constants';
import { CropSearchAutocomplete } from 'app/api/search';
import { SEARCH_TYPE } from 'pages/ProductsListPage/constants';
import { removeExtraSpaces } from 'utils/formatText';
import { styles } from './styles';
import { useSearchAutocomplete } from './hooks';

type SearchOption = { option: string | CropSearchAutocomplete };

export const HeaderSearch: FC = forwardRef((_, ref) => {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { query, setQuery, autocompleteResults, setResults } = useSearchAutocomplete();

  const clearInput = useCallback(() => setQuery(''), [setQuery]);
  const onSearch = useCallback(
    (value: SearchOption | undefined) => {
      if (!value) return;
      if (typeof value.option === 'string') {
        // Case where user does not select any option from the dropdown
        const text = removeExtraSpaces(value.option.trim());
        const encodedText = encodeURIComponent(text);
        if (!text) {
          clearInput();
          return;
        }
        const path = generatePath(ROUTES.buyer.products, { type: SEARCH_TYPE });
        setResults(undefined);
        navigate(`${path}?search=${encodedText}`);
        return;
      }
      setResults(undefined);
      // Case where user selects any option from the dropdown
      const path = generatePath(ROUTES.buyer.productsExtended, {
        type: value.option.familyName,
        subType: value.option.name,
      });
      navigate(path);
    },
    [navigate, clearInput, setResults],
  );

  useEffect(() => {
    if (type !== SEARCH_TYPE && !searchParams.get(SEARCH_TYPE)) {
      setQuery('');
    } else {
      setQuery(searchParams.get(SEARCH_TYPE) || '');
    }
  }, [type, searchParams, setQuery]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, id, popupOpen, anchorEl, setAnchorEl } =
    useAutocomplete<CropSearchAutocomplete, false, false, true>({
      freeSolo: true,
      id: 'autocomplete-search',
      options: autocompleteResults?.crops || [],
      getOptionLabel: (option: string | CropSearchAutocomplete) => {
        if (typeof option === 'string') return option;
        return option.name ?? '';
      },
      getOptionDisabled: () => false,
      filterOptions: (options) => options,
      filterSelectedOptions: true,
      onChange: (_, __, ___, option) =>
        onSearch(option as AutocompleteChangeDetails<CropSearchAutocomplete> | undefined),
      inputValue: query,
      onInputChange: (_, newInputValue) => {
        setQuery(newInputValue);
      },
    });

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> & { ref: React.Ref<HTMLInputElement> } =
    getInputProps();
  const onClickSearchIcon = () => {
    if (query !== '') {
      onSearch({ option: query });
    }
  };

  const hasItems = autocompleteResults?.crops?.length > 0;
  const rootRef = useForkRef(ref, setAnchorEl);
  return (
    <Box component="div" sx={styles.container}>
      <Box component="div" ref={rootRef} {...getRootProps()} sx={styles.inputContainer}>
        <Input id={id} slotProps={{ input: inputProps }} placeholder="Search for products" />
        {!!query && (
          <IconButton sx={{ ...styles.closeButton }} onClick={clearInput}>
            <CloseIcon height={24} width={24} />
          </IconButton>
        )}

        <Box sx={{ ...styles.searchIcon }}>
          <SearchIcon onClick={onClickSearchIcon} />
        </Box>
      </Box>
      {popupOpen && hasItems ? (
        <Popper
          open={popupOpen}
          anchorEl={anchorEl}
          disablePortal
          sx={styles.popper}
          placement="bottom-start"
          modifiers={[
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', enabled: false },
          ]}
        >
          <Box component="ul" {...getListboxProps()} sx={styles.resultsList}>
            {(autocompleteResults?.crops ?? []).map((option, index) => (
              <Box component="li" key={option.name} sx={styles.result} {...getOptionProps({ option, index })}>
                {option.name}
              </Box>
            ))}
          </Box>
        </Popper>
      ) : null}
    </Box>
  );
});
