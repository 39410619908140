import { UseFormWatch } from 'react-hook-form';

import { QuoteProduct, QuoteProductStatus } from 'app/api/quotes';
import { currencyFormat } from 'utils/currencyFormat';
import { QuoteTableFormData } from '../types';

interface UseDiscussionTotalProps {
  data: QuoteProduct[];
  watch: UseFormWatch<QuoteTableFormData>;
}

export const useDiscussionTotal = ({ watch }: UseDiscussionTotalProps) => {
  const quotes = watch('quotes');

  const totalAmount: number = quotes.reduce((acc: number, { amount, status }) => {
    const currentAmount = Number(status === QuoteProductStatus.AVAILABLE ? amount : 0);

    return acc + currentAmount;
  }, 0);

  const totalPrice = currencyFormat(
    quotes.reduce((acc, val) => {
      const singlePrice = val?.acceptedPrice ? Number(val?.acceptedPrice) : Number(val?.price);

      if (!Number.isNaN(singlePrice)) {
        const currentCount = val?.status === QuoteProductStatus.AVAILABLE ? val?.amount : 0;

        return acc + singlePrice * Number(currentCount);
      }

      return acc;
    }, 0) || 0,
  );

  return { totalPrice, totalAmount };
};
