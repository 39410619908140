import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100dvh',
    overflowX: 'auto',
    backgroundColor: customTheme.custom.white,
  },
  loaderWrap: {
    minHeight: '100svh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrap: (isSearchBar: boolean) => ({
    flex: '1',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: `0 auto 0`,
    marginTop: isSearchBar ? '96px' : '48px',
    minHeight: '100%',
  }),
  content: {
    flex: '1',
    padding: '14px 14px 0',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'auto',
    minHeight: '100%',
  },
};
