import { ROUTES } from 'app/routes/constants';

interface HeaderNavigationItem {
  text: string;
  path: string;
  hidden?: boolean;
}

export const headerNavigationItems = (isBuyer: boolean): HeaderNavigationItem[] => [
  {
    text: 'Home',
    path: ROUTES.buyer._,
    hidden: !isBuyer,
  },
  {
    text: 'Dashboard',
    path: isBuyer ? ROUTES.buyer.dashboard._ : ROUTES.seller.dashboard._,
  },
  {
    text: 'Contact us',
    path: ROUTES.common.contact,
  },
];
