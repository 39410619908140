import { customTheme } from 'theme/MuiThemeProvider/theme';
import { layoutWidth, layoutMinWidth, layoutMaxWidth } from '../constants/styleConstants';

export const styles = {
  mainWrap: (isShadow: boolean) => ({
    position: 'sticky',
    top: 0,
    zIndex: 12,
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 90px',
    width: layoutWidth,
    minWidth: layoutMinWidth,
    minHeight: '90px',
    padding: '0 24px',
    ...(isShadow && {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    }),
    backgroundColor: customTheme.custom.white,
  }),
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: layoutMaxWidth,
    margin: '0 auto',
  },
  leftSideWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  rightSideWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  spacer: {
    flex: '1',
    maxWidth: '120px',
  },
  logo: {
    height: '27px',
    flex: '0 0 145px',
    cursor: 'pointer',
  },
};
