import { FC, useEffect, useMemo } from 'react';

import { Control, UseFormSetValue } from 'react-hook-form';
import { Box, IconButton, Typography } from '@mui/material';

import { QuoteProduct, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';
import { EditButton } from 'components';
import { useAuth } from 'hooks';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { PRODUCT_IS_NO_LONGER_AVAILABLE, PRODUCT_REMOVED, QUANTITY_NOT_AVAILABLE } from 'pages/DashboardPage/constants';
import {
  ProductVariety,
  SellerActionsMenu,
  RemoveProductPopup,
  RemoveLastProductPopup,
  SellerInputNeededLabel,
  CounterOfferCell,
  ConfirmActions,
} from 'pages/DashboardPage/components';
import { useQuoteDiscussionPopups, useQuoteDiscussionRow, useSellersActions } from 'pages/DashboardPage/hooks';
import { cardItemTitles } from 'pages/QuoteDetailsPage/constants';
import { CellHeader } from '../CellHeader';
import { styles } from './styles';

const getIsAmountEditable = (
  isBuyer: boolean,
  isDisabled: boolean,
  quoteProductStatus: QuoteProductStatus | undefined,
  isWaiting: boolean,
) =>
  isBuyer &&
  !isDisabled &&
  (quoteProductStatus === QuoteProductStatus.AVAILABLE || quoteProductStatus === QuoteProductStatus.NOT_AVAILABLE) &&
  !isWaiting;

const getIsConfirmActions = (isRemoved: boolean, isUnavailable: boolean, isRestricted: boolean) =>
  !isRemoved && !isUnavailable && !isRestricted;

const getIsCounterOfferCell = (isActive: boolean, isWaiting: boolean, lastOffer: string, isBuyer: boolean) =>
  isActive && !isWaiting && (lastOffer || (!lastOffer && !isBuyer));

interface QuoteDiscussionCardProps {
  data: QuoteProduct;
  unitsType: UnitsType;
  control: Control<QuoteTableFormData>;
  index: number;
  isWaiting: boolean;
  productsCount: number;
  quoteId: number;
  isDisabled: boolean;
  isCurrentProductRemoveModal: boolean;
  isSampleRequest: boolean;
  handleOpenEditAmountModal: (id: number) => void;
  removeChoosedProducts: (index: number) => void;
  setValue: UseFormSetValue<QuoteTableFormData>;
  increaseConfirmedProducsCount: () => void;
  decreaseConfirmedProducsCount: () => void;
  closeDetails: () => void;
  handleCloseEditAmountModal: () => void;
  handleNavigateToDashboard: () => void;
  setCurrentRemoveId: (id: number | null) => void;
}

export const QuoteDiscussionCard: FC<QuoteDiscussionCardProps> = ({
  data,
  unitsType,
  control,
  index,
  isWaiting,
  productsCount,
  quoteId,
  isDisabled,
  isCurrentProductRemoveModal,
  isSampleRequest,
  handleOpenEditAmountModal,
  removeChoosedProducts,
  setValue,
  increaseConfirmedProducsCount,
  decreaseConfirmedProducsCount,
  closeDetails,
  handleCloseEditAmountModal,
  handleNavigateToDashboard,
  setCurrentRemoveId,
}): JSX.Element => {
  const { isBuyer } = useAuth();

  const {
    isModalOpen,
    isLastModalOpen,
    handleOpenRemoveProductModal,
    handleLastProductOpen,
    handleEditAmountButtonClick,
    handleCloseRemovePopup,
    handleCloseRemoveLastPopup,
  } = useQuoteDiscussionPopups({ id: data.id, handleOpenEditAmountModal, setCurrentRemoveId });

  const isNotlastProduct = useMemo(() => {
    return productsCount > 1;
  }, [productsCount]);

  const quoteProductStatus = data.status;
  const isAmountEditable = getIsAmountEditable(isBuyer, isDisabled, quoteProductStatus, isWaiting);

  const {
    quotePrice,
    isLoading,
    lastOffer,
    totalPrice,
    isConfirm,
    isActive,
    isRemoved,
    isUnavailable,
    isRestricted,
    isOpacity,
    isSellerInputNeeded,
    counterOfferPrice,
    isCancelLoading,
    handleRemoveRequest,
    handleConfirm,
    handleUndo,
    handleCancel,
  } = useQuoteDiscussionRow({
    control,
    data,
    index,
    isNotlastProduct,
    quoteId,
    isSampleRequest,
    closeDetails,
    decreaseConfirmedProducsCount,
    handleCloseEditAmountModal,
    handleCloseModal: handleCloseRemovePopup,
    increaseConfirmedProducsCount,
    removeChoosedProducts,
    setValue,
    handleNavigate: handleNavigateToDashboard,
  });

  const { open, anchorEl, handleClick, handleClose, handleRemove, handleMarkNotAvailable, handleStatusUndo } =
    useSellersActions({
      index,
      isConfirm,
      setValue,
      handleUndo,
    });

  const isConfirmActions = getIsConfirmActions(isRemoved, isUnavailable, isRestricted);
  const unavailableProductLabel = isUnavailable ? QUANTITY_NOT_AVAILABLE : PRODUCT_IS_NO_LONGER_AVAILABLE;
  const isCounterOfferCell = getIsCounterOfferCell(isActive, isWaiting, lastOffer, isBuyer);
  const isThousandSeedsUnit = unitsType === UnitsType.THOUSAND_SEEDS;
  const titles = cardItemTitles(unitsType);
  const isConfirmButton =
    !isRemoved &&
    !isUnavailable &&
    !isRestricted &&
    !isWaiting &&
    !!counterOfferPrice &&
    !quotePrice &&
    !data.acceptedPrice;

  const handleRemoveModalOpen = useMemo(() => {
    return isNotlastProduct ? handleOpenRemoveProductModal : handleLastProductOpen;
  }, [isNotlastProduct, handleOpenRemoveProductModal, handleLastProductOpen]);

  useEffect(() => {
    if (isCurrentProductRemoveModal) handleRemoveModalOpen();
  }, [handleRemoveModalOpen, isCurrentProductRemoveModal]);

  return (
    <Box sx={styles.wrap}>
      <Box sx={{ ...styles.varietyWrap(isRestricted), ...styles.opacityWrap(isOpacity) }}>
        <ProductVariety data={data} fullWidth isThousandSeedsUnit={isThousandSeedsUnit} />
      </Box>

      <Box sx={styles.row(isThousandSeedsUnit)}>
        <Box sx={styles.gridItemWrap}>
          <Box sx={styles.opacityWrap(isOpacity)}>
            <CellHeader {...titles.amount} isInline={isThousandSeedsUnit} />
          </Box>

          <Box sx={styles.weightInner}>
            <Box component="span" sx={{ ...styles.amountWeight, ...styles.opacityWrap(isOpacity) }}>
              {data.amount}
            </Box>
            {isAmountEditable && (
              <Box>
                <EditButton onClick={handleEditAmountButtonClick} />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ ...styles.gridItemWrap, ...styles.opacityWrap(isOpacity) }}>
          <CellHeader {...titles.latestOfferPrice} isInline={isThousandSeedsUnit} />
          <Box sx={styles.latestOfferPrice}>{isSellerInputNeeded ? <SellerInputNeededLabel /> : lastOffer || '-'}</Box>
        </Box>

        <Box sx={{ ...styles.gridItemWrap, ...styles.opacityWrap(isOpacity) }}>
          <CellHeader {...titles.latestOfferSubtotals} />
          <Box sx={styles.lastOfferSubtotal(!!totalPrice && !isSellerInputNeeded && !isOpacity)}>
            {isSellerInputNeeded ? '-' : totalPrice}
          </Box>
        </Box>
      </Box>

      <Box sx={styles.bottomActionsWrap}>
        <Box sx={styles.counterOfferCell(!!isConfirmButton && !isConfirm)}>
          {(isCounterOfferCell || !isActive) && (
            <Box sx={styles.opacityWrap(isOpacity)}>
              <CellHeader {...titles.counterOfferPrice} isInline />
            </Box>
          )}
          {isCounterOfferCell && (
            <CounterOfferCell
              control={control}
              index={index}
              isConfirm={!!data.acceptedPrice || !!isConfirm}
              isDisabled={isDisabled}
            />
          )}
          {!isActive && (
            <Box sx={styles.opacityWrap(isOpacity)}>
              <Typography sx={styles.statusLabel}>{isRemoved ? PRODUCT_REMOVED : unavailableProductLabel}</Typography>
            </Box>
          )}
        </Box>

        <Box sx={styles.actionsCell}>
          {isConfirmActions && (
            <Box sx={{ ...styles.actions(!!isConfirm) }}>
              {isConfirmButton && (
                <ConfirmActions
                  isConfirm={!!isConfirm}
                  isDisabled={isDisabled}
                  isThousandSeeds={isThousandSeedsUnit}
                  handleConfirm={handleConfirm}
                  handleUndo={handleUndo}
                />
              )}
            </Box>
          )}
          {!isBuyer && !isConfirmActions && !isWaiting && (isRemoved || isUnavailable) && (
            <Box sx={{ ...styles.undoLabel }} onClick={handleStatusUndo}>
              Undo
            </Box>
          )}
        </Box>
      </Box>

      {isConfirmActions && isBuyer && !isWaiting && (
        <Box sx={styles.actionsWrap}>
          <IconButton disabled={isDisabled} disableRipple sx={styles.closeIcon} onClick={handleRemoveModalOpen}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {!isBuyer && !isWaiting && !isRestricted && (
        <Box sx={styles.actionsWrap}>
          <SellerActionsMenu
            disabled={isDisabled}
            open={open}
            anchorEl={anchorEl}
            isRemoved={isRemoved}
            isUnavailable={isUnavailable}
            handleClick={handleClick}
            handleClose={handleClose}
            handleMarkNotAvailable={handleMarkNotAvailable}
            handleRemove={handleRemove}
          />
        </Box>
      )}

      {isModalOpen && (
        <RemoveProductPopup
          title="Remove product?"
          handleClose={handleCloseRemovePopup}
          isOpen={isModalOpen}
          handleRemove={handleRemoveRequest}
          isLoading={isLoading}
        />
      )}

      {isLastModalOpen && (
        <RemoveLastProductPopup
          handleClose={handleCloseRemoveLastPopup}
          isOpen={isLastModalOpen}
          handleRemove={handleCancel}
          isLoading={isCancelLoading}
        />
      )}
    </Box>
  );
};
