import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import * as rudderanalytics from 'rudder-sdk-js';

export const useTrackPageViewedEvent = () => {
  const pathnameRef = useRef<string>();
  const { pathname, search } = useLocation();

  const fullPath = `${pathname}${search}`;

  useEffect(() => {
    if (fullPath !== pathnameRef.current) {
      rudderanalytics.page();

      pathnameRef.current = fullPath;
    }
  }, [fullPath, pathname, search]);
};
