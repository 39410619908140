import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  banner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '56px 48px',
    borderRadius: '16px',
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 4,
    height: '380px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: 'inherit',
      marginLeft: '-14px',
      marginRight: '-14px',
      padding: '24px 16px',
      borderRadius: 0,
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.18)',
      overflow: 'visible',
    },
  },
  contentTitle: {
    color: customTheme.custom.brightGreen,
    fontFamily: 'Poppins',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '50px',
    marginBottom: 4,
    zIndex: 0,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '12px',
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  reasons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: 1,
    },
  },
  reason: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 1,
    width: '20px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '16px',
    },
  },
  reasonText: {
    color: '#71717A',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 700,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
  boatImg: {
    position: 'absolute',
    width: '634px',
    maxWidth: '50%',
    right: 0,
    bottom: 0,
    zIndex: 0,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
