import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  actionsWrap: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '16px 24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '12px',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'min-content',
      gridAutoRows: '1fr',
      alignItems: 'stretch',
    },
  },
  btnContainer: {
    gridColumn: '1 / 3',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridColumn: 'inherit',
    },
  },
  quoteButton: {
    width: '100%',
    padding: '10px 16px',
    backgroundColor: customTheme.palette.secondary.S100,
    whiteSpace: 'nowrap',
    '&.MuiButton-containedPrimary': {
      ':hover': {
        backgroundColor: customTheme.palette.secondary.S70,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: customTheme.custom.gray.G100,
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      height: 'auto',
      minHeight: '40px',
      padding: '6px 14px',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17px',
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      gridColumn: 'unset',
    },
  },
  messageButton: (isBorder: boolean) => ({
    padding: '10px 16px',
    backgroundColor: customTheme.custom.white,
    color: customTheme.custom.black.B50,
    gridColumn: '2 / 3',

    ...(isBorder && {
      border: `2px solid ${customTheme.custom.black.B50}`,
    }),

    ':hover': {
      backgroundColor: `${customTheme.palette.primary.P5} !important`,
      boxShadow: 'none',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      height: 'auto',
      minHeight: '40px',
      padding: '6px 14px',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17px',
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      gridColumn: 'unset',
    },
  }),
  sampleBtnContainer: {
    gridColumn: '1 / 2',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridColumn: 'inherit',
    },
  },
  sampleButton: (isBorder: boolean, inStock: boolean) => ({
    padding: '10px 16px',
    whiteSpace: 'nowrap',
    width: '100%',

    ...(isBorder && {
      border: `1px solid ${customTheme.custom.white}`,
    }),

    ...(isBorder &&
      !inStock && {
        border: `1px solid ${customTheme.custom.gray.G100}`,
      }),

    '&.Mui-disabled': {
      backgroundColor: customTheme.custom.gray.G100,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      height: 'auto',
      minHeight: '40px',
      padding: '6px 14px',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17px',
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      gridColumn: 'unset',
    },
  }),
  tooltip: {
    maxWidth: '240px',
    padding: '2px',
  },
};
