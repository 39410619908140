import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import SimpleBar from 'simplebar-react';
import { CommonButton } from 'components';

import { useCountries } from 'hooks/api/useCountriesQuery';
import { styles } from './styles';
import { Section } from '../Section';

export interface GeographyRestrictionsProps {
  restrictedCountries: number[];
  onChange: (field: string, value: number[]) => void;
}
export const GeographyRestrictions: FC<GeographyRestrictionsProps> = memo(({ restrictedCountries, onChange }) => {
  const { data: countries = [] } = useCountries();

  const [restrictions, setRestrictions] = useState(new Set(restrictedCountries));
  const toggleRestriction = useCallback(
    (id: number) => {
      const newRestrictions = new Set(restrictions);
      if (restrictions.has(id)) {
        newRestrictions.delete(id);
      } else {
        newRestrictions.add(id);
      }
      setRestrictions(newRestrictions);
    },
    [setRestrictions, restrictions],
  );
  const onClickSelectAll = () => setRestrictions(new Set([]));
  const onClickDeselectAll = () => setRestrictions(new Set(countries.map((country) => country.id)));

  const showRestricted = restrictions.size < countries.length / 2;
  const displayItems = countries.filter((c) => (showRestricted ? restrictions.has(c.id) : !restrictions.has(c.id)));
  useEffect(() => {
    onChange('restrictedCountries', [...restrictions]);
  }, [restrictions, onChange]);

  return (
    <Section title="Geography Restrictions" isCollapsible defaultCollpase>
      <Box sx={styles.container}>
        <Box sx={styles.listSide}>
          <Box sx={styles.listContainer}>
            <Box sx={styles.listHeader}>Products Visible In:</Box>
            <SimpleBar style={styles.listItemScroll}>
              <Box sx={styles.listItemContainer}>
                {countries.map((country) => (
                  <Box key={country.id} sx={styles.listItem} onClick={() => toggleRestriction(country.id)}>
                    <Checkbox
                      sx={styles.checkBox}
                      checked={!restrictions.has(country.id)}
                      onChange={() => toggleRestriction(country.id)}
                    />
                    <Typography>{country.name}</Typography>
                  </Box>
                ))}
              </Box>
            </SimpleBar>
          </Box>
          <Box sx={styles.buttons}>
            <CommonButton variant="text" onClick={onClickSelectAll}>
              Select All
            </CommonButton>
            <CommonButton variant="text" onClick={onClickDeselectAll}>
              Deselect All
            </CommonButton>
          </Box>
        </Box>
        <Box sx={styles.summarySide}>
          {restrictions.size === 0 && <Box sx={styles.allowedAll}>Allowed in all countries</Box>}
          {restrictions.size === countries.length && <Box sx={styles.restrictedAll}>Restricted in All Countries</Box>}
          {restrictions.size !== 0 && restrictions.size !== countries.length && (
            <>
              <Box sx={styles.summaryHeader}>{showRestricted ? 'Restricted in:' : 'Allowed In:'}</Box>
              <Box sx={styles.summaryItems}>
                {displayItems.map((country) => (
                  <Box component="span" key={country.id} sx={styles.pill(showRestricted)}>
                    {country.name}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Section>
  );
});
