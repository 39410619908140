import { FC } from 'react';

import { Box } from '@mui/material';

import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import {
  DashboardTabs,
  ProductNested,
  QuoteCardStatusesType,
  UnitsType,
  DashboardMainTabs,
} from 'app/api/quotes/types';
import { CompanyDetail } from 'app/api/company/types';
import { QuoteRequestDetails } from 'pages/DashboardPage/components/QuoteRequestDetails';
import { QuoteCardContent } from './QuoteCardContent';
import { QuoteCardMeta } from './QuoteCardMeta';
import { QuoteTotal } from './QuoteTotal';
import { useQuoteCard } from './hooks';
import { styles } from './styles';

export interface QuoteCardProps {
  tab?: DashboardTabs;
  id: number;
  products: ProductNested[];
  company: Pick<CompanyDetail, 'id' | 'name' | 'address' | 'displayId'>;
  unitsType: UnitsType;
  isSampleRequest: boolean;
  seller?: CompanyDetail;
  currentCycle?: number;
  totalAmount?: number | string;
  status?: QuoteCardStatusesType;
  isCanceled?: boolean;
  isNew?: boolean;
  isHighlighted?: boolean;
  createdAt?: string | null;
  estimatedDeliveryDate?: string | null;
  deliveredAt?: string | null;
  isQuote?: boolean;
  orderNumber?: string;
  isDetailsOpened?: boolean;
  onCloseDetails?: () => void;
  onOpenDetails?: (id: string) => void;
}

export const QuoteCard: FC<QuoteCardProps> = ({
  id,
  products,
  company,
  unitsType,
  isSampleRequest,
  totalAmount,
  status,
  seller,
  currentCycle = 0,
  isCanceled = false,
  isNew = false,
  isHighlighted = false,
  createdAt = '',
  estimatedDeliveryDate = '',
  deliveredAt = '',
  tab = DashboardMainTabs.QUOTE_REQUESTS,
  orderNumber,
  isQuote = false,
  isDetailsOpened = false,
  onCloseDetails,
  onOpenDetails,
}) => {
  const { isMobileSize, isModalOpen, handleClick, handleCloseDetails, handleImageError, handleCardClick } =
    useQuoteCard({
      id,
      isQuote,
      isSampleRequest,
      isDetailsOpened,
      currentCycle,
      seller,
      onCloseDetails,
      onOpenDetails,
    });

  const image = products.length ? products[0].thumbnail || products[0].image : null;

  return (
    <Box
      sx={{
        ...styles.wrap,
        ...(isCanceled && { ...styles.overlay }),
        ...(isHighlighted && !isCanceled && { ...styles.wrapHighlighted }),
      }}
      onClick={handleCardClick}
    >
      <Box sx={styles.innerWrap}>
        <Box
          component="img"
          loading="lazy"
          src={image || imagePlaceholder}
          alt="Quote Request"
          sx={{ ...styles.image }}
          onError={handleImageError(imagePlaceholder)}
        />
        <QuoteCardContent
          company={company}
          tab={tab}
          totalAmount={totalAmount}
          products={products}
          isNew={isNew}
          status={status}
          unitsType={unitsType}
        />
        {!isMobileSize && (
          <Box sx={styles.metaWrap}>
            <QuoteCardMeta
              company={company}
              isHighlighted={isHighlighted}
              createdAt={createdAt}
              estimatedDeliveryDate={estimatedDeliveryDate}
              deliveredAt={deliveredAt}
              onClick={handleClick}
              tab={tab}
              orderNumber={orderNumber}
            />
          </Box>
        )}
      </Box>

      <Box sx={styles.mobileFooterWrap}>
        <QuoteTotal
          unitsType={unitsType}
          tab={tab}
          totalAmount={totalAmount}
          createdAt={createdAt}
          estimatedDeliveryDate={estimatedDeliveryDate}
          deliveredAt={deliveredAt}
          orderNumber={orderNumber}
        />
      </Box>

      {isModalOpen && (
        <QuoteRequestDetails
          title={company.name}
          subTitle={`${company.address.city}, ${company.address.country}`}
          handleClose={handleCloseDetails}
          isOpen={isModalOpen}
          quoteId={id}
          isSampleRequest={isSampleRequest}
        />
      )}
    </Box>
  );
};
