import { customTheme } from 'theme/MuiThemeProvider/theme';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import {
  layoutWidth,
  layoutMinWidth,
  layoutMaxWidth,
  mobileLayoutWidth,
  mobileLayoutMinWidth,
} from '../constants/styleConstants';

export const styles = {
  mainWrap: (isShadow: boolean) => ({
    flex: '0 0 60px',
    width: layoutWidth,
    minWidth: layoutMinWidth,
    padding: '0 24px',
    backgroundColor: customTheme.custom.white,
    zIndex: 10,
    ...(isShadow && {
      boxShadow: '0 -4px 4px -4px rgba(0, 0, 0, 0.25)',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: mobileLayoutWidth,
      minWidth: mobileLayoutMinWidth,
      flex: '0 0 56px',
      padding: '0 14px',
      height: '56px',
    },
  }),
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: layoutMaxWidth,
    margin: '0 auto',
  },
  companyInfo: {
    fontSize: '12px',
  },
};
