import { FC, PropsWithChildren } from 'react';

import { generatePath, matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from 'app/routes/constants';
import { Link } from 'components/Link';
import { DashboardMainTabs, SampleRequestsTabs } from 'app/api/quotes';
import { Box, SxProps, Tooltip } from '@mui/material';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as CustomersIcon } from 'assets/icons/customers.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as LeafIcon } from 'assets/icons/leaf.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/order.svg';
import { ReactComponent as SampleIcon } from 'assets/icons/sample.svg';
import { ReactComponent as StorefrontIcon } from 'assets/icons/storefront.svg';
import { ReactComponent as SendMessageIcon } from 'assets/icons/sendMessage.svg';
import { useAuth } from 'hooks';

import { styles } from './styles';

export interface DashboardNavigationProps {
  sx?: SxProps;
}

const messages = {
  analytics: 'Coming soon',
  storefront: 'Coming soon',
  customers: 'Coming soon',
};

export const navSections = [
  { name: 'Home', route: ROUTES.dashboard._, Icon: HomeIcon, roles: ['buyer', 'seller'] },
  { name: 'Messages', route: ROUTES.common.messages, Icon: SendMessageIcon, roles: ['buyer', 'seller'] },
  {
    name: 'Orders',
    route: ROUTES.dashboard.orders.tab,
    childrenRoute: ROUTES.dashboard.orders.tab,
    routeProps: { tab: DashboardMainTabs.QUOTE_REQUESTS },
    Icon: OrderIcon,
    roles: ['buyer', 'seller'],
    children: [
      {
        name: 'Quote Requests',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.QUOTE_REQUESTS },
      },
      {
        name: 'Open Orders',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.OPEN_ORDERS },
      },
      {
        name: 'En Route',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.EN_ROUTE },
      },
      {
        name: 'Past',
        route: ROUTES.dashboard.orders.tab,
        routeProps: { tab: DashboardMainTabs.PAST_ORDERS },
      },
    ],
  },
  {
    name: 'Samples',
    route: ROUTES.dashboard.samples.tab,
    childrenRoute: ROUTES.dashboard.samples.tab,
    routeProps: { tab: SampleRequestsTabs.SAMPLE_REQUESTS },
    Icon: SampleIcon,
    roles: ['buyer', 'seller'],
    children: [
      {
        name: 'Sample Requests',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.SAMPLE_REQUESTS },
      },
      {
        name: 'Open Sample Orders',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.SAMPLE_SEND_IN_PROGRESS },
      },
      {
        name: 'Outbound',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.SAMPLE_EN_ROUTE },
      },
      {
        name: 'Past',
        route: ROUTES.dashboard.samples.tab,
        routeProps: { tab: SampleRequestsTabs.PAST },
      },
    ],
  },
  { name: 'Products', route: ROUTES.seller.edit._, Icon: LeafIcon, roles: ['seller'] },
  {
    name: 'My Storefront',
    route: ROUTES.common.company,
    Icon: StorefrontIcon,
    roles: ['seller'],
  },
  {
    name: 'Customers',
    route: ROUTES.dashboard.customers,
    Icon: CustomersIcon,
    isDisabled: true,
    roles: ['seller'],
    tooltip: messages.customers,
  },
  {
    name: 'Analytics (Beta)',
    route: ROUTES.dashboard.analytics,
    Icon: AnalyticsIcon,
    isDisabled: true,
    roles: ['seller'],
    tooltip: messages.analytics,
  },
];

const getValidNavSections = ({
  companyId,
  role,
  pathname,
}: {
  companyId?: string;
  role: 'buyer' | 'seller';
  pathname: string;
}) =>
  navSections
    .filter((section) => section.roles.includes(role))
    .map((section) => ({
      ...section,
      isActive: !!matchPath({ path: section.route }, pathname) && !section.childrenRoute,
      isChildrenActive: section.childrenRoute && !!matchPath({ path: section.childrenRoute }, pathname),
      path: generatePath(section.route, {
        ...section.routeProps,
        role,
        companyDisplayId: companyId,
        displayId: companyId,
      }),
      children: section.children?.map((child) => ({
        ...child,
        path: generatePath(child.route, {
          role,
          companyDisplayId: companyId,
          ...section.routeProps,
          ...child.routeProps,
        }),
      })),
    }));

export const DashboardNavigation: FC<DashboardNavigationProps> = ({ sx }) => {
  const { isBuyer, activeUser } = useAuth();
  const role = isBuyer ? 'buyer' : 'seller';
  const { pathname } = useLocation();
  const sections = getValidNavSections({ companyId: activeUser?.company?.displayId ?? '', role, pathname });
  return (
    <Box sx={{ ...styles.container, ...((sx || {}) as SxPropsTypes) }}>
      {sections.map((section) => (
        <Tooltip key={section.name} title={section.tooltip} placement="right">
          <Box>
            <Link
              disabled={section.isDisabled}
              key={section.name}
              sx={{ ...styles.link(section.isActive) }}
              path={section.path}
            >
              <section.Icon />
              {section.name}
            </Link>
            {(section.isActive || section.isChildrenActive) && section.children && (
              <Box sx={{ ...styles.childrenContainer }}>
                {section.children.map((child) => (
                  <Link
                    key={child.name}
                    sx={{ ...styles.link(child.path.toLowerCase() === pathname.toLowerCase()) }}
                    path={child.path}
                  >
                    {child.name}
                  </Link>
                ))}
              </Box>
            )}
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

export const PageWithDashboardNavigation: FC<
  PropsWithChildren<{
    contentSx?: SxProps;
  }>
> = ({ children, contentSx = {} as SxProps }) => (
  <Box sx={{ ...styles.pageWrap }}>
    <DashboardNavigation sx={styles.navigation} />
    <Box sx={[styles.content, contentSx as SxPropsTypes]}>{children}</Box>
  </Box>
);
