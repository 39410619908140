import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { LegendSection } from '../LegendSection';
import { styles } from './styles';

interface DescriptionProps {
  content?: string | null;
  legendImage?: string | null;
}

export const Description: FC<DescriptionProps> = ({ content, legendImage }) => {
  const isLegend = !!legendImage;

  return (
    <Box sx={styles.wrap}>
      {!!content && (
        <Box sx={styles.notes(isLegend)}>
          <Typography component="h3" sx={{ ...styles.title }}>
            Notes
          </Typography>
          <Typography sx={{ ...styles.content }}>{content}</Typography>
        </Box>
      )}

      <Box sx={styles.legendWrap}>{isLegend && <LegendSection src={legendImage} />}</Box>
    </Box>
  );
};
