import { FC } from 'react';

import { Box } from '@mui/material';

import { contactUsPointsData } from './constants';
import { ContactUsPointItem } from './ContactUsPointItem';

export const ContactUsPoints: FC = (): JSX.Element => (
  <Box>
    {contactUsPointsData.map((pointItem) => (
      <ContactUsPointItem key={pointItem.title} title={pointItem.title} text={pointItem.text} />
    ))}
  </Box>
);
