import { useCallback } from 'react';

import * as rudderanalytics from 'rudder-sdk-js';

import { AnalyticsLinkType, RudderAnalyticsName } from 'rudderAnalytics';

export interface LinkClickedEvenetProps {
  path: string;
  text: string;
  type: AnalyticsLinkType;
  isMailTo?: boolean;
}

export const useLinkClickedEvenet = () => {
  const { origin } = window.location;

  const handleTrackLinkClickEvent = useCallback(
    ({ path, text, type, isMailTo = false }: LinkClickedEvenetProps) => {
      const analyticsOptions = {
        destination_url: isMailTo ? path : encodeURI(`${origin}${path}`),
        destination_path: isMailTo ? path : encodeURI(path),
        link_text: text,
        link_type: type,
      };

      rudderanalytics.track(RudderAnalyticsName.LINK_CLICKED, analyticsOptions);
    },
    [origin],
  );

  return {
    handleTrackLinkClickEvent,
  };
};
