import applyCaseMiddleware from 'axios-case-converter';
import axios, { AxiosError, AxiosRequestHeaders } from 'axios';

import { notificationObserver, sessionObserver } from 'utils/observer';
import { getAccessInfo, getActiveUserId, removeSession } from 'utils/storage';
import { saveLoginPath } from 'utils/saveLoginPath';
import { ROUTES } from '../routes/constants';

const AUTH_URL = process.env.REACT_APP_API_ENDPOINT || 'api/';
const BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'api/';

export const authApi = axios.create({
  baseURL: AUTH_URL,
});

export const contentApi = applyCaseMiddleware(
  axios.create({
    baseURL: BASE_URL,
  }),
);

export const customerApi = applyCaseMiddleware(
  axios.create({
    baseURL: BASE_URL,
  }),
  { ignoreHeaders: true },
);

export const fileUploadApi = applyCaseMiddleware(
  axios.create({
    baseURL: BASE_URL,
  }),
  { ignoreHeaders: true },
);

export const productDetailsApi = applyCaseMiddleware(
  axios.create({
    baseURL: BASE_URL,
  }),
  {
    ignoreHeaders: true,
    caseMiddleware: {
      responseTransformer: ({ other, ...data }: Record<string, unknown>) => {
        const modifiedData: Record<string, unknown> = {};

        function snakeToCamel(input: string) {
          return input.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));
        }

        Object.keys(data).forEach((k) => {
          modifiedData[snakeToCamel(k) as unknown as string] = data[k];
        });

        const company = data.company as Record<string, unknown>;

        Object.keys(company).forEach((k) => {
          const key = snakeToCamel(k) as unknown as string;
          (modifiedData.company as Record<string, unknown>)[key] = company[k];
        });

        return { other, ...modifiedData };
      },
    },
  },
);

authApi.defaults.headers.common['Content-Type'] = 'application/json';
contentApi.defaults.headers.common['Content-Type'] = 'application/json';
customerApi.defaults.headers.common['Content-Type'] = 'application/json';
productDetailsApi.defaults.headers.common['Content-Type'] = 'application/json';

export const AUTH_PAGES = [
  ROUTES.auth.login,
  ROUTES.auth.resetPassword,
  ROUTES.auth.accountActivation,
  ROUTES.auth.createNewPassword,
];

const onError = async (error: AxiosError<{ [key: string]: string | string[] }>) => {
  let errStatus;

  if (axios.isAxiosError(error)) {
    saveLoginPath();
    errStatus = error?.response?.status as number;
  }

  if (!getAccessInfo('token') && !AUTH_PAGES.includes(window.location.pathname)) {
    saveLoginPath();
    sessionObserver.publish(false);
    return Promise.reject(error);
  }

  if (errStatus === 401) {
    if (getAccessInfo('token')) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Session is expired',
        text: 'Please login to continue',
      });
    }

    removeSession();
    return sessionObserver.publish(false);
  }

  return Promise.reject(error);
};

contentApi.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `token ${getAccessInfo('token') ?? ''}`,
    } as AxiosRequestHeaders;

    return config;
  },
  async (error) => {
    await Promise.reject(error);
  },
);

customerApi.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `token ${getAccessInfo('token') ?? ''}`,
      CustomerId: getActiveUserId() ?? '',
    } as unknown as AxiosRequestHeaders;

    return config;
  },
  async (error) => {
    await Promise.reject(error);
  },
);

productDetailsApi.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `token ${getAccessInfo('token') ?? ''}`,
      CustomerId: getActiveUserId() ?? '',
    } as unknown as AxiosRequestHeaders;

    return config;
  },
  async (error) => {
    await Promise.reject(error);
  },
);

fileUploadApi.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `token ${getAccessInfo('token') ?? ''}`,
      CustomerId: getActiveUserId() ?? '',
      'content-type': 'multipart/form-data',
    } as unknown as AxiosRequestHeaders;

    return config;
  },
  async (error) => {
    await Promise.reject(error);
  },
);

authApi.interceptors.response.use((response) => response, onError);
contentApi.interceptors.response.use((response) => response, onError);
customerApi.interceptors.response.use((response) => response, onError);

// Only to be used on queries that we know will not change
export const defaultQueryStaleTime = 1000 /* 1000 ms */ * 60; /* 60 sec */
