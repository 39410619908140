import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import textImgUrl from 'assets/images/landingTextImg.png';
import { styles } from './styles';

export const AboutUsDescription: FC = (): JSX.Element => (
  <>
    <Typography sx={{ ...styles.text }}>
      At Sproutzo, we&apos;re passionate about revolutionizing the world of international seed and agriculture trade.
      Our goal is to make the process faster, more efficient, and more secure for everyone involved.
    </Typography>

    <Typography sx={{ ...styles.text }}>
      As members of the seed industry, we know firsthand the challenges faced by producers and bulk purchasers alike.
      That&apos;s why we created Sproutzo – to simplify and streamline the entire experience, from product cataloging to
      connecting with trading partners across the globe.
    </Typography>

    <Typography sx={{ ...styles.text }}>
      With Sproutzo, you can digitize your processes and expand into new markets with ease. Our platform empowers you to
      manage your logistics and import/export workflows with confidence, so you can focus on what really matters –
      running your business.
    </Typography>

    <Typography sx={{ ...styles.text }}>
      Join us on our mission to modernize the seed trade industry. Let&apos;s grow together with Sproutzo!
    </Typography>

    <Box sx={{ ...styles.image }} component="img" src={textImgUrl} />
  </>
);
