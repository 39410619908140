import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageContainer: {
    padding: '16px 48px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '8px 0px',
    },
  },
  header: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,
    marginBottom: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '24px',
      lineHeight: '18px',
    },
  },
  pageOrdersContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
    },
  },
  ordersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  noOrders: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '16px',
  },
  orderContainer: (highlight: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    borderRadius: '8px',
    gap: '8px',
    cursor: 'pointer',
    backgroundColor: customTheme.custom.veryLightGrey,
    boxShadow: customTheme.custom.boxShadow.default,
    ...(highlight && {
      backgroundColor: customTheme.palette.primary.P20,
    }),
    ':hover': {
      backgroundColor: customTheme.palette.primary.P10,
      ...(highlight && {
        backgroundColor: customTheme.palette.primary.P20,
      }),
    },
  }),
  highlight: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    padding: '4px 8px',
    backgroundColor: customTheme.palette.primary.P100,
    color: customTheme.custom.white,
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
  },
  orderRow: {
    display: 'flex',
    gap: '16px',
  },
  bottomRow: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: customTheme.palette.info.main,
  },
  requestedAmount: {
    fontWeight: 600,
    fontSize: '14px',
  },
  orderImg: {
    width: '81px',
    height: '81px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  orderDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: 1,
  },
  orderProductCrop: {
    fontWeight: 600,
    color: customTheme.palette.info.main,
  },
  orderProductVariety: {
    fontWeight: 500,
    color: customTheme.palette.info.main,
  },
  orderCompanyName: {
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  orderCompanyAddress: {
    fontSize: '14px',
    fontWeight: 500,
  },
  orderProducts: {
    fontSize: '12px',
    fontWeight: 500,

    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  orderAmount: {
    color: customTheme.palette.secondary.S100,
  },
  orderQuantity: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '24px',
  },
  orderUnits: {
    marginLeft: '2px',
    fontWeight: 500,
    fontSize: '16px',
  },
  quoteRequestsContainer: {
    flex: 1,
    boxShadow: customTheme.custom.boxShadow.default,
    borderRadius: '8px',
    minWidth: '400px',
    boxSizing: 'border-box',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: '300px',
      width: '100%',
    },
  },
  quoteDate: {
    fontWeight: 500,
    fontSize: '14px',
    color: customTheme.palette.info.main,
  },
  boxHeader: {
    padding: '8px',
    borderBottom: `1px solid ${customTheme.custom.black.B20}`,
    boxShadow: customTheme.custom.boxShadow.default,
    fontSize: '16px',
    fontWeight: 500,
    color: 'inherit',
    width: '100%',
    display: 'block',
    boxSizing: 'border-box',
    position: 'relative',
  },
  boxContent: {
    padding: '8px',
    height: '650px',
    maxHeight: '650px',
    overflow: 'auto',
    backgroundColor: customTheme.custom.black.B5,
    ...scrollMixin,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: 'inherit',
      maxHeight: 'inherit',
    },
  },
};
