import React, { FC, useCallback } from 'react';

import { Box } from '@mui/material';
import { ProductsSlider } from 'components/ProductsSlider';

import { PageLoader } from 'components';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { useSimilarProducts } from '../../hooks/useSimilarProducst';
import { styles } from './styles';

type SimilarProductsProps = {
  cropId: number;
  containerRef: React.RefObject<HTMLDivElement>;
};

export const SimilarProducts: FC<SimilarProductsProps> = ({ containerRef, cropId }) => {
  const { products, isLoading } = useSimilarProducts({ cropId });
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const handleProductClick = useCallback(
    (id: number) => {
      const path = generatePath(ROUTES.common.product, { id });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: '', type: AnalyticsLinkType.CARD });
    },
    [handleTrackLinkClickEvent, navigate],
  );

  return (
    <Box component="div" ref={containerRef} sx={{ ...styles.container }}>
      <>
        {isLoading || (!products && <PageLoader />)}
        {!isLoading && products.length > 0 ? (
          <ProductsSlider
            id="similar-products"
            name="Similar products"
            isLink={false}
            products={products}
            onSlideClick={handleProductClick}
          />
        ) : null}
      </>
    </Box>
  );
};
