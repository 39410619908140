import { Counter } from 'app/api/orders/types';
import { QuoteProduct, DashboardTabs, UnitsType } from 'app/api/quotes/types';
import { Currency } from 'utils/currencyFormat';

export interface DashboardTab {
  label: string;
  value: number;
  counterKey?: keyof Counter;
  path: DashboardTabs;
}

export enum SortTypes {
  DEFAULT = 'default',
  DATE_SENT_NEWEST = 'dateSentNewest',
  DATE_CREATED_NEWEST = 'dateCreatedNewest',
  DATE_CREATED_OLDEST = 'dateCreatedOldest',
  DELIVERY_DATE_NEWEST = 'deliveryDateNewest',
  ESTIMATE_DELIVERY_DATE_NEWEST = 'estimateDeliveryDateNewest',
  ESTIMATE_DELIVERY_DATE_OLDEST = 'estamateDeliveryDateOldest',
  TOTAL_PRICE_HIGHEST = 'totalPriceHighest',
  TOTAL_PRICE_LOWEST = 'totalPriceLowest',
}

export interface SortValue {
  value: SortTypes;
  label: string;
}

export interface QuotesSortingFormValues {
  sort: SortTypes;
}

export interface AdditionalQuoteProductFormProps {
  value: string | undefined;
  updateAcceptedPrice: number | undefined;
  updatedAmount: number | undefined;
  updatedAmountType: UnitsType | undefined;
  amountChanged?: boolean;
}

export interface QuoteTableFormData {
  quotes: Array<QuoteProduct & AdditionalQuoteProductFormProps>;
  incoterms: string;
  priceUnits: Currency;
}
