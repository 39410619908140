import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  wrap: {
    marginBottom: '16px',
  },
  crop: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    display: 'inline',
    margin: 0,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  name: {
    color: customTheme.custom.black.B50,
  },
  variety: {
    color: customTheme.custom.black.B100,
    marginRight: '4px',
  },
  companyWrap: {
    display: 'flex',
    gap: '4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexWrap: 'wrap',
    },
  },
  link: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.palette.primary.P100,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    ':hover': {
      color: customTheme.palette.primary.P70,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      whiteSpace: 'nowrap',
    },
  },
  location: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      whiteSpace: 'nowrap',
    },
  },
  draftLabel: {
    display: 'inline',
    verticalAlign: 'middle',
    width: 'fit-content',
    height: 'fit-content',
    padding: '6px',
    marginLeft: '8px',
    borderRadius: '3px',
    background: '#E8E8E8',
    color: '#5E6062',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: '0.2px',
  },
  outOfStock: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: customTheme.custom.outOfStock,
    color: customTheme.custom.white,
    borderRadius: '4px',
    padding: '8px 32px',
    fontSize: '16px',
    fontWeight: 600,
    justifyContent: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '8px 4px',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  outOfStockContainer: {
    flex: '0 1 200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  similarProducts: {
    cursor: 'pointer',
    marginTop: '4px',
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 500,
    color: customTheme.custom.gray.G500,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      fontWeight: 500,
      maxWidth: '90px',
    },
  },
};
