import { FC } from 'react';

import { Box } from '@mui/material';
import { generatePath } from 'react-router-dom';

import { PageHeader } from 'components/PageHeader';
import { PageWithDashboardNavigation } from 'layout/DashboardNavigation';
import { useDeviceType } from 'hooks';
import { Link } from 'components/Link';
import { ROUTES } from 'app/routes/constants';

import { EditProductsTable } from './components/EditProductsTable';

import { styles } from './styles';

const messages = {
  title: 'Inventory',
  sub: 'Edit product availability or information',
  desktopOnly: 'Please visit this site on a desktop to edit inventory.',
  goToDashboard: 'Go to Dashboard',
};

export const EditProductsPage: FC = () => {
  const { isMobileSize } = useDeviceType();

  return (
    <PageWithDashboardNavigation contentSx={styles.container}>
      {isMobileSize ? (
        <Box sx={styles.desktopOnly}>
          <Box component="h2" sx={styles.desktopOnly}>
            {messages.desktopOnly}
          </Box>
          <Link sx={styles.dashboardLink} path={generatePath(ROUTES.dashboard._, { role: 'seller' })}>
            {messages.goToDashboard}
          </Link>
        </Box>
      ) : (
        <>
          <PageHeader text={messages.title} subText={messages.sub} />
          <EditProductsTable />
        </>
      )}
    </PageWithDashboardNavigation>
  );
};
