/* eslint-disable max-len */
export const aboutUsPointsData: { title: string; text: string }[] = [
  {
    title: `Curated marketplace`,
    text: `At Sproutzo, we take quality seriously, and we are very careful with who we invite to our platform. Through our trusted network of suppliers, we offer buyers direct access to premium products across the globe. With Sproutzo, you can be confident that you're getting high quality products.`,
  },
  {
    title: `Streamlined imports and exports`,
    text: `At Sproutzo, we understand that international purchases can be a daunting process. That is why we have developed import and logistics automation tools that make the process simpler. Once you have made a deal, our software helps you organize and prepare logistics and import documentation in a fraction of the time. This means you can focus on what really matters – growing your business.`,
  },
  {
    title: `Enhancement not replacement`,
    text: `We don’t want to replace the human-to-human interactions that make the agriculture industry special. Our goal is instead to give those same humans superpowers, enhancing their ability to expand their business, make new connections, and accomplish more deals in less time.`,
  },
  {
    title: `Get in touch`,
    text: `We are always looking for ways to improve Sproutzo, and we would love to hear your feedback. As our platform grows, we want to make sure we are meeting the needs of our users. If you have any ideas for features you would like to see in future versions, or if you just want to say hello, please get in touch. We would love to hear from you!`,
  },
];
