import { customTheme } from 'theme/MuiThemeProvider/theme';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  wrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: customTheme.palette.primary.P100,
  },
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  inner: (isBlur: boolean) => ({
    height: '100%',
    padding: '40px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
    ...(isBlur && {
      background: 'rgba(69, 69, 69, 0.7)',
      backdropFilter: 'blur(25px)',
    }),
  }),
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.palette.primary.P5,
  },
  list: (isLimitedHeight: boolean) => ({
    marginTop: '32px',
    marginBottom: '40px',
    display: 'grid',
    gap: '22px',
    maxHeight: isLimitedHeight ? '200px' : '100%',
    overflowY: 'auto',
    marginRight: '-6px',
    paddingRight: '6px',
    ...scrollMixin,
  }),
  keyStatsWrap: (isLimitedHeight: boolean) => ({
    maxHeight: isLimitedHeight ? '288px' : '100%',
    minHeight: '288px',
    overflowY: 'hidden',
    paddingRight: '6px',
  }),
};
