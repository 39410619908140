import { FC, useCallback } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import { RESTRICTED_CROPS } from 'app/api/crops';
import { FarmingMethod, Product, ProductStatus } from 'app/api/products';
import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import { useImageHandlers } from 'hooks';
import { getFileExtension } from 'pages/CompanyDetailsPage/utils';
import { capitalizeFirstLetter } from 'utils/formatText';
import { getVarietyValue } from 'utils/getVarietyValue';
import { geneticTypeLabel } from './constants';
import { styles } from './styles';

export interface ProductCardProps extends Product {
  isSliderCard?: boolean;
  hideMobileFooter?: boolean;
  mobileCardOrientation?: 'vertical' | 'horizontal';
  sx?: SxProps;
  onClick: (id: number) => void;
  size?: 'small' | 'large';
}

export const ProductCard: FC<ProductCardProps> = ({
  id,
  name,
  image,
  crop,
  variety,
  company,
  geneticType,
  qtyAvailable,
  status,
  thumbnail,
  inStock,
  farmingMethod,
  isSliderCard = false,
  hideMobileFooter = false,
  mobileCardOrientation = 'vertical',
  sx,
  size = 'small',
  onClick,
}) => {
  const { handleImageError } = useImageHandlers();

  const handleCardClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const varietyValue = getVarietyValue({ crop, variety, name });
  const isDraft = status === ProductStatus.DRAFT;
  const imageSrc = thumbnail || image || imagePlaceholder;
  const isLogo = !!company.logo?.length && getFileExtension(company.logo) !== 'pdf';
  const isMobileHorizontal = mobileCardOrientation === 'horizontal';
  const isRestricted = RESTRICTED_CROPS.some((name) => name === crop.toLowerCase());

  return (
    <Box
      sx={{ ...sx, container: styles.container, ...styles.wrap(isSliderCard, isMobileHorizontal, isRestricted, size) }}
      onClick={isRestricted ? undefined : handleCardClick}
      data-testid={size === 'small' ? 'card-small' : 'card-large'}
    >
      <Box
        component="img"
        loading="lazy"
        src={imageSrc}
        alt={`${variety || ''} ${name || ''}`}
        sx={{ ...styles.image(isSliderCard, isMobileHorizontal) }}
        onError={handleImageError(imagePlaceholder)}
        data-testid="product-img"
      />
      {!inStock && <Box sx={{ ...styles.outOfStock(isMobileHorizontal) }}>Out of Stock</Box>}
      {farmingMethod?.toLowerCase() === FarmingMethod.ORGANIC.toLowerCase() && (
        <Box sx={{ ...styles.organicTag(isMobileHorizontal, inStock) }}>Organic</Box>
      )}
      {!inStock && <Box sx={{ ...styles.outOfStock(isMobileHorizontal) }}>Out of Stock</Box>}
      {isDraft && <Box sx={{ ...styles.draftLabel }}>Draft</Box>}
      <Box sx={{ ...styles.content }} data-testid={isSliderCard ? 'slider-card-content' : 'normal-card-content'}>
        <Box>
          <Typography sx={{ ...styles.crop }}>{capitalizeFirstLetter(crop)}</Typography>
          <Box sx={{ ...styles.header }}>
            <Typography sx={{ ...styles.variety(isMobileHorizontal) }}>
              {varietyValue}
              {variety && name && ' '}
              {name && (
                <Typography sx={() => ({ ...styles.variety(isMobileHorizontal), ...styles.name })} component="span">
                  {name}
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ ...styles.tagsWrap(isMobileHorizontal) }}>
            <Typography sx={{ ...styles.type }}>{geneticTypeLabel[geneticType]}</Typography>
            {!!qtyAvailable && (
              <Typography sx={{ ...styles.stock }}>
                <Typography component="span" sx={{ ...styles.stockTitle }}>
                  In stock:
                </Typography>
                <Typography component="span" sx={{ ...styles.quantity }}>
                  {qtyAvailable}kg
                </Typography>
              </Typography>
            )}
          </Box>
          <Box sx={{ ...styles.footer(hideMobileFooter) }}>
            <Typography sx={{ ...styles.company(isMobileHorizontal) }}>
              {company.name}
              <Typography component="span" sx={{ ...styles.company(isMobileHorizontal), ...styles.country }}>
                {' '}
                • {company.country}
              </Typography>
            </Typography>
            {isLogo && (
              <Box component="img" src={company.logo ?? ''} sx={styles.logo(!!qtyAvailable, isMobileHorizontal)} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
