/* eslint-disable max-len */
export const contactUsPointsData: { title: string; text: string }[] = [
  {
    title: `What products can I trade on Sproutzo?`,
    text: `Sproutzo currently specializes in bulk seeds, but we will soon be expanding to other agri-food related categories. Keep an eye out for new products on the platform!`,
  },
  {
    title: `Who can use Sproutzo?`,
    text: `Sproutzo is designed for anyone who works in the seed industry. We welcome everyone involved in the seed trade, including from producers, breeders, distributors, wholesalers,  to breeders and importers, food production companies, seed packet companies, farmer co-ops, distributors, and more.`,
  },
  {
    title: `How does Sproutzo ensure quality on the platform?`,
    text: `We take quality seriously at Sproutzo. Each supplier joins from invite-only waitlist, most coming from pre-existing relationships we have from years of working in the seed industry. We also strive to visit as many suppliers in person as possible.`,
  },
  {
    title: `What if I can't find the product I'm looking for?`,
    text: `If you can't find the product you need on Sproutzo, just let us know! Our team will work hard to find the product you're looking for and bring it onto the platform. Simply reach out to us at the email address provided.`,
  },
  {
    title: `How does Sproutzo make money?`,
    text: `Sproutzo takes a small commission on new sales made through our platform. This commission ensures that we can continue to improve our services and support our users in the best way possible.`,
  },
  {
    title: `What features are coming next to Sproutzo?`,
    text: `We're constantly working on new features to improve the Sproutzo platform. Some upcoming features include freight tracking, sample requests, automated document and invoice generation, and much more. Stay tuned!`,
  },
  {
    title: `Where is the Sproutzo team based?`,
    text: `Our headquarters are located in the heart of New York City. However, we have team members all around the world, working hard to provide the best service to our users.`,
  },
  {
    title: `What payment terms does Sproutzo offer?`,
    text: `To ensure secure transactions and prompt business dealings, our standard payment terms involve upfront escrow payments from the buyer, released to the supplier at agreed-upon intervals as they ship and deliver the product. However, we understand that every deal is unique, and we're always willing to make exceptions when necessary.`,
  },
  {
    title: `Who handles freight for Sproutzo?`,
    text: `At Sproutzo, we work closely with our partner freight forwarders to ensure safe and timely delivery of your cargo. We act as your point of contact throughout the process, handling delivery in a white-glove fashion and providing continuous updates as your products move across the world.`,
  },
];
