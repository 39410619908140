import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { TextFormField } from 'components';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonPopup';
import { QuoteProduct, UnitsType } from 'app/api/quotes';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { formatNumber } from 'utils/formatText';

import { ProductVariety } from '../ProductVariety';
import { styles } from './styles';

interface EditQuantityUnitsPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  currentAmount: number;
  currentUnits: UnitsType;
  updatedUnits: UnitsType;
  data: QuoteProduct;
  update: (quantityAmount: number, quantityUnits: UnitsType) => void;
}

const messages = {
  title: 'Edit Quantity',
  differentQuantity: 'Change quantity to:',
  cancel: 'Cancel',
  confirm: 'Confirm',
};

export const EditQuantityUnitsPopup: FC<EditQuantityUnitsPopupProps> = ({
  handleClose,
  currentAmount,
  currentUnits,
  updatedUnits,
  update,
  data,
  isOpen = false,
}) => {
  const { control, watch } = useForm<{ quantity: undefined | number }>({
    defaultValues: {
      quantity: undefined,
    },
  });

  const quantity = watch('quantity');

  const handleConfirm = () => {
    if (quantity) {
      update(quantity, updatedUnits);
      handleClose();
    }
  };

  if (!data) {
    return null;
  }

  return (
    <CommonModal
      paperStyles={styles.paper as SxPropsTypes}
      title={`Change to ${getUnitDisplayValue(updatedUnits)}`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Box sx={{ ...styles.topContent }}>
        <ProductVariety fullWidth data={data} />
        <Box component="span" sx={styles.original}>{`Original Request – ${formatNumber(
          currentAmount,
        )} ${getUnitDisplayValue(currentUnits, true)}`}</Box>
      </Box>

      <Box sx={{ ...styles.content }}>
        <Typography sx={{ ...styles.subTitle }}>
          {`If you change this product’s unit to ${getUnitDisplayValue(
            updatedUnits,
            true,
          )}, you must suggest a new quantity. You can clarify the equivalent weight in the chat.`}
        </Typography>
        <TextFormField
          wrapStyles={{ ...styles.amountField } as SxPropsTypes}
          inputStyles={{ ...styles.amountFieldInput } as SxPropsTypes}
          control={control}
          name="quantity"
          placeholder="Suggest New Quantity"
          withoutHighlights
          disabled={false}
        />
      </Box>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          {messages.cancel}
        </CommonButton>
        <CommonButton
          variant="text"
          color="inherit"
          sx={{ ...styles.confirmButton }}
          disabled={!quantity}
          onClick={handleConfirm}
        >
          {messages.confirm}
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
