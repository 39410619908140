import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { PageLoader } from 'components';
import { TrackEventsTable, TrackingInfoSection, MapSection, TrackEventsList } from './components';
import { useShipmentTrackingPage } from './hooks';
import { styles } from './styles';

export const ShipmentTrackingPage: FC = () => {
  const { isLoading, lastUpdated, oceanFreightData, summaryData, statusesData, src, timingData } =
    useShipmentTrackingPage();

  if (isLoading) {
    return (
      <Box sx={styles.loaderWrap}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.mainInfoWrap}>
        <MapSection src={src} />
        <TrackingInfoSection oceanFreight={oceanFreightData} summary={summaryData} timing={timingData} />
      </Box>
      <Box sx={styles.dateWrap}>
        <Typography sx={styles.date}>Last Updated: {lastUpdated}</Typography>
      </Box>
      {!!statusesData.length && (
        <>
          <TrackEventsTable data={statusesData} />
          <TrackEventsList data={statusesData} />
        </>
      )}
    </Box>
  );
};
