import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Control, useController, useWatch } from 'react-hook-form';
import { Box, Divider, Fade, IconButton, Popper } from '@mui/material';

import { ChatFormData, SBChatMode } from 'app/api/SBChat';
import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { CommonTooltip, LoadingButton, TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { UploadedFilesList } from '../UploadedFilesList';
import { useFileUpload } from './hooks';
import { styles } from './styles';

interface MessageInputProps {
  control: Control<ChatFormData>;
  isSystemMessagesHidden: boolean;
  disableSendButton?: boolean;
  isSendMessageLoading?: boolean;
  isMessagesPage?: boolean;
  isQuoteDetailsPopup?: boolean;
  mode?: SBChatMode;
  handleSwitchMessagesView: () => void;
  handleSendMessage: () => void;
}

const messageMaxLength = 2000;

const messages = {
  invalidReminder: 'Terms of service reminder',
  invalidNotPermitted: 'Not permitted',
  invalidDetails:
    'sharing email, WhatsApp, mobile, or other external communication channels on Sproutzo ' +
    'is not permitted and may result in suspension or removal of your account.' +
    ' Please keep all communication on the Sproutzo platform.',
};

const validateEmail = (str: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(str);
};

const validatePhoneNumber = (str: string) => {
  const re = /(?:\d[-()\s]*){7,16}/;
  const reNot = /(?:\d[-()\s]*){16}/;
  return re.test(str) && !reNot.test(str);
};

const validateWebsite = (str: string) => {
  const re = /((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*/;
  return re.test(str) && !str.includes(document.location.host);
};

const validateStrings = (str: string) => {
  const triggers = ['email', 'whatsapp', 'whats app', 'mobile', 'wechat'];
  return triggers.some((trigger) => str.toLowerCase().includes(trigger));
};

export const MessageInput: FC<MessageInputProps> = ({
  control,
  isSystemMessagesHidden,
  mode,
  disableSendButton = false,
  isMessagesPage = false,
  isQuoteDetailsPopup = false,
  isSendMessageLoading = false,
  handleSwitchMessagesView,
  handleSendMessage,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { fileError, uploadedFiles, handleFileUpload, handleRemoveFile, onChange, setFileError } =
    useFileUpload(control);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const inputValue = useWatch({
    control,
    name: 'message',
  });
  useEffect(() => {
    if (inputValue === undefined) return;
    const preventSendValidators = [validateEmail, validateWebsite];
    const warningValidators = [validatePhoneNumber, validateStrings];
    const isInvalid = preventSendValidators.some((validator) => validator(inputValue));
    const isWarning = warningValidators.some((validator) => validator(inputValue));
    setIsInvalid(isInvalid);
    setIsWarning(isWarning);
  }, [inputValue]);

  const {
    field: { value },
  } = useController({ control, name: 'message' });

  const isEmptyValue = !uploadedFiles.length && !!value && !(value as unknown as string).trim();
  const isSendButtonDisabled = disableSendButton || isEmptyValue || isInvalid;

  const tooltipTitle = useMemo(() => {
    return uploadedFiles.length >= 5 ? '5 files - max number to upload' : '';
  }, [uploadedFiles]);

  const rowsCount = isMessagesPage ? 5 : 4;
  const maxRows = isQuoteDetailsPopup ? 3 : rowsCount;

  const onInputFile = () => fileInputRef.current?.click();

  const onSendMessage = useCallback(() => {
    setFileError('');
    handleSendMessage();
  }, [handleSendMessage, setFileError]);
  const anchorEl = useRef(null);

  const placement = isMessagesPage ? 'top-end' : 'bottom-end';
  return (
    <Box ref={anchorEl} sx={{ ...styles.wrap(isMessagesPage, isInvalid || isWarning) }}>
      <Box sx={{ ...styles.messageWrap }}>
        <CommonTooltip title="Show history logs">
          <Box sx={{ ...styles.historyIconWrap }}>
            <IconButton
              sx={{ ...(styles.historyIcon(isSystemMessagesHidden) as SxPropsTypes) }}
              onClick={handleSwitchMessagesView}
            >
              <HistoryIcon />
            </IconButton>
          </Box>
        </CommonTooltip>
        <>
          <Divider flexItem orientation="vertical" sx={{ ...styles.divider }} />
          <TextFormField
            control={control}
            name="message"
            inputWrapStyles={{ ...(styles.inputWrapStyles as SxPropsTypes) }}
            wrapStyles={{ ...(styles.inputBoxStyles as SxPropsTypes) }}
            inputStyles={{
              ...(styles.input as SxPropsTypes),
            }}
            disabled={isSendMessageLoading}
            multiline
            minRows={1}
            maxRows={maxRows}
            withoutHighlights
            placeholder="Type your message here ..."
            maxLength={messageMaxLength}
            onChange={onChange}
          />
          <CommonTooltip title={tooltipTitle}>
            <Box sx={{ ...styles.attachmentIconWrap }}>
              <IconButton
                disabled={!!tooltipTitle || isSendMessageLoading}
                sx={{ ...styles.attachmentIcon }}
                onClick={onInputFile}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".png, .jpg, .pdf"
                  style={{ ...styles.fileInput }}
                  onChange={handleFileUpload}
                />
                <AttachmentIcon />
              </IconButton>
            </Box>
          </CommonTooltip>
          <Popper
            sx={styles.popperContainer}
            open={isInvalid || isWarning}
            anchorEl={anchorEl.current}
            placement={placement}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <Box sx={{ ...styles.invalidText(isInvalid) }}>
                  <Box component="span" sx={styles.invalidTextMain}>
                    {isInvalid ? messages.invalidNotPermitted : messages.invalidReminder}
                  </Box>
                  {' - '}
                  {messages.invalidDetails}
                </Box>
              </Fade>
            )}
          </Popper>
          <Box sx={{ ...styles.sendButtonWrap(isMessagesPage, isSendButtonDisabled) }}>
            <Box sx={{ ...styles.sendButtonInner(isSendButtonDisabled) }}>
              {isSendMessageLoading ? (
                <LoadingButton sx={{ ...styles.loadingButton }} loading />
              ) : (
                <IconButton sx={{ ...styles.sendButton }} disabled={isSendButtonDisabled} onClick={onSendMessage}>
                  <SendIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </>
      </Box>

      {!!uploadedFiles.length && (
        <UploadedFilesList
          uploadedFiles={uploadedFiles as Record<'id' | 'file', string | File>[]}
          isMultiLines={mode === SBChatMode.ORDER_DETAILS}
          isRemoveDisabled={isSendMessageLoading}
          handleRemoveFile={handleRemoveFile}
        />
      )}

      {!!fileError && <Box sx={{ ...styles.errorBox }}>{fileError}</Box>}
    </Box>
  );
};
