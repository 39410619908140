import { useCallback, MouseEvent, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { OnNotificationClickProps } from 'app/api/notifications';
import { ROUTES } from 'app/routes/constants';
import { useDeviceType } from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import {
  INVALIDATE_QUERY_KEYS_ON_ORDER_NOTIFICATION_CLICK,
  INVALIDATE_QUERY_KEYS_ON_QUOTE_NOTIFICATION_CLICK,
  INVALIDATE_QUERY_KEYS_ON_SAMPLE_NOTIFICATION_CLICK,
  NOTIFICATIONS_COUNT_QUERY_KEY,
  NOTIFICATIONS_QUERY_KEY,
} from 'layout/Notifications/constants';
import { getUrlPathname } from 'layout/Notifications/utils';
import { useUpdateNotificationMutation, useNotificationsTotalCountQuery } from './api';

export const useNotificationsHandlers = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();
  const { isMobileSize } = useDeviceType();

  const isNotificationsOpen = !!anchorEl;

  const { mutate: updateNotification } = useUpdateNotificationMutation({});
  const { count } = useNotificationsTotalCountQuery();

  useEffect(() => {
    if (isNotificationsOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_COUNT_QUERY_KEY] });
    } else {
      setTimeout(() => queryClient.removeQueries(NOTIFICATIONS_QUERY_KEY), 200);
    }
  }, [isNotificationsOpen, queryClient]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNavigateToNotificationDetails = useCallback(
    (link: string) => {
      navigate(link);
      handleTrackLinkClickEvent({ path: link, text: '', type: AnalyticsLinkType.NOTIFICATION });
    },
    [handleTrackLinkClickEvent, navigate],
  );

  const handleNotificationsClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isMobileSize) {
        navigate(ROUTES.common.notifications);

        return;
      }

      setAnchorEl(event.currentTarget);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_QUERY_KEY] });
    },
    [isMobileSize, navigate, queryClient],
  );

  const handleItemClick = useCallback(
    async ({ isNew, link, id }: OnNotificationClickProps) => {
      const url = getUrlPathname(link, isMobileSize);
      const isQuoteNotification = link.includes('dashboard?');
      const isSampleNotification = link.includes('dashboard/sample-requests?');
      const isOrderNotification = link.includes('order');

      if (isNew) {
        const data = {
          inAppIsRead: true,
        };

        updateNotification({ id, data, link: url });
      }

      handleClose();

      if (isQuoteNotification) {
        await Promise.all(
          INVALIDATE_QUERY_KEYS_ON_QUOTE_NOTIFICATION_CLICK.map((key) =>
            queryClient.invalidateQueries({ queryKey: [key] }),
          ),
        );
      }

      if (isSampleNotification) {
        await Promise.all(
          INVALIDATE_QUERY_KEYS_ON_SAMPLE_NOTIFICATION_CLICK.map((key) =>
            queryClient.invalidateQueries({ queryKey: [key] }),
          ),
        );
      }

      if (isOrderNotification) {
        await Promise.all(
          INVALIDATE_QUERY_KEYS_ON_ORDER_NOTIFICATION_CLICK.map((key) =>
            queryClient.invalidateQueries({ queryKey: [key] }),
          ),
        );
      }

      handleNavigateToNotificationDetails(url);
    },
    [handleClose, handleNavigateToNotificationDetails, isMobileSize, queryClient, updateNotification],
  );

  return {
    isNotificationsOpen,
    anchorEl,
    count,
    handleClose,
    handleNotificationsClick,
    handleItemClick,
  };
};
