import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { PageWithDashboardNavigation } from 'layout/DashboardNavigation';

import { useAuth, useDeviceType } from 'hooks';
import { SortTypes } from 'pages/DashboardPage/types';
import { useQuoteRequestsQuery } from 'pages/DashboardPage/hooks/useQuoteRequestsQuery';
import { DashboardMainTabs, Quote, QuoteRequestStatus, SampleRequestsTabs } from 'app/api/quotes';

import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/routes/constants';
import { formatDate, formatNumber } from 'utils/formatText';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { Link } from 'components/Link';

import { styles } from './styles';

const useDashboardHomePage = () => {
  const { isBuyer } = useAuth();
  return { isBuyer };
};

type OrderProps = Quote & { isBuyer: boolean };
export const Order = ({
  id,
  isBuyer,
  highlight,
  totalAmount,
  buyer,
  seller,
  quoteproducts,
  id: quoteId,
  isSampleType,
  unitsType,
  createdAt,
}: OrderProps) => {
  const image = quoteproducts[0].product?.image ?? '';
  const navigate = useNavigate();
  const { isMobileSize } = useDeviceType();

  const handleOnClick = () => {
    if (isMobileSize) {
      const quotePath = generatePath(
        isSampleType ? ROUTES.common.sampleRequestDetails : ROUTES.common.quoteRequestDetails,
        { id },
      );
      navigate(quotePath);
    } else {
      const route = isSampleType ? ROUTES.dashboard.samples.tab : ROUTES.dashboard.orders.tab;
      const tab = isSampleType ? SampleRequestsTabs.SAMPLE_REQUESTS : DashboardMainTabs.QUOTE_REQUESTS;
      navigate({
        pathname: generatePath(route, {
          tab,
          role: isBuyer ? 'buyer' : 'seller',
        }),
        search: createSearchParams({ quoteId: quoteId.toString() }).toString(),
      });
    }
  };

  const company = isBuyer ? seller : buyer;
  return (
    <Box sx={styles.orderContainer(highlight)} onClick={handleOnClick}>
      {highlight && <Box sx={styles.highlight}>New</Box>}
      <Box sx={styles.orderRow}>
        <Box src={image} component="img" sx={styles.orderImg} />
        <Box sx={styles.orderDetails}>
          <Box>
            <Box sx={styles.orderCompanyName}>{company.name}</Box>
            <Box sx={styles.orderCompanyAddress}>{`${company.address.city}, ${company.address.country}`}</Box>
          </Box>
          <Box>
            <Box sx={styles.orderProducts}>
              {quoteproducts.map((qp, index) => (
                <Box component="span" key={qp.id}>
                  <Box component="span" sx={styles.orderProductCrop}>
                    {qp.product?.crop}
                  </Box>
                  <Box component="span" sx={styles.orderProductVariety}>
                    {`(${qp.product?.variety || ''})`}
                  </Box>
                  {index !== quoteproducts.length - 1 && ', '}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={[styles.orderRow, styles.bottomRow]}>
        <Box>
          <Box sx={styles.requestedAmount}>Total Amount Requested:</Box>
          <Box sx={styles.orderAmount}>
            <Box component="span" sx={styles.orderQuantity}>
              {formatNumber(totalAmount)}
            </Box>
            <Box component="span" sx={styles.orderUnits}>
              {getUnitDisplayValue(unitsType, true)}
            </Box>
          </Box>
        </Box>
        {createdAt && (
          <Box sx={styles.quoteDate}>
            Date Quote Created: <Box component="span">{formatDate(createdAt)}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useQuoteRequests = ({ isSample }: { isSample: boolean }) => {
  const {
    isLoading: isQuoteRequestsLoading,
    isSuccess: isQuotesListSuccess,
    data: quoteRequestsList,
  } = useQuoteRequestsQuery({
    enabled: true,
    sortValue: SortTypes.DEFAULT,
    requestSample: isSample,
    status: [QuoteRequestStatus.OPEN],
  });
  return { isQuoteRequestsLoading, isQuotesListSuccess, quoteRequestsList };
};

export const OrdersBox: FC<{ header: string; isBuyer: boolean; isSample?: boolean; path: string }> = ({
  header,
  isBuyer,
  isSample = false,
  path,
}) => {
  const { isQuotesListSuccess, quoteRequestsList } = useQuoteRequests({
    isSample,
  });

  return (
    <Box sx={styles.quoteRequestsContainer}>
      <Link sx={styles.boxHeader} path={path}>
        {header}
      </Link>
      <Box sx={styles.boxContent}>
        {isQuotesListSuccess && quoteRequestsList.length > 0 && (
          <Box sx={styles.ordersContainer}>
            {quoteRequestsList.map((qr) => (
              <Order key={qr.id} {...qr} isBuyer={isBuyer} />
            ))}
          </Box>
        )}
        {isQuotesListSuccess && quoteRequestsList.length === 0 && (
          <Box sx={styles.noOrders}>{header} will appear here</Box>
        )}
      </Box>
    </Box>
  );
};

export const DashboardHomePage: FC = () => {
  const { isBuyer } = useDashboardHomePage();
  const quoteRequestsRoute = generatePath(ROUTES.dashboard.orders.tab, {
    tab: DashboardMainTabs.QUOTE_REQUESTS,
    role: isBuyer ? 'buyer' : 'seller',
  });
  const sampleRequestsRoute = generatePath(ROUTES.dashboard.orders.tab, {
    tab: SampleRequestsTabs.SAMPLE_REQUESTS,
    role: isBuyer ? 'buyer' : 'seller',
  });
  return (
    <PageWithDashboardNavigation contentSx={styles.pageContainer}>
      <Typography variant="h1" sx={styles.header}>
        Dashboard
      </Typography>
      <Box sx={styles.pageOrdersContainer}>
        <OrdersBox isBuyer={isBuyer} header="Quote Requests" path={quoteRequestsRoute} />
        <OrdersBox isBuyer={isBuyer} header="Sample Requests" isSample path={sampleRequestsRoute} />
      </Box>
    </PageWithDashboardNavigation>
  );
};
