import { useCallback, useEffect, useState } from 'react';

import { UseFormWatch } from 'react-hook-form';

import { QuoteProduct, QuoteProductStatus } from 'app/api/quotes';
import { currencyFormat } from 'utils/currencyFormat';
import { AdditionalQuoteProductFormProps, QuoteTableFormData } from '../types';

export const useQuoteSellerTotal = (watch: UseFormWatch<QuoteTableFormData>) => {
  const quoteProducts = watch('quotes');
  const priceUnits = watch('priceUnits');
  const productCount = quoteProducts.length;
  const isScrolledList = productCount > 4;

  const totalAmount: number = quoteProducts.reduce((acc: number, { amount, status }) => {
    const currentAmount: number = status === QuoteProductStatus.AVAILABLE ? amount : 0;

    return acc + currentAmount;
  }, 0);

  const getTotalPrice = useCallback(
    (quoteProducts: (QuoteProduct & AdditionalQuoteProductFormProps)[]) => {
      const price = quoteProducts?.reduce((acc, val) => {
        const singlePrice = Number(val?.value || val?.price || val?.acceptedPrice);

        if (!Number.isNaN(singlePrice)) {
          const currentCount = val?.status === QuoteProductStatus.AVAILABLE ? val?.amount : 0;
          return acc + singlePrice * Number(currentCount);
        }

        return acc;
      }, 0);
      return currencyFormat(price || 0, priceUnits);
    },
    [priceUnits],
  );

  const [totalPrice, setTotalPrice] = useState<string>(getTotalPrice(quoteProducts));

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.quotes)
        setTotalPrice(getTotalPrice(value.quotes as (QuoteProduct & AdditionalQuoteProductFormProps)[]));
    });
    return () => subscription.unsubscribe();
  }, [watch, setTotalPrice, getTotalPrice]);

  return {
    totalPrice,
    totalAmount,
    isScrolledList,
  };
};
