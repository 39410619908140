import { useCallback } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import { getQuoteData } from 'app/api/quotes/service';

export const useQuoteRequestDetails = (quoteId: number, handleClose: () => void) => {
  const queryClient = useQueryClient();

  const { data: quoteData, isLoading } = useQuery(['quote-data', quoteId], () => getQuoteData(quoteId), {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  const closeDetails = useCallback(() => {
    handleClose();

    void Promise.all([
      queryClient.invalidateQueries({ queryKey: ['quote-requests'] }, { cancelRefetch: true }),
      queryClient.invalidateQueries({ queryKey: ['sample-requests'] }, { cancelRefetch: true }),
      queryClient.invalidateQueries({ queryKey: ['orders-counter'] }, { cancelRefetch: true }),
    ]);
  }, [handleClose, queryClient]);

  return {
    isLoading,
    quoteData,
    closeDetails,
  };
};
