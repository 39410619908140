import { FC } from 'react';

import { Box } from '@mui/material';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';

import { ChatPageInner } from './components';
import { useChatPageHandlers } from './hooks';
import { styles } from './styles';

export const ChatPage: FC = () => {
  const { channelUrl, isLoading } = useChatPageHandlers();

  return (
    <Box sx={styles.pageWrap}>
      <ChannelListProvider activeChannelUrl={channelUrl}>
        <ChatPageInner channelUrl={channelUrl} isListLoading={isLoading} />
      </ChannelListProvider>
    </Box>
  );
};
