import { FC } from 'react';

import { useTrackPageViewedEvent } from 'rudderAnalytics';
import { AuthMainUi } from '../../components/AuthMainUi';
import { CreatePasswordForm } from './components/CreatePasswordForm';

export const CreatePasswordPage: FC = (): JSX.Element => {
  useTrackPageViewedEvent();

  return (
    <AuthMainUi>
      <CreatePasswordForm />
    </AuthMainUi>
  );
};
