import { FC, ReactNode, SyntheticEvent, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { Box, Link as MaterialLink, SxProps, Theme } from '@mui/material';

import { styles } from './styles';

interface LinkProps {
  path: string;
  text?: string;
  trackType?: AnalyticsLinkType;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  disabled?: boolean;
}

export const Link: FC<LinkProps> = ({
  path,
  text = '',
  sx,
  trackType: type = AnalyticsLinkType.LINK,
  children,
  disabled = false,
}) => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();
  const navigate = useNavigate();
  const handleLinkClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      e.preventDefault();
      navigate(path);
      handleTrackLinkClickEvent({ path, text, type });
    },
    [text, path, type, handleTrackLinkClickEvent, navigate],
  );
  if (disabled) {
    return <Box sx={{ ...styles.link, ...styles.disabled, ...(sx || {}) }}>{children}</Box>;
  }
  return (
    <MaterialLink href={path} onClick={handleLinkClick} sx={{ ...styles.link, ...(sx || {}) }}>
      {children}
    </MaterialLink>
  );
};
