import { FC, MouseEvent, useRef } from 'react';

import { Box } from '@mui/material';

import { CompanyChatModal, PageLoader } from 'components';
import { useCompanyChatHandlers } from 'hooks';
import { Footer } from 'layout/Footer';
import {
  PageHeader,
  KeyStatsSection,
  Description,
  FullStatsSection,
  MobileProductsActionsButtons,
  LegendSection,
} from './components';
import { useProductDetailsPageHanlers } from './hooks';
import { styles } from './styles';
import { SimilarProducts } from './components/SimilarProducts';

export const ProductDetailsPage: FC = () => {
  const {
    channelUrl,
    isBuyer,
    isCreateChannelLoading,
    isLoading,
    data,
    keyTechStats,
    fullTechStats,
    hideRequestButton,
    isChatModalOpen,
    isVerticalImage,
    legendImage,
    startMessageString,
    handleCloseChatModal,
    handleImageLoad,
    onRequestQuoteClick,
    onRequestSampleClick,
    onSendMessageClick,
  } = useProductDetailsPageHanlers();

  const {
    control,
    isLoading: isSendMessageLoading,
    isMessageData,
    handleConfirm,
    handleSubmit,
  } = useCompanyChatHandlers({ channel: channelUrl, startMessageString });

  const similarProductsRef = useRef<HTMLDivElement>(null);
  const scrollDown = (e?: MouseEvent) => {
    e?.preventDefault();
    if (similarProductsRef.current) {
      similarProductsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.pageWrap(isBuyer)}>
        {data ? (
          <>
            <PageHeader
              crop={data.crop}
              variety={data.variety}
              name={data.name}
              company={data.company}
              status={data?.status}
              inStock={data.inStock}
              scrollDown={scrollDown}
            />

            <KeyStatsSection
              image={data.image}
              inStock={data.inStock}
              stats={keyTechStats}
              minOrder={data.company.smallestShipment}
              hideButton={hideRequestButton}
              isCreateChannelLoading={isCreateChannelLoading}
              isLegend={!!legendImage}
              isVerticalImage={isVerticalImage}
              onRequestQuoteClick={onRequestQuoteClick}
              onRequestSampleClick={onRequestSampleClick}
              onSendMessageClick={onSendMessageClick}
              handleImageLoad={handleImageLoad}
            />

            <Box sx={styles.legendWrap}>{!!legendImage && <LegendSection src={legendImage} />}</Box>

            <FullStatsSection stats={fullTechStats} />
            {(!!data.description || !!legendImage) && (
              <Description content={data.description} legendImage={legendImage} />
            )}

            <SimilarProducts cropId={data.id} containerRef={similarProductsRef} />

            <Box sx={styles.footerWrap(!hideRequestButton)}>
              <Footer />
            </Box>

            {!hideRequestButton && (
              <MobileProductsActionsButtons
                inStock={data.inStock}
                isCreateChannelLoading={isCreateChannelLoading}
                onRequestQuoteClick={onRequestQuoteClick}
                onRequestSampleClick={onRequestSampleClick}
                onSendMessageClick={onSendMessageClick}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
      <CompanyChatModal
        channelUrl={channelUrl}
        control={control}
        disableSendButton={!isMessageData}
        isOpen={isChatModalOpen}
        isSendMessageLoading={isSendMessageLoading}
        otherCompanyName={data?.company.name ?? ''}
        handleClose={handleCloseChatModal}
        handleSendMessage={handleSubmit(handleConfirm)}
      />
    </>
  );
};
