import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'relative',
    padding: '16px 8px 16px 20px',
    overflow: 'hidden',

    ':not(:last-of-type)': {
      borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    },
  },
  opacityWrap: (isOpacity: boolean) => ({
    opacity: isOpacity ? 0.4 : 1,
  }),
  varietyWrap: (isFullWidth: boolean) => ({
    width: isFullWidth ? '100%' : 'calc(100% - 36px)',
    marginBottom: '14px',
  }),
  actionsWrap: {
    position: 'absolute',
    top: '12px',
    right: '4px',
    color: customTheme.custom.black.B70,
  },
  row: (isThousandSeedsUnit: boolean) => ({
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: `${isThousandSeedsUnit ? '1.2fr' : '0.8fr'} 1.5fr 1.2fr`,
    gridAutoRows: 'auto',
    alignItems: 'start',
  }),
  amountWeight: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: customTheme.custom.black.B70,
  },
  quoteWrap: {
    height: '100%',
    display: 'grid',
  },
  statusLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
    alignSelf: 'end',
  },
  amountField: {
    margin: 0,
    minWidth: '100px',
    maxWidth: '140px',
  },
  amountFieldInput: {
    height: '21px',
  },
  subtotals: {
    height: '100%',
    display: 'grid',
  },
  price: (isColor: boolean) => ({
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: isColor ? customTheme.palette.secondary.S100 : customTheme.custom.black.B100,
    alignSelf: 'end',
  }),
  undoLabel: {
    fontSize: '12px',
    lineHeight: '16px',
    textDecoration: 'underline',
    color: customTheme.custom.black.B70,
  },
};
