import { AUTH_PAGES } from 'app/api';
import { setLoginPath } from './storage';

export const saveLoginPath = () => {
  const { pathname, search } = window.location;
  if (pathname === '/' || AUTH_PAGES.some((pg) => pg === pathname)) {
    return;
  }
  setLoginPath(pathname + search);
};
