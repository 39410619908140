import { FC } from 'react';

import { Box } from '@mui/material';

import { UnitsType } from 'app/api/quotes';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { styles } from './styles';

interface QuoteTotalProps {
  isSellerInputNeeded: boolean;
  totalAmount: string | number;
  totalPrice: string | number;
  unitsType: UnitsType;
}

export const QuoteTotal: FC<QuoteTotalProps> = ({ totalPrice, totalAmount, isSellerInputNeeded, unitsType }) => {
  const unit = getUnitDisplayValue(unitsType);

  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.itemWrap}>
        <Box sx={styles.title}>Total Weight</Box>
        <Box sx={styles.amount}>
          {totalAmount || '-'}
          {totalAmount ? ` ${unit}` : ''}
        </Box>
      </Box>
      <Box sx={styles.itemWrap}>
        <Box sx={styles.title}>Total Price</Box>
        <Box sx={styles.price}>{isSellerInputNeeded ? '' : totalPrice || '-'}</Box>
      </Box>
    </Box>
  );
};
