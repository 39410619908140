import { FC } from 'react';

import { Box } from '@mui/material';

import { reorderCategories } from 'hooks/api/useCropFamiliesQuery';
import { PageLoader } from '../../components/PageLoader';
import { ProductsSlider } from '../../components/ProductsSlider';
import { CompanySlider } from '../../components/CompanySlider';
import { HomePageBanner } from './components';
import { useBuyerHomePageHandlers } from './hooks';
import { styles } from './styles';
import { LeadingSuppliers } from './components/LeadingSuppliers';
import { Categories } from './components/Categories';
import { SimpleTradeBanner } from './components/SimpleTadeBanner';
import { AllInOnePlaceBanner } from './components/AllInOnePlaceBanner';
import { ChoiceCard } from './components/ChoiceCard';

export const BuyerHomePage: FC = () => {
  const { isLoading, homePageData, handleProductClick, newProducts, featuredProducts, featuredSuppliers } =
    useBuyerHomePageHandlers();

  const displayCropFamilies = reorderCategories(homePageData, true);

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.pageWrap }}>
      <HomePageBanner />
      <LeadingSuppliers />
      <Categories />
      <ProductsSlider
        id="featured-products"
        name="Featured products"
        size="large"
        subText=" Instant access to thousands of varieties."
        products={featuredProducts}
        onSlideClick={handleProductClick}
      />

      <ProductsSlider
        id="new-arrivals"
        name="New arrivals"
        isLink={false}
        products={newProducts}
        onSlideClick={handleProductClick}
        slide={<ChoiceCard />}
        slidePosition={3}
      />
      <CompanySlider companies={featuredSuppliers} name="Featured suppliers" pathName="Featured sellers" />
      <SimpleTradeBanner />
      {displayCropFamilies.slice(0, 2).map((section) => (
        <ProductsSlider key={section.id || section.name} {...section} onSlideClick={handleProductClick} isSmallTitle />
      ))}

      <AllInOnePlaceBanner />
      {displayCropFamilies.slice(2).map((section) => (
        <ProductsSlider key={section.id || section.name} {...section} onSlideClick={handleProductClick} isSmallTitle />
      ))}
    </Box>
  );
};
