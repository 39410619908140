import { Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/routes/constants';
import { CommonButton } from '../../../../components/CommonButton';
import { FormError } from '../../../../components/FormError';
import { LoadingButton } from '../../../../components/LoaderButton';
import { MaskedTextFormField } from '../../../../components/MaskedTextFormField';
import { TextFormField } from '../../../../components/TextFormField';
import { useLoginForm } from './hooks/useLoginForm';
import { validationRules } from './hooks/validation';
import { styles } from './styles';

export const LoginForm: FC = (): JSX.Element => {
  const { control, onChange, onSubmit, formError, isLoading, handleSubmit, onForgotPassword } = useLoginForm();

  const isFormError = !!formError;
  const { token, isBuyer } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // NOTE: This should only run once on component load
    if (token) {
      const page = isBuyer ? ROUTES.buyer._ : ROUTES.seller.dashboard._;
      navigate(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
      <Typography sx={{ ...styles.title }}>Log In</Typography>

      <TextFormField
        isFormError={isFormError}
        control={control}
        name="username"
        rules={validationRules.username}
        placeholder="Email"
        autoComplete="on"
      />

      <MaskedTextFormField
        isFormError={isFormError}
        control={control}
        name="password"
        rules={validationRules.password}
        placeholder="Password"
      />

      {isFormError && <FormError text={formError} />}

      <Box sx={{ ...styles.buttonsWrap(isFormError) }}>
        <LoadingButton type="submit" loading={isLoading} sx={styles.loginButton}>
          Log In
        </LoadingButton>

        <CommonButton variant="text" color="inherit" sx={{ ...styles.forgotPasswordButton }} onClick={onForgotPassword}>
          Forgot Password?
        </CommonButton>
      </Box>
    </form>
  );
};
