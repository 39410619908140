import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { PageLoader } from 'components';
import { ContactUsBanner, ContactUsDescription, ContactUsPoints } from './components';
import { useContactUsPage } from './hooks';
import { styles } from './styles';

export const ContactUsPage: FC = (): JSX.Element => {
  const { data, isLoading } = useContactUsPage();

  if (isLoading) {
    return (
      <Box sx={styles.loaderWrap}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.pageWrap }}>
      <ContactUsBanner />

      <Box sx={styles.pageContentWrap}>
        <Box sx={styles.column}>
          <ContactUsDescription email={data?.supportEmail || ''} />
        </Box>

        <Box sx={styles.column}>
          <Typography sx={styles.sectionTitle}>Frequently Asked Questions</Typography>
          <ContactUsPoints />
        </Box>
      </Box>
    </Box>
  );
};
