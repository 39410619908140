import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '100%',
    minHeight: '32px',
    maxHeight: '38px',
    padding: '4px 32px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '0 28px',
    flexWrap: 'wrap',
    boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: customTheme.palette.secondary.S20,
  },
  itemWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  title: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
  },
  amount: {
    fontSize: '12px',
    lineHight: '16px',
    fontWeight: 600,
    color: customTheme.custom.info,
    whiteSpace: 'nowrap',
  },
  price: {
    fontSize: '12px',
    lineHight: '16px',
    fontWeight: 600,
    color: customTheme.palette.secondary.S100,
    whiteSpace: 'nowrap',
  },
};
