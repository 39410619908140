import { FC } from 'react';

import { Box } from '@mui/material';

import { AboutPageBanner, AboutUsDescription, AboutUsPoints } from './components';
import { styles } from './styles';

export const AboutUsPage: FC = (): JSX.Element => {
  return (
    <Box sx={styles.pageWrap}>
      <AboutPageBanner />

      <Box sx={styles.pageContentWrap}>
        <Box sx={styles.column}>
          <AboutUsDescription />
        </Box>

        <Box sx={styles.column}>
          <AboutUsPoints />
        </Box>
      </Box>
    </Box>
  );
};
