import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ShopingCart } from 'assets/images/shoppingCart.svg';
import { UnitsType } from 'app/api/quotes/types';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { styles } from './styles';

interface EmptyQuotesStateProps {
  unitsType: UnitsType;
  isRequestSample: boolean;
}

export const EmptyQuotesState: FC<EmptyQuotesStateProps> = ({ unitsType, isRequestSample }): JSX.Element => {
  const requestType = isRequestSample ? 'samples' : 'products';

  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.imageWrap}>
        <ShopingCart />
      </Box>

      <Box>
        <Typography sx={styles.title}>Here you will see the selected {requestType}</Typography>
        <Typography sx={styles.description}>
          Select all the {requestType} you are interested in below and enter the Amount (
          {getUnitDisplayValue(unitsType, true)}) for each. All selected {requestType} will appear here.
        </Typography>
      </Box>
    </Box>
  );
};
