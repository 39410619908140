import { FC } from 'react';

import { Box } from '@mui/material';

import { ProductsActionsButtons } from '../ProductsActionsButtons';
import { styles } from './styles';

interface MobileProductsActionsButtonsProps {
  isCreateChannelLoading: boolean;
  onRequestQuoteClick: () => void;
  onRequestSampleClick: () => void;
  onSendMessageClick: () => void;
  inStock: boolean;
}

export const MobileProductsActionsButtons: FC<MobileProductsActionsButtonsProps> = ({
  isCreateChannelLoading,
  onRequestQuoteClick,
  onRequestSampleClick,
  onSendMessageClick,
  inStock,
}) => {
  return (
    <Box sx={styles.wrap}>
      <ProductsActionsButtons
        inStock={inStock}
        isCreateChannelLoading={isCreateChannelLoading}
        isMessageButtonBorder
        isSampleButtonBorder={false}
        onRequestQuoteClick={onRequestQuoteClick}
        onRequestSampleClick={onRequestSampleClick}
        onSendMessageClick={onSendMessageClick}
      />
    </Box>
  );
};
