import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    outline: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '10px',
  },
};
