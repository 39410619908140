import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  button: {
    minWidth: 'min-content',
    padding: '10px 16px',
    borderRadius: '10px',
    fontWeight: '500',
    fontSize: '14px',
    color: customTheme.custom.black.B50,
    background: customTheme.custom.white,
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
    zIndex: 4,

    '&.MuiButton-containedPrimary': {
      ':hover': {
        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
        color: customTheme.custom.black.B50,
        background: customTheme.custom.veryLightGrey,
        cursor: 'pointer',
      },
    },

    '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
      color: customTheme.custom.black.B50,
      background: customTheme.custom.white,
      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
      pointerEvents: 'unset',
      cursor: 'not-allowed',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '4px 6px',
      height: '30px',
      lineHeight: '22px',
      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
      whiteSpace: 'nowrap',
    },
  },
  menuButtonOpened: {
    color: customTheme.custom.black.B50,
    background: customTheme.custom.veryLightGrey,
  },
  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3px',
    color: customTheme.custom.black.B50,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      position: 'relative',
      top: '-2px',

      svg: {
        width: '14px',
        height: '14px',
      },
    },
  },
  menuButtonIconopened: {
    transform: 'rotate(180deg)',
  },
  menuWrap: {
    '&.MuiPopover-root': {
      zIndex: 3,
    },

    '& .MuiPaper-root': {
      minWidth: 'min-content',
      padding: 0,
      borderRadius: '10px',
      background: customTheme.custom.veryDarkGrey,
      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
    },

    '& .MuiMenu-list': {
      padding: '10px 6px',
    },

    '& .MuiMenuItem-root': {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      color: customTheme.custom.black.B20,

      ':first-of-type': {
        paddingTop: '4px',
        paddingBottom: '12px',
        borderBottom: `0.1px solid ${customTheme.custom.black.B30}`,
      },

      ':last-of-type': {
        paddingTop: '12px',
        paddingBottom: '4px',
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      '& .MuiPaper-root': {
        marginTop: '2px',
      },

      '& .MuiMenu-list': {
        padding: '0 6px',
      },

      '& .MuiMenuItem-root': {
        ':first-of-type': {
          paddingTop: '6px',
          paddingBottom: '8px',
        },

        ':last-of-type': {
          paddingTop: '8px',
          paddingBottom: '6px',
        },
      },
    },
  },
};
