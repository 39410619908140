import { FC, useCallback } from 'react';

import { Box, Link, Typography } from '@mui/material';

import textImgUrl from 'assets/images/ship.png';
import { AnalyticsLinkType, useLinkClickedEvenet } from 'rudderAnalytics';
import { styles } from './styles';

interface ContactUsDescriptionProps {
  email: string;
}

export const ContactUsDescription: FC<ContactUsDescriptionProps> = ({ email }): JSX.Element => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvenet();

  const handleEmailLinkClick = useCallback(() => {
    handleTrackLinkClickEvent({ path: `mailto: ${email}`, text: email, type: AnalyticsLinkType.LINK, isMailTo: true });
  }, [email, handleTrackLinkClickEvent]);

  return (
    <>
      <Typography sx={{ ...styles.text }}>
        Whether you want to ask a question, report a problem, or make a suggestion for future features, please don’t
        hesitate to reach out.
      </Typography>

      <Typography sx={{ ...styles.text }}>
        Please reach out at the following email address for all inquiries.
      </Typography>

      <Link href={`mailto: ${email}`} sx={{ ...styles.supportLink }} onClick={handleEmailLinkClick}>
        {email}
      </Link>

      <Typography sx={{ ...styles.text }}>We strive to answer every request within 24 hours.</Typography>

      <Box sx={{ ...styles.image }} component="img" src={textImgUrl} />
    </>
  );
};
