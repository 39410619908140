import { CSSProperties, FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton, SxProps, Toolbar } from '@mui/material';

import { ReactComponent as Logo } from 'assets/images/headerLogo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menuBurger.svg';
import { getDefaultUserRoute } from 'app/providers/utils';
import { useAuth } from 'hooks';
import { HeaderSearch } from '../../../HeaderSearch';
import { Notifications } from '../../../Notifications';
import { UserMenu } from '../../../UserMenu';
import { styles } from './styles';

interface MobileHeaderProps {
  isShadow: boolean;
  isActionsEnabled?: boolean;
  logoSx?: SxProps;
  minHeight?: CSSProperties['minHeight'];
  onMenuButtonClick?: () => void;
}

export const MobileHeader: FC<MobileHeaderProps> = ({
  isShadow,
  isActionsEnabled = true,
  logoSx,
  minHeight,
  onMenuButtonClick,
}) => {
  const navigate = useNavigate();
  const { isBuyer } = useAuth();

  const handleRedirectHome = useCallback(() => {
    navigate(getDefaultUserRoute(isBuyer));
  }, [isBuyer, navigate]);

  return (
    <AppBar position="fixed" sx={styles.mainWrap(isShadow, isBuyer, minHeight)}>
      <Toolbar sx={styles.toolBar(!isActionsEnabled)}>
        {isActionsEnabled && (
          <Box sx={styles.leftSideWrap}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onMenuButtonClick}
              sx={styles.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Notifications />
          </Box>
        )}

        <Box sx={{ ...styles.logo, ...logoSx }}>
          <Logo onClick={handleRedirectHome} />
        </Box>

        {isActionsEnabled && <UserMenu />}
      </Toolbar>

      {isBuyer && isActionsEnabled && <HeaderSearch />}
    </AppBar>
  );
};
