import { useEffect, useRef } from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import * as rudderanalytics from 'rudder-sdk-js';

import { NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import { OrderStatus } from 'app/api/orders/types';
import { patchDocumentFolderAsComplete } from 'app/api/orders/service';
import { useAuth } from 'hooks';
import { RudderAnalyticsName } from 'rudderAnalytics';
import { notificationObserver } from 'utils/observer';
import { FOLDER_NOT_FOUND_ERROR } from '../constants';
import { useOrderQuery } from './useOrderQuery';

interface MarkFolderAsCompleteI {
  id: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  setDocumentAsUpdated: () => void;
  onSuccessAction?: () => void;
}

export const useMarkFolderAsCompleteMutation = ({
  id,
  orderId,
  quoteId,
  isSampleRequest,
  setDocumentAsUpdated,
  onSuccessAction,
}: MarkFolderAsCompleteI) => {
  const queryClient = useQueryClient();
  const { isBuyer, activeUser } = useAuth();

  const { data, refetch } = useOrderQuery({ id: orderId, userId: activeUser?.id, isBuyer, queryKey: 'order-data' });

  const statusRef = useRef<OrderStatus>();

  useEffect(() => {
    if (data) {
      statusRef.current = data.status;
    }
  }, [data]);

  const { mutate: handleMarkFolderComplete, isLoading: isMarkFolderCompleteLoading } = useMutation(
    () => patchDocumentFolderAsComplete(id),
    {
      onSuccess: async () => {
        setDocumentAsUpdated();
        onSuccessAction?.();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['order-documents', id] });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['folder-details'] });

        await refetch().then((data) => {
          const status = data.data?.status;

          if (status === OrderStatus.PAYMENT_IN_PROGRESS && status !== statusRef.current) {
            const analyticsOptions = {
              order_id: orderId,
              quote_id: quoteId,
              is_sample_request: isSampleRequest,
            };

            rudderanalytics.track(RudderAnalyticsName.ALL_REQUIRED_DOCUMENTS_COMPLETED, analyticsOptions);
          }
        });
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === NOT_FOUND_ERROR_CODE) {
          notificationObserver.publish({
            type: 'error',
            title: FOLDER_NOT_FOUND_ERROR,
          });
        }
      },
    },
  );

  return { handleMarkFolderComplete, isMarkFolderCompleteLoading };
};
