import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  confirmIcon: {
    display: 'flex',
    padding: 0,
    color: customTheme.palette.secondary.S50,
    ':hover': {
      color: customTheme.palette.secondary.S70,
      cursor: 'pointer',
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  undoLabel: (isThousandSeeds: boolean) => ({
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
    textDecoration: 'underline',
    position: 'relative',
    zIndex: 20,
    ':hover': {
      color: customTheme.custom.black.B50,
      cursor: 'pointer',
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginLeft: isThousandSeeds ? '-56px' : 0,
      zIndex: 5,
    },
  }),
  acceptPriceButton: {
    display: 'none',
    background: customTheme.palette.secondary.S70,
    borderRadius: '12px',
    height: '100%',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    flexGrow: 1,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',

      '&.MuiButtonBase-root:hover': {
        background: customTheme.palette.secondary.S70,
      },
    },
  },
};
