import { FC, useCallback } from 'react';

import { Control, useWatch } from 'react-hook-form';
import { Box, IconButton, Typography } from '@mui/material';

import { Product } from 'app/api/products/types';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import { TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { getVarietyValue } from 'utils/getVarietyValue';
import { getUnitDisplayValue } from '../../utils';
import { QuoteFormData } from '../../hooks/types';
import { validationRules } from '../../hooks';
import { styles } from './styles';

interface ChoosedProductRowProps {
  control: Control<QuoteFormData>;
  index: number;
  removeChoosedProducts: (id: number, index: number) => void;
  data: Product;
  formError: string;
}

export const ChoosedProductRow: FC<ChoosedProductRowProps> = ({
  data,
  index,
  control,
  formError,
  removeChoosedProducts,
}): JSX.Element => {
  const currentVal = useWatch({
    control,
    name: `quotes.${index}.value`,
  });

  const unitsType = useWatch({
    control,
    name: 'unitsType',
  });

  const unitDisplayValue = getUnitDisplayValue(unitsType, true);

  const removeProduct = useCallback(() => {
    removeChoosedProducts(data.id, index);
  }, [data, index, removeChoosedProducts]);

  const varietyValue = getVarietyValue({
    crop: data.crop,
    variety: data.variety,
    name: data.name,
  });

  const imageSrc = data.thumbnail || data.image || imagePlaceholder;

  return (
    <Box data-testid="product-row" sx={{ ...styles.productRow }}>
      <Box sx={{ ...styles.productInfo }}>
        <Box component="img" loading="lazy" src={imageSrc} sx={{ ...styles.productImage }} />

        <Box sx={styles.titlesWrap}>
          <Typography sx={{ ...styles.title }}>{data.crop}</Typography>
          <Typography sx={{ ...styles.nameWrap }}>
            <Typography component="span" sx={{ ...styles.name }}>
              {varietyValue}
            </Typography>
            {data.variety && '\u00A0'}
            {data.name}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ ...styles.countInfo }}>
        <Typography sx={styles.neededLabel}>Amount Needed ({unitDisplayValue})?</Typography>
        <Box sx={{ ...styles.textFieldWrap }}>
          <TextFormField
            wrapStyles={{ ...styles.countField } as SxPropsTypes}
            control={control}
            name={`quotes.${index}.value`}
            isFormError={!!formError && !currentVal}
            withoutHighlights
            placeholder="Enter Amount"
            rules={validationRules.amount}
          />
          <Typography component="span" sx={{ ...styles.unitLabel }}>
            {unitDisplayValue}
          </Typography>
        </Box>

        <IconButton aria-label="remove" sx={{ ...styles.removeButton }} onClick={removeProduct}>
          <RemoveIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
