import { useCallback, useEffect, useMemo, useState } from 'react';

import { UseFormWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import * as rudderanalytics from 'rudder-sdk-js';

import { QuoteRequestFormProduct } from 'app/api/products/types';
import { DashboardMainTabs, QuoteRequestData, SampleRequestsTabs, UnitsType } from 'app/api/quotes/types';
import { addQuoteProduct, sendQuoteRequest } from 'app/api/quotes/service';
import { CompanyDetail } from 'app/api/company/types';
import { ROUTES } from 'app/routes/constants';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { RudderAnalyticsName, getQuoteRequestedEventProducts } from 'rudderAnalytics';
import { asyncGenerator } from 'utils/asyncGenerator';
import { notificationObserver } from 'utils/observer';
import { getSumByPropertyName } from 'utils/getSumByPropertyName';
import { QuoteFormData } from '../../../hooks/types';

interface UseChoosedProductsProps {
  watch: UseFormWatch<QuoteFormData>;
  seller: CompanyDetail | undefined;
  unitsType: UnitsType;
  isSampleType: boolean;
}

export const useChoosedProducts = ({ watch, seller, unitsType, isSampleType }: UseChoosedProductsProps) => {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const quotesList = watch('quotes');

  const [formError, setFormError] = useState('');

  const {
    mutate: quoteRequest,
    isLoading,
    isSuccess,
  } = useMutation((data: QuoteRequestData) => sendQuoteRequest(data), {
    onSuccess: (data) => {
      const totalAmount = getSumByPropertyName<QuoteRequestFormProduct>(quotesList, 'value');

      const analyticsOptions = {
        quote_id: data.id,
        products: getQuoteRequestedEventProducts(quotesList),
        number_of_products: quotesList.length,
        supplier_name: seller?.name ?? '',
        supplier_id: seller?.id ?? '',
        unit: unitsType.toLowerCase(),
        total_amount: totalAmount,
        total_weight: unitsType === UnitsType.THOUSAND_SEEDS ? '' : totalAmount,
        is_sample_request: isSampleType,
      };

      rudderanalytics.track(RudderAnalyticsName.QUOTE_REQUESTED, analyticsOptions);
    },
  });

  const {
    mutate: addQuote,
    isLoading: addQuoteLoading,
    isSuccess: isSuccessAddQuote,
  } = useMutation(
    (
      products: {
        product: number;
        amount: number;
      }[],
    ) => {
      const addAllProducts = async () => {
        // eslint-disable-next-line no-restricted-syntax
        for await (const num of asyncGenerator(products.length)) {
          const product = products[num];

          await addQuoteProduct({
            quote: Number(quoteId),
            amount: product.amount,
            product: product.product,
          });
        }
      };

      return addAllProducts();
    },
  );

  const onSubmit = (values: QuoteFormData) => {
    const { quotes, unitsType } = values;
    const isSomeEmpty = quotes.some(({ value }) => !value);

    if (isSomeEmpty) {
      setFormError(
        `Please check Amount (${getUnitDisplayValue(
          unitsType,
          true,
        )}) for selected products. Highlighted field is required. `,
      );
      return;
    }

    const products = quotes.map((quote) => ({ product: quote.id, amount: Number(quote.value), amountType: unitsType }));

    if (quoteId) {
      addQuote(products);
    } else {
      quoteRequest({
        sellerCompany: quotes?.[0].company.id,
        products,
        unitsType,
        isSampleType,
      });
    }
  };

  const onChange = useCallback(() => {
    if (formError) {
      setFormError('');
    }
  }, [formError]);

  useEffect(() => {
    const subscription = watch(() => {
      onChange();
    });
    return () => subscription?.unsubscribe();
  }, [watch, onChange]);

  useEffect(() => {
    if (isSuccess) {
      notificationObserver.publish({
        type: 'success',
        title: `${isSampleType ? 'Sample' : 'Quote'} Request sent successfully `,
      });

      const path = isSampleType
        ? generatePath(ROUTES.dashboard.samples.tab, { role: 'buyer', tab: SampleRequestsTabs.SAMPLE_REQUESTS })
        : generatePath(ROUTES.dashboard.orders.tab, { role: 'buyer', tab: DashboardMainTabs.QUOTE_REQUESTS });

      navigate(path);
    }
  }, [isSampleType, isSuccess, navigate]);

  useEffect(() => {
    if (isSuccessAddQuote) {
      const pathname = isSampleType
        ? generatePath(ROUTES.dashboard.samples.tab, { role: 'buyer', tab: SampleRequestsTabs.SAMPLE_REQUESTS })
        : generatePath(ROUTES.dashboard.orders.tab, { role: 'buyer', tab: DashboardMainTabs.QUOTE_REQUESTS });

      navigate({
        pathname,
        ...(quoteId && { search: `quoteId=${quoteId}` }),
      });
    }
  }, [isSampleType, isSuccessAddQuote, navigate, quoteId]);

  const isSuccessRequest = useMemo(() => {
    return quoteId ? isSuccessAddQuote : isSuccess;
  }, [quoteId, isSuccessAddQuote, isSuccess]);

  return {
    isLoading,
    formError,
    onChange,
    onSubmit,
    isSuccess,
    addQuoteLoading,
    isSuccessAddQuote,
    isSuccessRequest,
  };
};
