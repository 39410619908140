import { useCallback, useEffect, useMemo } from 'react';

import { Control, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as rudderanalytics from 'rudder-sdk-js';

import { UserRole } from 'app/api/auth/types';
import { patchQuoteProduct, updatePrices, updateQuote } from 'app/api/quotes/service';
import {
  HistoryPrice,
  QuoteProduct,
  QuoteProductStatus,
  UnitsType,
  UpdateQuoteData,
  UpdateQuoteProduct,
} from 'app/api/quotes/types';
import { useAuth } from 'hooks';
import { RudderAnalyticsName, getAnalyticsUserType, getQuoteRespondedToEventProducts } from 'rudderAnalytics';
import { CompanyDetail, ShipmentMethod } from 'app/api/company/types';
import { QuoteTableFormData } from '../types';
import { useHandleWaitAnswerStatus } from './useHandleWaitAnswerStatus';
import { useChangeProductAmountHandlers } from './useChangeProductAmountHandlers';
import { useQuoteResponseTotals } from './useQuoteResponseTotals';

interface UpdateQuoteMutation extends UpdateQuoteData {
  onAction?: () => void;
}

interface UseSendQuoteRequestProps {
  control: Control<QuoteTableFormData>;
  currentCycle: number;
  data: QuoteProduct[];
  isCreateOrder: boolean;
  isSampleRequest: boolean;
  quoteId: number;
  seller: CompanyDetail | undefined;
  unitsType: UnitsType;
  handleAction: () => void;
}

export const useSendQuoteRequest = ({
  control,
  currentCycle,
  data,
  isSampleRequest,
  isCreateOrder,
  quoteId,
  seller,
  unitsType,
  handleAction,
}: UseSendQuoteRequestProps) => {
  const { activeUser, isBuyer } = useAuth();

  const { setIsUpdWaitAnswer, isUpdWaitAnswer } = useHandleWaitAnswerStatus();

  const onSendAction = useCallback(() => {
    if (isCreateOrder) {
      handleAction();
    }

    if (isUpdWaitAnswer()) {
      handleAction();
    }
  }, [handleAction, isCreateOrder, isUpdWaitAnswer]);

  const {
    mutate: updatePriceRequest,
    isLoading: isLoadingPrice,
    isSuccess: isUpdatePriceSuccess,
  } = useMutation(
    (data: HistoryPrice[]) => {
      return Promise.all(data.map((dataItem) => updatePrices(dataItem)));
    },
    {
      onSuccess: () => {
        updateWaitAnswerStatus();
      },
    },
  );

  const {
    mutate: updateQuoteProduct,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useMutation(
    (data: (UpdateQuoteProduct & { id: number })[]) => {
      return Promise.all(data.map(({ id, ...rest }) => patchQuoteProduct(id, rest)));
    },
    {
      onSuccess: () => {
        if (isCreateOrder) onSendAction();
        if (!isUpdWaitAnswer()) updateWaitAnswerStatus();
      },
    },
  );

  const {
    mutate: handleUpdateQuote,
    isLoading: isLoadingUpdateQuote,
    isSuccess: isSuccessUpdateQuote,
  } = useMutation(
    async (data: UpdateQuoteMutation) => {
      await updateQuote(quoteId, data);
    },
    {
      onSuccess: (data, { onAction }) => {
        if (!isCreateOrder) onAction?.();
      },
    },
  );

  const quotes = useWatch({
    control,
    name: 'quotes',
  });

  const incoterms = useWatch({ control, name: 'incoterms' }) as ShipmentMethod;
  const priceUnits = useWatch({ control, name: 'priceUnits' });
  const { totalPrice, totalAmount } = useQuoteResponseTotals({ quotes });

  const analyticsOptions = useMemo(
    () => ({
      quote_id: quoteId,
      quote_total: totalPrice,
      products: getQuoteRespondedToEventProducts({ products: quotes, seller }),
      number_of_products: quotes.length,
      user_type: getAnalyticsUserType(isBuyer),
      cycle_number: currentCycle,
      supplier_name: seller?.name ?? '',
      supplier_id: seller?.id ?? '',
      unit: unitsType.toLowerCase(),
      total_amount: totalAmount,
      total_weight: unitsType === UnitsType.THOUSAND_SEEDS ? '' : totalAmount,
      is_sample_request: isSampleRequest,
    }),
    [currentCycle, isBuyer, isSampleRequest, quoteId, quotes, seller, totalAmount, totalPrice, unitsType],
  );

  const updateWaitAnswerStatus = useCallback(() => {
    if (!isUpdWaitAnswer()) {
      setIsUpdWaitAnswer();
      setTimeout(
        () =>
          handleUpdateQuote({
            waitAnswer: isCreateOrder ? UserRole.SELLER : activeUser?.kind,
            incoterms,
            priceUnits,
            onAction: () => {
              onSendAction();
              rudderanalytics.track(RudderAnalyticsName.QUOTE_RESPONDED_TO, analyticsOptions);
            },
          }),
        500,
      );
    }
  }, [
    activeUser?.kind,
    analyticsOptions,
    onSendAction,
    handleUpdateQuote,
    isCreateOrder,
    isUpdWaitAnswer,
    setIsUpdWaitAnswer,
    incoterms,
    priceUnits,
  ]);

  const { isEditAmountLoading, isEditAmountSuccess, productsWithUpdatedAmountLength } = useChangeProductAmountHandlers({
    quoteId,
    quotes,
    updateWaitAnswerStatus,
  });

  const productsWithUpdatedPrice = useMemo(() => {
    return quotes
      ?.filter((quote) => quote.status === QuoteProductStatus.AVAILABLE && quote.value)
      .map((quote) => ({
        actor: activeUser?.id,
        counterOffer: false,
        price: Number(quote.value),
        priceUnits,
        quoteProduct: quote.id,
        amount: quote.amount,
        amountType: quote.amountType,
        status: quote.status,
      }));
  }, [quotes, activeUser, priceUnits]);

  const productsWithUpdatedAcceptedPrice = useMemo(() => {
    return quotes
      ?.filter(
        (quote, index) => quote.updateAcceptedPrice && quote.updateAcceptedPrice !== data.at(index)?.acceptedPrice,
      )
      .map(({ id, updateAcceptedPrice }) => ({
        id,
        acceptedPrice: Number(updateAcceptedPrice),
        status: QuoteProductStatus.AVAILABLE,
      }));
  }, [data, quotes]);

  const productsWithUpdatedStatuses = useMemo(() => {
    return isBuyer
      ? []
      : quotes
          ?.filter((quote) => {
            const initialStatus = data.find((product) => product.id === quote.id)?.status;

            return quote.status && quote.status !== initialStatus;
          })
          .map(({ id, status }) => ({ id, status }));
  }, [data, isBuyer, quotes]);

  const productsWithoutLatestOffer = useMemo(() => {
    return quotes?.filter((quote) => !quote.price);
  }, [quotes]);

  const handleConfirm = useCallback(() => {
    // Update the price history
    updatePriceRequest(
      quotes.map((quote) => ({
        actor: activeUser?.id,
        price: Number(quote.value) || Number(quote.acceptedPrice) || Number(quote.price),
        counterOffer: false,
        priceUnits,
        quoteProduct: quote.id,
        amount: quote.amount,
        amountType: quote.amountType,
        status: quote.status as QuoteProductStatus,
        isAccepted: isCreateOrder
          ? quote.status === QuoteProductStatus.AVAILABLE
          : !!quote.acceptedPrice && !quote.value,
      })),
    );
    // Update all the quote products with status and if accepted
    updateQuoteProduct(
      quotes.map((quote) => ({
        id: quote.id,
        status: quote.status,
        amount: quote.amount,
        amountType: quote.amountType,
        acceptedPrice: isCreateOrder ? Number(quote.acceptedPrice || quote.price) : Number(quote.acceptedPrice),
        acceptedPriceUnits: priceUnits,
      })),
    );

    // I don't think we need to do this?
    // They remove products and then add them back in with new amount....
    // if (productsWithUpdatedAmountLength) {
    //   editProductAmount();
    // }
    if (
      !productsWithUpdatedPrice.length &&
      !productsWithUpdatedAcceptedPrice.length &&
      !productsWithoutLatestOffer.length
    ) {
      onSendAction();
    }
    if (isCreateOrder && !productsWithUpdatedPrice.length && !productsWithUpdatedAcceptedPrice.length) {
      setTimeout(
        () =>
          handleUpdateQuote({
            waitAnswer: isCreateOrder ? UserRole.SELLER : activeUser?.kind,
            incoterms,
            priceUnits,
          }),
        500,
      );
      onSendAction();
    }
    if (!isCreateOrder) onSendAction();
    if (productsWithoutLatestOffer.length && !isCreateOrder) {
      updateWaitAnswerStatus();
    }

    if (
      isCreateOrder &&
      (productsWithUpdatedPrice.length ||
        productsWithUpdatedAcceptedPrice.length ||
        productsWithUpdatedStatuses.length ||
        productsWithUpdatedAmountLength)
    ) {
      rudderanalytics.track(RudderAnalyticsName.QUOTE_RESPONDED_TO, analyticsOptions);
    }
  }, [
    quotes,
    productsWithUpdatedPrice,
    productsWithUpdatedAcceptedPrice,
    productsWithUpdatedStatuses,
    productsWithUpdatedAmountLength,
    productsWithoutLatestOffer.length,
    isCreateOrder,
    onSendAction,
    updatePriceRequest,
    updateQuoteProduct,
    analyticsOptions,
    handleUpdateQuote,
    activeUser?.kind,
    updateWaitAnswerStatus,
    activeUser?.id,
    incoterms,
    priceUnits,
  ]);

  useEffect(() => {
    if (
      (isUpdatePriceSuccess || isUpdateSuccess || isSuccessUpdateQuote) &&
      ((productsWithUpdatedPrice?.length && isUpdatePriceSuccess) || !isUpdatePriceSuccess) &&
      ((productsWithUpdatedAcceptedPrice?.length && isUpdateSuccess) || !isUpdateSuccess) &&
      ((productsWithUpdatedAmountLength && isEditAmountSuccess) || !isEditAmountSuccess) &&
      (((productsWithUpdatedPrice?.length ||
        productsWithUpdatedAcceptedPrice?.length ||
        productsWithoutLatestOffer?.length) &&
        isSuccessUpdateQuote) ||
        !isSuccessUpdateQuote)
    ) {
      onSendAction();
    }
  }, [
    onSendAction,
    productsWithUpdatedPrice?.length,
    isUpdatePriceSuccess,
    productsWithUpdatedAcceptedPrice?.length,
    isUpdateSuccess,
    isSuccessUpdateQuote,
    productsWithoutLatestOffer?.length,
    productsWithUpdatedAmountLength,
    isEditAmountSuccess,
  ]);

  const isLoading = useMemo(() => {
    const loading = isLoadingPrice || isUpdateLoading || isLoadingUpdateQuote || isEditAmountLoading;

    return loading || (loading && !isUpdWaitAnswer());
  }, [isLoadingPrice, isUpdateLoading, isLoadingUpdateQuote, isEditAmountLoading, isUpdWaitAnswer]);

  return { isLoading, handleConfirm };
};
