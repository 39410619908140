import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  text: {
    marginBottom: '24px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
      fontSize: '12px',

      ':last-of-type': {
        marginBottom: '8px',
      },
    },
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1000px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '320px',
    },
  },
  supportLink: {
    display: 'block',
    marginBottom: '24px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
    color: customTheme.palette.primary.P100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
      fontSize: '12px',
    },
  },
};
