import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Typography, Box } from '@mui/material';

import { SortFilter } from 'components';
import { DashboardTab, SortValue } from '../../types';
import { styles } from './styles';

interface PageHeaderProps {
  activeTab: DashboardTab;
  control: Control;
  sortOptions: SortValue[];
}

export const PageHeader: FC<PageHeaderProps> = ({ activeTab, control, sortOptions }) => {
  return (
    <Box sx={styles.wrap}>
      <Typography component="h2" sx={{ ...styles.title }}>
        {activeTab.label}
      </Typography>
      <Box sx={styles.actionsWrap}>
        {!!sortOptions.length && <SortFilter data={sortOptions} control={control} controlName="sort" />}
      </Box>
    </Box>
  );
};
