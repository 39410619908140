import { ROUTES } from 'app/routes/constants';

export const ROUTES_WITH_SIDE_MENU = [
  ROUTES.common.company,
  ROUTES.common.product,
  ROUTES.common.order,
  ROUTES.common.shipmentTracking,
  ROUTES.buyer.placeOrder,
  ROUTES.buyer.products,
  ROUTES.buyer.productsExtended,
  // ROUTES.buyer.dashboard._,
  // ROUTES.buyer.dashboard.tab,
  ROUTES.buyer.quoteRequest,
  ROUTES.buyer.quoteRequestCompany,
  ROUTES.buyer.quoteRequestAdd,
  ROUTES.buyer.sampleRequest,
  ROUTES.buyer.sampleRequestCompany,
  ROUTES.buyer.sampleRequestAdd,
];
