import { useMemo } from 'react';

import { Control, useWatch } from 'react-hook-form';

import { QuoteProduct, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { currencyFormat } from 'utils/currencyFormat';

interface UseQuoteSellerInitialCardProps {
  control: Control;
  data: QuoteProduct;
  index: number;
  unitsType: UnitsType;
}

export const useQuoteSellerInitialCard = ({ control, data, index, unitsType }: UseQuoteSellerInitialCardProps) => {
  const quotePrice = useWatch({
    control,
    name: `quotes.${index}.value`,
  });

  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  const totalPrice = useMemo(() => {
    return currencyFormat(data.amount * quotePrice);
  }, [data, quotePrice]);

  const isRemoved = quoteProductStatus === QuoteProductStatus.REMOVED;
  const isUnavailable = quoteProductStatus === QuoteProductStatus.NOT_AVAILABLE;
  const isRestricted = quoteProductStatus === QuoteProductStatus.RESTRICTED;
  const isNotAvailable = quoteProductStatus !== QuoteProductStatus.AVAILABLE;
  const isOpacity = isNotAvailable;
  const isThousandSeedsUnit = unitsType === UnitsType.THOUSAND_SEEDS;

  return {
    quoteProductStatus,
    totalPrice,
    isNotAvailable,
    isOpacity,
    isRemoved,
    isRestricted,
    isThousandSeedsUnit,
    isUnavailable,
  };
};
